import { Component } from 'react'
import { connect } from 'react-redux'

import { Box } from '@mui/material'
import SubHeadeWithBreadcrumb from './components/SubHeader.js'

import { Loading, NoData, Voting } from './components/widgets'

import { withTranslation } from '../language.js'
import {
  isEmpty,
  mapDataPropsProvider,
  notDefined,
  shouldUpdate
} from '../utils'
import { AuthorizationGate } from './components/Authorization.js'

class VotingHomeComponent extends Component {
  shouldComponentUpdate (nextProps) {
    return shouldUpdate(this.props, nextProps)
  }

  render () {
    const { data, loading } = this.props
    if (loading) {
      return <Loading />
    }

    if (
      notDefined(data) ||
      isEmpty(data?.profiles) ||
      isEmpty(data?.campaigns)
    ) {
      return <NoData />
    }

    return (
      <AuthorizationGate>
        <Box>
          <SubHeadeWithBreadcrumb />
          <Box sx={{ padding: '5px' }}>
            <Voting />
          </Box>
        </Box>
      </AuthorizationGate>
    )
  }
}

const mapVotingProps = function (state) {
  const { profile, checksum } = state.voting
  return mapDataPropsProvider(profile, checksum, state)
}

const VotingHome = connect(mapVotingProps)(
  withTranslation(VotingHomeComponent)
)

export { VotingHome }
