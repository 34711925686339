import axios from 'axios'
import { authExpired, fetchAccessTokenSuccess, resetAuth } from '../state/auth'
import { setConnectionError } from '../state/error'
import { loadingOff, loadingOn } from '../state/navigation'
import store from '../state/store.js'
import { isDefined } from '../utils'

export const Api = class {
  constructor (config, onSuccess, onError) {
    this.config = config
    this.onSuccess = onSuccess
    this.onError = onError
  }

  loadingOn () {
    store.dispatch(loadingOn())
  }

  loadingOff () {
    store.dispatch(loadingOff())
  }

  fetch (endpoint, language) {
    const { publicBackendUrl, apiUri } = this.config
    const uri = isDefined(language) ? `${publicBackendUrl}/${apiUri}/${endpoint}?language=${language}` : `${publicBackendUrl}/${apiUri}/${endpoint}`

    this.loadingOn()

    axios.get(uri).then(
      (response) => {
        store.dispatch(setConnectionError({ error: false }))
        this.loadingOff()
        this.onSuccess(response)
      },
      (error) => {
        const { message, code } = error
        const detail = error?.response?.data ?? message
        store.dispatch(setConnectionError({ error: true, detail, message, code }))
        this.loadingOff()
        this.onError(error)
      }
    )
  }
}

export const AuthorizedApi = class {
  constructor (config, onSuccess, onError) {
    this.config = config
    this.onSuccess = onSuccess
    this.onError = onError
  }

  loadingOn () {
    store.dispatch(loadingOn())
  }

  loadingOff () {
    store.dispatch(loadingOff())
  }

  clearConnectionError () {
    store.dispatch(setConnectionError({ error: false }))
  }

  connectionError (error) {
    const { message, code } = error
    const detail = error?.response?.data ?? message
    store.dispatch(setConnectionError({ error: true, detail, message, code }))
  }

  authExpired () {
    store.dispatch(authExpired())
  }

  resetAuth () {
    store.dispatch(resetAuth())
  }

  setTokens (access, refresh) {
    const tokens = { accessToken: access, refreshToken: refresh }
    store.dispatch(fetchAccessTokenSuccess(tokens))
  }

  anonymousFetch (endpoint) {
    const { publicBackendUrl, apiUri } = this.config
    axios.get(`${publicBackendUrl}/${apiUri}/${endpoint}`).then(
      (response) => {
        this.clearConnectionError()
        this.loadingOff()
        this.onSuccess(response)
      },
      (error) => {
        console.error(error)
        this.connectionError(error)
        this.loadingOff()
        this.onError(error)
      }
    )
  }

  fetchWithToken (endpoint, token, queryParameters) {
    const { publicBackendUrl, apiUri } = this.config
    let url = `${publicBackendUrl}/${apiUri}/${endpoint}`
    const pairs = []
    if (isDefined(queryParameters)) {
      for (const key in queryParameters) {
        const value = queryParameters[key]
        pairs.push(`${key}=${value}`)
      }
    }
    if (pairs.length > 0) {
      url = `${url}?${pairs.join('&')}`
    }
    const parameters = { headers: { Authorization: token } }
    axios.get(url, parameters).then(
      (response) => {
        this.clearConnectionError()
        this.loadingOff()
        this.onSuccess(response)
      },
      (error) => {
        console.error(error)
        this.connectionError(error)
        this.loadingOff()
        this.onError(error)
      }
    )
  }

  putWithToken (endpoint, token, data, queryParameters) {
    const { publicBackendUrl, apiUri } = this.config
    let url = `${publicBackendUrl}/${apiUri}/${endpoint}`
    const pairs = []
    if (isDefined(queryParameters)) {
      for (const key in queryParameters) {
        const value = queryParameters[key]
        pairs.push(`${key}=${value}`)
      }
    }
    if (pairs.length > 0) {
      url = `${url}?${pairs.join('&')}`
    }
    const parameters = { headers: { Authorization: token } }
    axios
      .put(url, data, parameters)
      .then(
        (response) => {
          this.clearConnectionError()
          this.loadingOff()
          this.onSuccess(response)
        },
        (error) => {
          console.error(error)
          this.connectionError(error)
          this.loadingOff()
          this.onError(error)
        }
      )
  }

  postWithToken (endpoint, token, data, queryParameters) {
    const { publicBackendUrl, apiUri } = this.config
    let url = `${publicBackendUrl}/${apiUri}/${endpoint}`
    const pairs = []
    if (isDefined(queryParameters)) {
      for (const key in queryParameters) {
        const value = queryParameters[key]
        pairs.push(`${key}=${value}`)
      }
    }
    if (pairs.length > 0) {
      url = `${url}?${pairs.join('&')}`
    }
    const parameters = { headers: { Authorization: token } }
    axios
      .post(url, data, parameters)
      .then(
        (response) => {
          this.clearConnectionError()
          this.loadingOff()
          this.onSuccess(response)
        },
        (error) => {
          console.error(error)
          this.connectionError(error)
          this.loadingOff()
          this.onError(error)
        }
      )
  }
}
