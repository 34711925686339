import { Box } from '@mui/material'
// import { useSelector } from 'react-redux'
// import { AchievementsBrowser } from './components/widgets'

const Menu = () => {
  // const achievements = useSelector((state) => state.achievements)
  // const { data } = achievements

  return (
    <Box className="companion-yaps-menu">
      {/* <AchievementsBrowser data={data} noData={{ label: 'nothing here - achievements' }}/> */}
    </Box>
  )
}

export { Menu }
