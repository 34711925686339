import { Typography } from '@mui/material'
import { Component } from 'react'
import { translateText, withTranslation } from '../../../language'

class TranslatableTypographyComponent extends Component {
  render () {
    const { t, i18n } = this.props.i18nEngine
    const { data } = this.props

    let text = ''
    if (data !== undefined && data.length > 0) {
      text = translateText(data, i18n)
    } else {
      text = t(this.props.children)
    }

    const customProps = {
      ...this.props,
      children: text
    }

    delete customProps.i18nEngine
    delete customProps.language
    delete customProps.data

    return <Typography {...customProps} />
  }
}

export const TranslatableTypography = withTranslation(
  TranslatableTypographyComponent
)

class ResizableTypographyComponent extends Component {
  render () {
    const { translate } = this.props
    const { t } = this.props.i18nEngine

    let text = this.props.children

    if (translate !== 'false') {
      text = t(this.props.children)
    }

    let size = 14

    if (text.length > 20) {
      size = 10
    }

    const customProps = {
      ...this.props,
      children: text,
      fontSize: size
    }

    delete customProps.i18nEngine
    delete customProps.language

    return (
      <Typography {...customProps} onClick={this.props.onClick}></Typography>
    )
  }
}

export const ResizableTypography = withTranslation(
  ResizableTypographyComponent
)
