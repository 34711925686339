import jwt_decode from 'jwt-decode'

const limitText = function (text, length) {
  if (text.length > length) {
    return text.substring(0, length - 6) + ' ...'
  } else {
    return text
  }
}

const withDefault = function (value, defaultValue) {
  if (value === undefined) {
    return defaultValue
  } else {
    return value
  }
}

const shouldUpdate = function (oldProps, newProps) {
  const currentLanguage = oldProps.language
  const nextLanguage = newProps.language
  const currentChecksum = oldProps.checksum
  const nextChecksum = newProps.checksum

  return currentChecksum !== nextChecksum || currentLanguage !== nextLanguage
}

const notDefined = function (data) {
  return JSON.stringify(data) === '{}' || data === undefined || data === null || data === ''
}

const isDefined = function (data) {
  return !notDefined(data)
}

const isEmpty = function (data) {
  return notDefined(data) || JSON.stringify(data) === '[]'
}

const mapDataPropsProvider = function (data, checksum, state) {
  const { dataError } = state.error
  const { loading } = state.navigation

  if (dataError || notDefined(data) || data.length === 0) {
    return {
      data: {},
      checksum: {},
      loading
    }
  } else {
    return {
      data: withDefault(data, []),
      checksum: withDefault(checksum, ''),
      loading
    }
  }
}

const shallowOpts = function (opts) {
  const newOpts = { ...opts }
  delete newOpts.data
  delete newOpts.checksum
  return newOpts
}

function loadFromStorage (key) {
  try {
    const auth = localStorage.getItem(key)
    return JSON.parse(auth)
  } catch (error) {
    return null
  }
}

function saveToStorage (key, content) {
  try {
    const serialized = JSON.stringify(content)
    localStorage.setItem(key, serialized)
  } catch (error) {}
}

function removeFromStorage (key) {
  try {
    localStorage.removeItem(key)
  } catch (error) {}
}

function handler (value) {
  return () => {
    return value
  }
}

function definedOrDefault (valueHandler, defaultHandler) {
  let value = defaultHandler()
  try {
    const temporaryValue = valueHandler()
    if (isDefined(temporaryValue) && !isEmpty(temporaryValue)) {
      value = temporaryValue
    }
  } catch {}

  return value
}

function mapAppConfig (state, props) {
  const config = state?.config?.config
  const newProps = isDefined(config)
    ? { ...props, config }
    : { ...props }
  return newProps
}

function baseUrl (config) {
  if (isDefined(config?.backendUrl) && isDefined(config?.apiUri)) {
    return `${config.backendUrl}/${config.apiUri}`
  } else if (isDefined(config?.publicBackendUrl) && isDefined(config?.apiUri)) {
    return `${config.publicBackendUrl}/${config.apiUri}`
  } else {
    return undefined
  }
}

function avatarUrl (config, url) {
  return url
}

function isTokenValid (token) {
  if (notDefined(token)) {
    console.warn('Token is not defined')
    return false
  }

  try {
    const decoded = jwt_decode(token)
    const { exp } = decoded
    const d = new Date()
    const seconds = d.getTime() / 1000 + 5
    const valid = seconds <= exp
    if (!valid) {
      console.warn('Token expired')
    }
    return valid
  } catch (error) {
    console.error('Token verification error')
    console.error(error)
    return false
  }
}

function convertDateFromUTC (date, time) {
  const fullyQualifiedDate = `${date}T${time}Z`
  const convertedDate = new Date(fullyQualifiedDate)
  return convertedDate
}

function didConfigChange (c1, c2) {
  return (
    c1.apiUri !== c2.apiUri ||
    c1.publicBackendUrl !== c2.publicBackendUrl ||
    c1.version !== c2.version
  )
}

class DefaultMap extends Map {
  get (key) {
    if (!this.has(key)) {
      this.set(key, this.default())
    }
    return super.get(key)
  }

  constructor (defaultFunction, entries) {
    super(entries)
    this.default = defaultFunction
  }
}

export {
  DefaultMap, avatarUrl, baseUrl, convertDateFromUTC, definedOrDefault, didConfigChange, handler, isDefined, isEmpty, isTokenValid, limitText, loadFromStorage, mapAppConfig, mapDataPropsProvider, notDefined, removeFromStorage, saveToStorage, shallowOpts, shouldUpdate, withDefault
}
