import { createSlice } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const initialState = {
  data: [],
  error: '',
  selected: 0,
  checksum: '',
  updated: ''
}

const map = createSlice({
  name: 'map',
  initialState,
  reducers: {
    fetchMapSuccess (state, action) {
      const { data, checksum, generated } = action.payload
      return {
        ...state,
        data,
        checksum,
        updated: generated,
        error: ''
      }
    },
    fetchMapError (state, action) {
      const { detail } = action.payload

      return {
        ...state,
        error: detail
      }
    },
    changeSelection (state, action) {
      const { selected } = action.payload
      return {
        ...state,
        selected
      }
    }
  }
})

const persistConfig = {
  key: 'map',
  storage
}

export const { fetchMapSuccess, fetchMapError, changeSelection } = map.actions
export const MapReducer = persistReducer(persistConfig, map.reducer)
