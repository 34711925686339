import { ThemeProvider } from '@emotion/react'
import { CssBaseline } from '@mui/material'
import { BrowserRouter } from 'react-router-dom'
import { theme } from './theme'
import Body from './view/components/Body'
import Footer from './view/components/Footer'
import Header from './view/components/Header'

import Routing from './view/Routing'
import { ConfigProvider } from './view/components/ConfigProvider'
import { Disconnected } from './view/components/widgets'
import BackgroundImageWithAlfa from './view/components/widgets/BackgroundImageWithAlfa.js'

export default function App () {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <CssBaseline />
        <Header />
        <Body>
          <ConfigProvider>
            <Routing />
          </ConfigProvider>
        </Body>
        <Disconnected />
        <BackgroundImageWithAlfa />
        <Footer />
      </BrowserRouter>
    </ThemeProvider>
  )
}
