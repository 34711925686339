import {
  Box
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { notDefined } from '../../../utils'
import { Card, NoData } from '../widgets'

const coninfoMessagesStyle = {
  main: {
    width: '100%',
    height: '100%'
  },
  wrapper: {
    padding: '5px'
  },
  item: {
    marginBottom: '5px'
  }
}

export const Messages = (props) => {
  const { i18n } = useTranslation()
  const { t } = i18n
  const { data, loading } = props

  if (loading && notDefined(data)) {
    return undefined
  }
  if (notDefined(data) || data.length === 0) {
    return (
      <Box>
        <NoData label="no-announcements"/>
      </Box>
    )
  }
  const messages = data
    .filter((f) => f?.language === i18n?.language)
    .flatMap((m) => m?.text)

  const items = messages.map((message, i) => {
    return (
      <Card
        sx={coninfoMessagesStyle.item}
        key={i}
        title={t('convention-announcement')}
        text={message}
        icon='Forum'
      />
    )
  })

  return (
    <Box sx={coninfoMessagesStyle.main}>
      <Box sx={coninfoMessagesStyle.wrapper}>{items}</Box>
    </Box>
  )
}
