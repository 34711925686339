import {
  AuthErrorDialog,
  LoginDialog,
  LogoutDialog,
  NotRegisteredErrorDialog
} from './components/widgets'

function Login () {
  return <LoginDialog />
}

function Logout () {
  return <LogoutDialog />
}

function AuthError () {
  return <AuthErrorDialog />
}

function NotRegisteredError () {
  return <NotRegisteredErrorDialog />
}

export { AuthError, Login, Logout, NotRegisteredError }
