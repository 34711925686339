/**
 * React, react redux and react localization imports
 */
import { Component } from 'react'
import { connect } from 'react-redux'

/**
 * Material imports
 */
import {
  Box,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material'

/**
 * Relative exports to root directory
 */
import { withTranslation } from '../../../language'
import { selectLanguage } from '../../../state/lang'
import store from '../../../state/store'
import { pallete } from '../../../theme'
import { isDefined } from '../../../utils'
import CZIcon from './lang-icons/CZIcon.js'
import ENIcon from './lang-icons/ENIcon.js'

const style = {
  container: {
    color: pallete.paperColor,
    padding: '0px'
  },
  button: {
    color: pallete.paperColor,
    textAlign: 'center',
    padding: '0px',
    margin: '0px',
    height: '40px',
    minWidth: '0px',
    marginRight: '10px  '
  },
  text: {
    color: pallete.paperColor,
    textShadow: `1px 1px 3px ${pallete.textColor}`,
    fontWeight: 800
  }
}

/**
 * Component for selecting active language in application.
 * It's connected to redux state and using react i18next as language engine.
 */
class LanguageSelectTextComponent extends Component {
  render () {
    const { language, i18nEngine, refresh } = this.props
    const { t, i18n } = i18nEngine

    if (isDefined(language) && language !== i18n.language && !refresh) {
      i18n.changeLanguage(language)
    }

    return (
      <Box
        data-component="language-select"
        data-active-language={language}
        sx={style.container}
      >
        <Button
          sx={style.button}
          onClick={() =>
            this.props.selectLanguage(i18n, t('toggle_next_language'))
          }
        >
          <Typography sx={style.text}>
            {t('toggle_next_language_label')}
          </Typography>
        </Button>
      </Box>
    )
  }
}

const flagsStyle = {
  group: {
    height: '30px',
    '& .MuiButtonBase-root': {
      padding: '5px',
      paddingTop: '13px'
    }
  },
  button: {
    border: 0,
    '&:hover': {
      borderRadius: 0
    },
    '&.Mui-selected, &.Mui-selected:hover': {
      color: pallete.paperColor,
      backgroundColor: pallete.headerColor,
      borderRadius: 0
    }
  }
}

/**
 * Component for selecting active language in application.
 * It's connected to redux state and using react i18next as language engine.
 */
class LanguageSelectFlagsComponent extends Component {
  render () {
    const { language, i18nEngine } = this.props
    const { i18n } = i18nEngine

    const control = {
      value: language,
      onChange: (e, language) => {
        if ((language !== null) & (language !== this.props.language)) {
          this.props.selectLanguage(i18n, language)
        }
      }
    }

    return (
      <Box>
        <ToggleButtonGroup
          exclusive
          size="small"
          sx={flagsStyle.group}
          {...control}
        >
          <ToggleButton value="en" key="en" sx={flagsStyle.button}>
            <ENIcon />
          </ToggleButton>
          <ToggleButton value="cs" key="cs" sx={flagsStyle.button}>
            <CZIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    language: state.language.active,
    refresh: state.language.refresh
  }
}

const mapDispatchToProps = () => {
  return {
    selectLanguage: (i18n, language) => {
      i18n.changeLanguage(language)
      store.dispatch(selectLanguage(language))
    }
  }
}

const LanguageFlags = withTranslation(
  connect(mapStateToProps, mapDispatchToProps)(LanguageSelectFlagsComponent)
)
const LanguageSelectText = withTranslation(
  connect(mapStateToProps, mapDispatchToProps)(LanguageSelectTextComponent)
)

export { LanguageFlags, LanguageSelectText }
