/**
 * React, react redux and react localization imports
 */

import { Component } from 'react'
// import { connect } from 'react-redux';

import { login } from '../../../api/auth'

/**
 * Material imports
 */
import GppBadIcon from '@mui/icons-material/GppBad'
import GppGoodIcon from '@mui/icons-material/GppGood'
import RemoveModeratorIcon from '@mui/icons-material/RemoveModerator'

/**
 * Relative exports to root directory
 */
import { Box, Paper } from '@mui/material'
import { connect } from 'react-redux'
import { withTranslation } from '../../../language'
import { pallete } from '../../../theme'
import { mapAppConfig } from '../../../utils'
import { TranslatableButton } from './Button'
import { TranslatableTypography } from './Typography'

const dialogStyle = {
  box: {
    width: '100%',
    minHeight: '400px',
    padding: '5px'
  },
  container: {
    width: '100%',
    height: '100%',
    position: 'relative',
    border: `1px dashed ${pallete.headerColor}`,
    filter: `drop-shadow(0px 0px 3px ${pallete.headerBrightColor})`
  },
  wrapper: {
    padding: '15px',
    textAlign: 'center'
  },
  icon: { fontSize: 120, color: pallete.headerColor },
  typography: { fontWeight: 600, color: pallete.headerColor },
  button: { marginTop: '30px' }
}

class LoginDialogComponent extends Component {
  render () {
    const { config, language } = this.props

    return (
      <Box sx={dialogStyle.box}>
        <Paper sx={dialogStyle.container}>
          <Box sx={dialogStyle.wrapper}>
            <GppGoodIcon sx={dialogStyle.icon} />
            <TranslatableTypography sx={dialogStyle.typography}>
              companion-authorization
            </TranslatableTypography>
            <TranslatableButton
              variant="filled"
              sx={dialogStyle.button}
              onClick={() => login(config, language)}
            >
              Continue
            </TranslatableButton>
          </Box>
        </Paper>
      </Box>
    )
  }
}

const LoginDialog = withTranslation(
  connect(mapAppConfig)(LoginDialogComponent)
)

class LogoutDialogComponent extends Component {
  render () {
    return (
      <Box sx={dialogStyle.box}>
        <Paper sx={dialogStyle.container}>
          <Box sx={dialogStyle.wrapper}>
            <RemoveModeratorIcon sx={dialogStyle.icon} />
            <TranslatableTypography sx={dialogStyle.typography}>
              logout-success
            </TranslatableTypography>
          </Box>
        </Paper>
      </Box>
    )
  }
}

const LogoutDialog = withTranslation(LogoutDialogComponent)

const loginMenuItemStyle = {
  wrapper: {
    fontSize: 14,
    color: pallete.paperColor,
    textAlign: 'center',
    padding: '15px'
  },
  icon: {},
  typography: { marginTop: '15px' },
  button: { marginTop: '15px' }
}

class LoginMenuItemComponent extends Component {
  render () {
    const { config, language } = this.props

    return (
      <Box sx={loginMenuItemStyle.wrapper}>
        <GppGoodIcon sx={loginMenuItemStyle.icon} />

        <TranslatableTypography sx={loginMenuItemStyle.typography}>
          user-menu
        </TranslatableTypography>

        <TranslatableTypography sx={loginMenuItemStyle.typography}>
          companion-authorization
        </TranslatableTypography>
        <TranslatableButton
          variant="invert-contained"
          sx={loginMenuItemStyle.button}
          onClick={() => login(config, language)}
        >
          login
        </TranslatableButton>
      </Box>
    )
  }
}

const LoginMenuItem = withTranslation(
  connect(mapAppConfig)(LoginMenuItemComponent)
)

const errorDialogStyle = {
  box: {
    width: '100%',
    minHeight: '400px',
    padding: '5px'
  },
  container: {
    width: '100%',
    height: '100%',
    position: 'relative',
    border: `1px dashed ${pallete.headerColor}`,
    filter: `drop-shadow(0px 0px 3px ${pallete.headerBrightColor})`
  },
  wrapper: {
    padding: '15px',
    textAlign: 'center'
  },
  icon: { fontSize: 120, color: pallete.headerColor },
  typography: { fontWeight: 600, color: pallete.headerColor },
  button: { marginTop: '30px' }
}

class ErrorDialog extends Component {
  render () {
    const { config, buttonText, language } = this.props

    return (
      <Box sx={errorDialogStyle.box}>
        <Paper sx={errorDialogStyle.container}>
          <Box sx={errorDialogStyle.wrapper}>
            <GppBadIcon sx={errorDialogStyle.icon} />
            <TranslatableTypography sx={errorDialogStyle.typography}>
              {this.props.children}
            </TranslatableTypography>
            <TranslatableButton
              variant="filled"
              sx={errorDialogStyle.button}
              onClick={() => login(config, language)}
            >
              {buttonText}
            </TranslatableButton>
          </Box>
        </Paper>
      </Box>
    )
  }
}

class AuthErrorDialogComponent extends Component {
  render () {
    return (
      <ErrorDialog {...this.props} buttonText="retry">
        companion-authorization
      </ErrorDialog>
    )
  }
}

const AuthErrorDialog = withTranslation(
  connect(mapAppConfig)(AuthErrorDialogComponent)
)

class NotRegisteredErrorDialogComponent extends Component {
  render () {
    return <ErrorDialog>not-registered</ErrorDialog>
  }
}

const NotRegisteredErrorDialog = withTranslation(
  NotRegisteredErrorDialogComponent
)

class AutomaticallyLogoutedDialogComponent extends Component {
  render () {
    return (
      <ErrorDialog {...this.props} buttonText="login">
        authentication-expired
      </ErrorDialog>
    )
  }
}

const AutomaticallyLogoutedDialog = withTranslation(
  connect(mapAppConfig)(AutomaticallyLogoutedDialogComponent)
)

class NoAccessDialogComponent extends Component {
  render () {
    return (
      <ErrorDialog {...this.props} buttonText="login">
        no-access
      </ErrorDialog>
    )
  }
}

const NoAccessDialog = withTranslation(
  connect(mapAppConfig)(NoAccessDialogComponent)
)

export {
  AuthErrorDialog, AutomaticallyLogoutedDialog, LoginDialog, LoginMenuItem, LogoutDialog, NoAccessDialog, NotRegisteredErrorDialog
}
