/**
 * React, react redux and react localization imports
 */
import { Component } from 'react'
// import { connect } from 'react-redux';

/**
  * Relative exports to root directory"editor.codeActionsOnSave": {
   "source.organizeImports": true
 }
  */
import { Box, CircularProgress, Paper } from '@mui/material'
import { withTranslation } from '../../../language'
import { pallete } from '../../../theme'
import { TranslatableTypography } from './Typography'

const configLoadingStyle = {
  box: {
    width: '100%',
    minHeight: '400px',
    padding: '5px'
  },
  container: {
    width: '100%',
    height: '100%',
    position: 'relative',
    border: `1px dashed ${pallete.headerColor}`,
    filter: `drop-shadow(0px 0px 3px ${pallete.headerBrightColor})`
  },
  wrapper: {
    padding: '35px',
    textAlign: 'center'
  },
  errorIcon: { fontSize: 120, color: pallete.headerColor },
  typography: { fontWeight: 600, color: pallete.headerColor }
}

class ConfigLoadingComponent extends Component {
  render () {
    return (
      <Box sx={configLoadingStyle.box}>
        <Paper sx={configLoadingStyle.container}>
          <Box sx={configLoadingStyle.wrapper}>
            <CircularProgress color="primary" />
            <TranslatableTypography>
              Application is loading
            </TranslatableTypography>
          </Box>
        </Paper>
      </Box>
    )
  }
}

const ConfigLoading = withTranslation(ConfigLoadingComponent)

export { ConfigLoading }
