import ErrorIcon from '@mui/icons-material/Error'
import { Box, CircularProgress, Grid, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { requestWithUserAuth } from '../../../api/auth'
import { createYap } from '../../../api/yaps'
import { setConnectionError } from '../../../state/error'
import { setCreateDisabled, setCreatingYap, setDraftedMessage, setValidationError } from '../../../state/yaps'
import { pallete } from '../../../theme'
import { isDefined } from '../../../utils'
import { Button, CountDown, ErrorModal } from '../widgets'

const sendStyle = {
  background: pallete.headerColor,
  padding: '5px',
  '& .companion-yaps-send-input-wrapper': {
    padding: '5px'
  },
  '& .companion-yaps-send-button-wrapper': {
    textAlign: 'center',
    lineHeight: '56px',
    display: 'flex',
    justifyContent: 'center',
    '& .MuiButtonBase-root': {
      height: '40px',
      marginTop: '12px'
    },
    '& .companion-yaps-send-progress-wrapper': {
      height: '50px',
      paddingTop: '5px'
    }
  },
  '& .MuiCircularProgress-root': {
    color: 'white'
  },
  '& .companion-yaps-send-input': {
    width: '100%',
    '& .Mui-focused': {
      color: pallete.paperColor
    }
  },
  '& .companion-countdown': {
    marginTop: '10px'
  }
}

const Send = (props) => {
  const { auth, config, language, error, created, draftedMessage, validationError } = props
  const creating = props.creating ?? false
  const createDisabled = props.createDisabled ?? false
  const dispatch = useDispatch()
  const translation = useTranslation()
  const { t } = translation.i18n

  const sendEvent = () => {
    if (isDefined(draftedMessage)) {
      dispatch(setCreatingYap({ creating: true }))
      requestWithUserAuth(config, auth, (auth) =>
        createYap(config, auth, draftedMessage, language)
      )
    } else {
      dispatch(setValidationError({ validationError: 'empty-yap-validation-error' }))
    }
  }

  const changeEvent = (value) => {
    dispatch(setDraftedMessage({ draftedMessage: value }))
    if (isDefined(value.replace(/\s/g, ''))) {
      dispatch(setValidationError({ validationError: '' }))
    } else {
      dispatch(setValidationError({ validationError: 'empty-yap-validation-error' }))
    }
  }

  const difference = isDefined(created) ? Math.floor((new Date().getTime() - created) / 1000) : 100000

  let sendControl
  const disabled = createDisabled && difference < 30
  if (disabled) {
    const remaining = 30 - difference
    sendControl = <CountDown remaining={remaining} onTrigger={ () => dispatch(setCreateDisabled({ createDisabled: false })) }/>
  } else {
    const progress = <Box className="companion-yaps-send-progress-wrapper"><CircularProgress /></Box>
    sendControl = creating ? progress : <Button disabled={ isDefined(validationError)} onClick={() => sendEvent()} label='send' variant="invert-contained" />
  }
  const closeModalHandler = () => {
    dispatch(setConnectionError({ error: false, detail: '' }))
  }
  const modalProps = {
    label: error.errorDetail?.detail,
    open: error.connectionError,
    onClose: () => closeModalHandler(),
    icon: (<ErrorIcon />)
  }

  const textFieldProps = {
    onChange: (e) => changeEvent(e.target.value),
    disabled,
    label: t('new-yap'),
    variant: 'standard',
    value: draftedMessage
  }

  const textFieldPropsWithValidation = isDefined(validationError) ? { ...textFieldProps, error: true, helperText: t(validationError) } : textFieldProps

  return (
    <Grid container className="companion-yaps-send" sx={sendStyle}>
      <Grid xs={9} item className="companion-yaps-send-input-wrapper" >
        <TextField className="companion-yaps-send-input" {...textFieldPropsWithValidation}/>
      </Grid>
      <Grid xs={3} item className="companion-yaps-send-button-wrapper" >
        <ErrorModal {...modalProps}/>
        {sendControl}
      </Grid>
    </Grid>
  )
}

export { Send }
