import { createSlice, current } from '@reduxjs/toolkit'

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { notDefined } from '../../utils'

const initialState = {
  data: [],
  error: '',
  checksum: '',
  updated: ''
}

const notifications = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    fetchNotificationsSuccess (state, action) {
      const { data, checksum, generated } = action.payload
      const { category } = current(state)
      const categories = [...new Set(data.map((element) => element.category)), 'coninfo']
      let useCategory = category
      if (notDefined(useCategory) && categories.length > 0) {
        useCategory = categories[0]
      }
      return {
        ...state,
        data,
        categories,
        category: useCategory,
        checksum,
        updated: generated,
        error: ''
      }
    },
    fetchNotificationsError (state, action) {
      const { detail } = action.payload

      return {
        ...state,
        error: detail
      }
    },
    useCategory (state, action) {
      const { category } = action.payload
      return {
        ...state,
        category
      }
    }

  }
})

const persistConfig = {
  key: 'notifications',
  storage
}

export const { fetchNotificationsSuccess, fetchNotificationsError, useCategory } =
  notifications.actions
export const NotificationsReducer = persistReducer(
  persistConfig,
  notifications.reducer
)
