import axios from 'axios'
import { AuthorizedApi } from '.'
import {
  authExpired,
  clearExpired,
  fetchAccessTokenSuccess,
  resetAuth
} from '../state/auth'
import { loadingOff, loadingOn } from '../state/navigation'
import store from '../state/store.js'
import { isDefined, isTokenValid, notDefined } from '../utils'

export function login (config, language) {
  const api = new AuthorizedApi(
    config,
    (success) => {
      const { data } = success
      store.dispatch(clearExpired())
      window.location.href = data.url
    },
    (error) => {
      console.error(error)
    }
  )

  const url = isDefined(language) ? 'user/login?language=' + language : 'user/login'
  api.anonymousFetch(url)
}

export function logout (config, auth) {
  const api = new AuthorizedApi(
    config,
    (success) => {
      store.dispatch(resetAuth())
    },
    (error) => {
      console.error(error)
      store.dispatch(resetAuth())
    }
  )

  api.fetchWithToken('user/logout', auth?.authToken)
}

function requestNewTokensProxy (config, auth, requestFunction) {
  const parameters = { headers: { token: auth?.authToken } }
  const { publicBackendUrl, apiUri } = config
  const baseUrl = `${publicBackendUrl}/${apiUri}`
  axios
    .get(`${baseUrl}/user/auth`, parameters)
    .then(function (response) {
      const { access_token, refresh_token } = response?.data
      const tokenData = {
        accessToken: access_token,
        refreshToken: refresh_token
      }
      store.dispatch(fetchAccessTokenSuccess(tokenData))
      requestFunction(tokenData)
    })
    .catch(({ message, name, code }) => {
      console.error(message, name, code)
      console.error(
        'Failed to request new tokens, considering CAT Authorization Token outdated, forcing re-login'
      )
      store.dispatch(loadingOff())
      store.dispatch(authExpired())
      store.dispatch(resetAuth())
    })
}

function refreshTokensProxy (config, auth, requestFunction) {
  const parameters = { headers: { token: auth?.refreshToken } }
  const { publicBackendUrl, apiUri } = config
  const baseUrl = `${publicBackendUrl}/${apiUri}`
  axios
    .get(`${baseUrl}/user/refresh`, parameters)
    .then(function (response) {
      const { access_token, refresh_token } = response?.data
      const tokenData = {
        accessToken: access_token,
        refreshToken: refresh_token
      }
      store.dispatch(fetchAccessTokenSuccess(tokenData))
      requestFunction(tokenData)
    })
    .catch(({ message, name, code }) => {
      console.error(message, name, code)
      console.error(
        'Failed to refresh tokens, requesting new refresh and access tokens'
      )
      requestNewTokensProxy(config, auth, requestFunction)
    })
}

export function requestWithUserAuth (config, auth, requestFunction, anonymousFunction) {
  if (notDefined(auth?.authToken)) {
    if (isDefined(anonymousFunction)) {
      console.info('Auth token not defined, trying anonymously')
      store.dispatch(loadingOn())
      anonymousFunction()
    } else {
      console.error('Auth token not defined, doing nothing')
    }
    return
  }

  store.dispatch(loadingOn())
  if (isTokenValid(auth?.authToken)) {
    if (isTokenValid(auth?.accessToken)) {
      requestFunction(auth)
    } else if (isTokenValid(auth?.refreshToken)) {
      refreshTokensProxy(config, auth, requestFunction)
    } else {
      requestNewTokensProxy(config, auth, requestFunction)
    }
  } else {
    console.error('CAT Authorization Token outdated, forcing re-login')
    store.dispatch(loadingOff())
    store.dispatch(authExpired())
    store.dispatch(resetAuth())
  }
}
