import { createSlice } from '@reduxjs/toolkit'

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const initialState = {
  data: [],
  error: '',
  checksum: '',
  updated: ''
}

const screens = createSlice({
  name: 'consite_screens',
  initialState,
  reducers: {
    fetchConsiteScreensSuccess (state, action) {
      const { data, checksum, generated } = action.payload
      return {
        ...state,
        data,
        checksum,
        updated: generated,
        error: ''
      }
    },
    fetchConsiteScreensError (state, action) {
      const { detail } = action.payload

      return {
        ...state,
        error: detail
      }
    }
  }
})

const persistConfig = {
  key: 'consite_screens',
  storage
}

export const { fetchConsiteScreensSuccess, fetchConsiteScreensError } =
  screens.actions
export const ConinfoScreensReducer = persistReducer(
  persistConfig,
  screens.reducer
)
