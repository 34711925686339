import PetsIcon from '@mui/icons-material/Pets'
import {
  Box,
  Paper,
  Typography
} from '@mui/material'

import { pallete } from '../../../theme'
import { notDefined } from '../../../utils'
import { NoData, TranslatableTypography } from '../widgets'

const barStyle = {
  main: {
    width: '100%',
    height: '100%'
  },
  wrapper: {
    padding: '5px',
    '&.bar_item:nth-of-type(even)': {
      background: pallete.paperColorDim2
    },
    '&.bar_item:nth-of-type(odd)': {
      background: pallete.paperColorDim
    }
  },
  paper: {
    pading: '5px'
  },
  title: {
    color: pallete.headerColor,
    padding: '15px',
    textDecoration: 'underline',
    fontStyle: 'italic'
  },
  header: {
    wrapper: {
      padding: '0px 15px 5px 15px',
      color: pallete.headerColor
    },
    iconLine: {
      display: 'inline-block',
      lineHeight: '35px',
      position: 'relative'
    },
    iconText: {
      width: '35px',
      height: '35px',
      textAlign: 'center',
      position: 'absolute',
      fontSize: 32,
      fontWeight: 800
    },
    icon: {
      width: '35px',
      height: '35px',
      marginRight: '5px',
      opacity: '0.2'
    },
    title: {
      display: 'inline',
      lineHeight: '35px'
    }
  },
  body: {
    wrapper: {
      padding: '5px'
    },
    description: {
      padding: '0px 20px 0px 20px',
      textAlign: 'justify',
      fontSize: '0.9rem'
    }
  },
  footer: {
    wrapper: { color: pallete.headerColor, textAlign: 'center' },
    subTitle: { display: 'inline', fontSize: '0.75rem' }
  }
}

const BarItem = (props) => {
  const { data } = props
  const { titles, sub_titles, descriptions, order } = data

  return (
    <Box sx={barStyle.wrapper} className="bar_item">
      <Box sx={barStyle.header.wrapper}>
        <Box sx={barStyle.header.iconLine}>
          <Typography sx={barStyle.header.iconText}>{order}</Typography>
          <PetsIcon sx={barStyle.header.icon} />
        </Box>
          <TranslatableTypography sx={barStyle.header.title} data={titles}/>
      </Box>
      <Box sx={barStyle.body.wrapper}>
        <TranslatableTypography sx={barStyle.body.description} data={descriptions}/>
      </Box>
      <Box sx={barStyle.footer.wrapper}>
        <TranslatableTypography sx={barStyle.footer.subTitle} data={sub_titles}/>
      </Box>
    </Box>
  )
}

export const Bar = (props) => {
  const { data, loading } = props
  if (loading && notDefined(data)) {
    return undefined
  }

  if (notDefined(data)) {
    return (
      <Box>
        <NoData />
      </Box>
    )
  }

  const items = data.map((item, i) => (<BarItem key={i} data={item}/>))

  return (
    <Box sx={barStyle.main}>
      <Box sx={barStyle.wrapper}>
        <Paper sx={barStyle.paper}>
            <TranslatableTypography sx={barStyle.title}>
              on-bar
            </TranslatableTypography>
            <Box>{items}</Box>
        </Paper>
      </Box>
    </Box>
  )
}
