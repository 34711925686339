/**
 * React, react redux and react localization imports
 */
import { Component } from 'react'
// import { connect } from 'react-redux';

/**
 * Material imports
 */
import {
  Avatar,
  Box,
  Grid,
  Card as MUICard,
  CardContent as MUICardContent,
  CardMedia as MUICardMedia,
  Typography
} from '@mui/material'

import * as Muicon from '@mui/icons-material'

/**
 * Relative exports to root directory
 */
import { Link } from 'react-router-dom'
import { withTranslation } from '../../../language'
import { pallete } from '../../../theme'
import { isDefined } from '../../../utils'
import { TranslatableButton } from './Button'

const cardStyle = {
  container: {
    outer: { display: 'flex' },
    inner: { display: 'flex', flexDirection: 'column', height: '100%' },
    content: { height: 'calc(100% - 40px)' }
  },
  title: {
    outer: {
      width: '100%',
      minHeight: '30px',
      maxHeight: '60px',
      overflowWrap: 'break-word',
      textAlign: 'left',
      paddingLeft: '10px',
      background: pallete.headerColor,
      overflow: 'hidden'
    },
    label: { width: '100%', lineHeight: '30px', color: pallete.paperColor }
  },
  media: {
    outer: { width: '30%' },
    component: {
      width: '100%',
      height: '100%',
      '& .img': { width: '30%' }
    }
  },
  actions: {
    component: { textAlign: 'right', height: 40 }
  },
  '&': {
    width: '100%'
  },
  '& .companion-card-avatar-outer': {
    padding: '10px 5px 0px 5px',
    overflow: 'hidden',
    textAlign: 'center',
    '& .MuiAvatar-root': {
      background: pallete.headerColor,
      lineHeight: '40px',
      display: 'inline-block'
    }
  },
  '& .companion-card-title': {
    paddingLeft: '10px',
    color: 'white',
    overflow: 'hidden',
    background: pallete.headerColor,
    '& p': {
      fontSize: '0.85rem',
      color: pallete.paperColorDim2
    }
  },
  '& .companion-card-content p': {
    width: '100%',
    lineBreak: 'anywhere'
  }
}

export const CompanionCardWithAvatar = (props) => {
  const { title, text, src } = props

  return (
    <MUICard className="companion-card-root" sx={cardStyle}>
      <Grid container>
        <Grid item xs={2} md={1} className="companion-card-avatar-outer">
          <Avatar src={src}>{(title ?? text).substring(0, 1)}</Avatar>
        </Grid>
        <Grid item xs={10} md={11}>
          <Grid container>
            <Grid item xs={12} className="companion-card-title">
              <Typography>{title}</Typography>
            </Grid>
            <Grid item xs={12} className="companion-card-content">
              <MUICardContent>
                <Typography>{text}</Typography>
              </MUICardContent>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MUICard>
  )
}

class CardComponent extends Component {
  render () {
    const {
      title,
      media,
      icon,
      text,
      actions,
      sx,
      style,
      max,
      fontSize,
      link
    } = this.props

    let cardTitle = ''

    if (title !== undefined) {
      cardTitle = (
        <Box sx={cardStyle.title.outer}>
          <Typography sx={cardStyle.title.label}>{title}</Typography>
        </Box>
      )
    }

    let cardMedia = ''
    let contentWidth = '100%'

    if (media !== undefined) {
      cardMedia = (
        <Box sx={cardStyle.media.outer}>
          <MUICardMedia sx={cardStyle.media.component} {...media} />
        </Box>
      )

      contentWidth = '70%'
    }

    if (icon !== undefined && media === undefined) {
      const Icon = Muicon[icon] ? Muicon[icon] : Muicon.NoAccounts

      cardMedia = (
        <Box
          sx={{
            ...cardStyle.media.outer,
            textAlign: 'center',
            width: '50px',
            float: 'left'
          }}
        >
          <Icon
            sx={{
              width: '45px',
              height: '45px',
              marginTop: '10px',
              color: pallete.headerColor
            }}
          />
        </Box>
      )

      contentWidth = 'calc(100% - 50px)'
    }

    let textContent = ''

    if (text !== undefined) {
      if (max !== undefined) {
        textContent = (
          <Typography fontSize={fontSize}>
            {text.substring(0, max - 4)} ...
          </Typography>
        )
      } else {
        textContent = <Typography fontSize={fontSize}>{text}</Typography>
      }
    }

    let cardActions
    if (actions !== undefined) {
      cardActions = <Box sx={cardStyle.actions.component}>{actions}</Box>
    }

    if (isDefined(link) && link.startsWith('/')) {
      cardActions = (
        <Box sx={cardStyle.actions.component}>
          <Link to={link}>
            <TranslatableButton>Open</TranslatableButton>
          </Link>
        </Box>
      )
    } else if (isDefined(link)) {
      cardActions = (
        <Box sx={cardStyle.actions.component}>
          <TranslatableButton href={link} target="_blank">Open</TranslatableButton>
        </Box>
      )
    }
    const propsProxy = {
      sx,
      style
    }

    return (
      <MUICard
        className="companion-card"
        sx={cardStyle.container.outer}
        {...propsProxy}
      >
        {cardMedia}
        <Box sx={{ ...cardStyle.container.inner, width: contentWidth }}>
          {cardTitle}
          <Box sx={cardStyle.container.content}>
            <MUICardContent>
              {textContent}
              {this.props.children}
            </MUICardContent>
          </Box>
          {cardActions}
        </Box>
      </MUICard>
    )
  }
}

export const Card = withTranslation(CardComponent)

const votingSelectorStyle = {
  container: { height: '100%', width: '100%' },
  votePlaceholder: {
    outer: { padding: '5px', overflow: 'hidden', height: '300px' },
    inner: {
      border: `2px dashed ${pallete.headerColor}`,
      background: pallete.headerTransparentThinColor,
      cursor: 'pointer',
      height: '100%',
      width: '100'
    }
  },
  votingAvatar: {
    container: {
      background: pallete.headerColor,
      textAlign: 'center',
      width: '100%'
    },
    label: { color: pallete.paperColor }
  },
  title: {
    container: {
      width: '100%',
      height: '30px',
      textAlign: 'left',
      paddingLeft: '10px',
      background: pallete.headerColor,
      overflow: 'hidden'
    },
    label: { width: '100%', lineHeight: '30px', color: pallete.paperColor }
  },
  content: {
    outer: { padding: '5px' },
    inner: { paddingLeft: '0px' }
  },
  actionContainer: {
    outer: { textAlign: 'center', height: '40px' },
    button: {
      width: '90%',
      fontWeight: 'small',
      borderRadius: '25px',
      background: pallete.headerColor,
      color: pallete.paperColor,
      '&:hover': { background: pallete.headerColor }
    }
  }
}

class VotingProfileCardComponent extends Component {
  render () {
    const { title, text, max, fontSize, sx, style, vote } = this.props

    let cardTitle = ''

    if (title !== undefined) {
      cardTitle = (
        <Box sx={votingSelectorStyle.title.container}>
          <Typography
            sx={{
              ...votingSelectorStyle.title.label,
              textTransform: 'uppercase'
            }}
          >
            {title}
          </Typography>
        </Box>
      )
    }

    let textContent = ''

    if (text !== undefined) {
      if (max !== undefined) {
        textContent = (
          <Typography fontSize={fontSize}>
            {text.substring(0, max - 4)} ...
          </Typography>
        )
      } else {
        textContent = <Typography fontSize={fontSize}>{text}</Typography>
      }
    }

    let voteContent = ''
    let buttonLabel = ''

    if (vote !== undefined) {
      buttonLabel = 'changeVote'
      voteContent = (
        <Box>
          <MUICardMedia {...vote}></MUICardMedia>
          <Box sx={votingSelectorStyle.votePlaceholder.container}>
            <Typography sx={votingSelectorStyle.votingAvatar.label}>
              {vote.alt}
            </Typography>
          </Box>
        </Box>
      )
    } else {
      buttonLabel = 'voteLabel'
      voteContent = (
        <Grid xs={12} item style={votingSelectorStyle.votePlaceholder.outer}>
          <Box sx={votingSelectorStyle.votePlaceholder.inner}></Box>
        </Grid>
      )
    }

    const mainContent = (
      <Grid xs={12} item style={votingSelectorStyle.content.outer}>
        <MUICardContent sx={votingSelectorStyle.container.inner}>
          {textContent}
          {this.props.children}
        </MUICardContent>

        <Box sx={votingSelectorStyle.actionContainer.outer}>
          <TranslatableButton sx={votingSelectorStyle.actionContainer.button}>
            {buttonLabel}
          </TranslatableButton>
        </Box>
      </Grid>
    )

    const propsProxy = {
      sx,
      style
    }

    return (
      <MUICard {...propsProxy}>
        <Box sx={votingSelectorStyle.container}>
          {cardTitle}
          <Grid container>
            {voteContent}
            {mainContent}
          </Grid>
        </Box>
      </MUICard>
    )
  }
}

export const VotingProfileCard = withTranslation(VotingProfileCardComponent)
