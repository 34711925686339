import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { AuthorizationGate } from './components/Authorization'
import { Browser, Menu } from './components/events'

const style = {
  menu: {
    width: '100%'
  }
}

const EventsHome = () => {
  const schedule = useSelector((state) => state.schedule)
  const { data, assignedCount } = schedule

  return (
    <Box>
      <Menu sx={style.menu} assignedCount={assignedCount} />
      <Browser data={data} />
    </Box>
  )
}

const EventsFavorite = () => {
  const schedule = useSelector((state) => state.schedule)
  const { favorite, assignedCount } = schedule

  return (
    <Box>
      <Menu sx={style.menu} assignedCount={assignedCount} />
      <Browser data={favorite} noData={{ label: 'nothing here - events', icon: (<AddCircleOutlineIcon className="error-icon"/>) }}/>
    </Box>
  )
}

const EventsAssigned = () => {
  const schedule = useSelector((state) => state.schedule)
  const { assigned, assignedCount } = schedule

  return (
    <AuthorizationGate>
      <Box>
        <Menu sx={style.menu} assignedCount={assignedCount} />
        <Browser data={assigned} noData={{ label: 'nothing here - assigned' }} />
      </Box>
    </AuthorizationGate>
  )
}

export { EventsAssigned, EventsFavorite, EventsHome }
