/**
 * React, react redux and react localization imports
 */
import { Component } from 'react'
// import { connect } from 'react-redux';

/**
 * Material imports
 */
import ButtonBase from '@mui/material/ButtonBase'
import { Stack } from '@mui/system'

/**
 * Relative exports to root directory
 */
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField
} from '@mui/material'
import { withTranslation } from '../../../language'
import { pallete } from '../../../theme'
import { ResizableTypography, TranslatableTypography } from './Typography'

class Item extends Component {
  render () {
    const { title, description, children, sx, className } = this.props

    let titleContent = ''
    if (title !== undefined) {
      titleContent = (
        <Box className={`${className} companion-title`}>{title}</Box>
      )
    }

    let descriptionContent = ''
    if (description !== undefined) {
      descriptionContent = (
        <Box className={`${className} companion-description`}>
          {description}
        </Box>
      )
    }

    return (
      <Box className={`${className} companion-outer`} sx={sx}>
        {titleContent}
        {descriptionContent}
        {children}
      </Box>
    )
  }
}

class TimedItem extends Component {
  render () {
    const { title, description, time, children, sx, className } = this.props

    let titleContent = ''
    if (title !== undefined) {
      titleContent = (
        <Box className={`${className} companion-title`}>{title}</Box>
      )
    }

    let descriptionContent = ''
    if (description !== undefined) {
      descriptionContent = (
        <Box className={`${className} companion-description`}>
          {description}
        </Box>
      )
    }

    return (
      <Grid container className={`root ${className}`} sx={sx}>
        <Grid item className="companion-time">
          {time}
        </Grid>

        <Grid item sx={{ width: 'calc(100% - 120px)' }}>
          {titleContent}
          {descriptionContent}
          {children}
        </Grid>
      </Grid>
    )
  }
}

const renderers = {
  Item,
  TimedItem
}

const itemVariants = {
  'with-group-label': {
    '& .companion-group': {
      border: `1px solid ${pallete.headerColor}`,
      overflow: 'hidden'
    },
    '& .companion-outer:first-of-type': { borderRadius: '5px 5px 0px 0px' },
    '& .companion-outer:last-child': { borderRadius: '0px 0px 5px 5px' },
    '& .companion-group.companion-title': {
      textAlign: 'left',
      fontWeight: 600,
      background: pallete.headerColor,
      color: pallete.paperColor,
      padding: '5px 15px 5px 15px'
    },
    '& .companion-item.companion-title, & .companion-item.companion-description':
      { fontSize: '12px', padding: '5px 15px 5px 15px' },
    '& .companion-item.companion-title': { fontWeight: 600 },
    '& .companion-item .companion-time': {
      fontSize: '12px',
      color: pallete.headerColor
    },
    '& .companion-group .companion-item.root:nth-of-type(odd)': {
      background: pallete.paperColorDim
    },
    '& .companion-group .companion-item.root:nth-of-type(even)': {
      background: pallete.paperColorDim2
    },
    '& .companion-group .companion-item.root .companion-time': {
      textAlign: 'center',
      padding: '5px',
      width: '100px'
    }
  }
}

class VerticalGroupedStackComponent extends Component {
  shouldComponentUpdate (nextProps) {
    const update =
      this.props.checksum !== nextProps.checksum &&
      nextProps.checksum !== undefined &&
      nextProps.checksum !== null &&
      this.props.checksum !== undefined &&
      this.props.checksum !== null

    return update
  }

  render () {
    const { data, renderer, checksum, sx, className } = this.props

    const { group, item } = renderer

    let GroupRenderer = Item

    if (group !== undefined) {
      GroupRenderer = renderers[group]
    }

    let ItemRenderer = Item

    if (item !== undefined) {
      ItemRenderer = renderers[item]
    }

    const content = data.map((group) => {
      const items = group.items.map((item) => {
        return <ItemRenderer
        className="companion-item"
        key={item.id}
         {...item} />
      })

      return (
        <GroupRenderer
          className="companion-group"
          key={group.id}
          title={group.title}
          description={group.description}
        >
          {items}
        </GroupRenderer>
      )
    })

    return (
      <Stack
        className={`companion-root ${className}`}
        checksum={checksum}
        sx={{ ...itemVariants['with-group-label'], ...sx }}
      >
        {content}
      </Stack>
    )
  }
}

export const VerticalGroupedStack = withTranslation(
  VerticalGroupedStackComponent
)

class SearchableGridItemWithAvatar extends Component {
  render () {
    const { id, title, avatar, handler } = this.props

    return (
      <Grid item key={id} className="companion-searchable-grid-item">
        <ButtonBase onClick={() => handler(id)}>
          <Card className="companion-card">
            <CardMedia
              className="companion-card-avatar"
              component="img"
              image={avatar}
              alt={title}
              height="125px"
            />
            <CardContent className="companion-card-content">
              <ResizableTypography
                className="companion-card-title"
                fontWeight={600}
                translate="false"
              >
                {title}
              </ResizableTypography>
            </CardContent>
          </Card>
        </ButtonBase>
      </Grid>
    )
  }
}

const searchableGridComponentVariants = {
  standard: {
    '&.companion-searchable-root': {
      border: `1px solid ${pallete.headerColor}`,
      overflow: 'hidden'
    },
    '&.companion-searchable-root .companion-title': {
      background: pallete.headerColor,
      height: '40%',
      width: '100%',
      padding: '5px 15px 0px 15px',
      color: pallete.paperColor
    },
    '&.companion-searchable-root .companion-title label': {
      color: pallete.paperColor,
      fontSize: '12px'
    },
    '&.companion-searchable-root .companion-title input': {
      color: pallete.paperColor,
      fontSize: '12px'
    },
    '&.companion-searchable-root .companion-title .companion-filter-outer': {
      textAlign: 'right'
    },
    '&.companion-searchable-root .companion-title p': {
      lineHeight: '50px'
    },
    '&.companion-searchable-root .companion-grid-outer': {
      padding: '0px 15px 15px 0px'
    },
    '&.companion-searchable-root .companion-grid-inner': {
      width: '100%',
      margin: 'auto'
    },
    '&.companion-searchable-root .companion-searchable-grid-item .companion-card':
      {
        width: '155px',
        height: '155px',
        cursor: 'pointer',
        border: `1px solid ${pallete.headerColor}`
      },
    '&.companion-searchable-root .companion-searchable-grid-item .companion-card .companion-card-content':
      {
        height: '30px',
        textAlign: 'center',
        padding: '5px 0px 0px 5px',
        background: pallete.headerColor
      },
    '&.companion-searchable-root .companion-searchable-grid-item .companion-card .companion-card-content .companion-card-title':
      { color: pallete.paperColor }
  }
}

class SearchableGridComponent extends Component {
  constructor (props) {
    super(props)
    this.state = { filter: undefined }
  }

  shouldComponentUpdate (nextProps, nextState) {
    const update =
      (this.props.checksum !== nextProps.checksum &&
        nextProps.checksum !== undefined &&
        nextProps.checksum !== null &&
        this.props.checksum !== undefined &&
        this.props.checksum !== null) ||
      this.state.filter !== nextState.filter

    return update
  }

  select (key) {}

  render () {
    const { data, sx } = this.props

    const items = data.flatMap((item) => {
      const itemProps = {
        ...item,
        handler: this.select
      }

      if (this.state.filter === undefined || this.state.filter === '') {
        return [
          <SearchableGridItemWithAvatar
            className="companion-item"
            key={item.id}
            {...itemProps}
          />
        ]
      }

      const regex = new RegExp(`.*${this.state.filter}.*`)
      const matches = item.id.match(regex) || item.title.match(regex)

      if (matches) {
        return [
          <SearchableGridItemWithAvatar
            className="companion-item"
            key={item.id}
            {...itemProps}
          />
        ]
      } else return []
    })

    return (
      <Box
        className="companion-searchable-root"
        sx={{ ...searchableGridComponentVariants.standard, ...sx }}
      >
        <Grid container className="companion-title">
          <Grid item xs={8}>
            <TranslatableTypography fontWeight={600}>
              selectFursuiter
            </TranslatableTypography>
          </Grid>

          <Grid item xs={4} className="companion-filter-outer">
            <TextField
              label="Filter"
              variant="filled"
              size="small"
              onChange={(e) => {
                this.setState({ filter: e.target.value })
              }}
            ></TextField>
          </Grid>
        </Grid>

        <Box className="companion-grid-outer">
          <Grid
            className="companion-grid-inner"
            container
            spacing={2}
            justifyContent="space-evenly"
            alignItems="center"
          >
            {items}
          </Grid>
        </Box>
      </Box>
    )
  }
}

export const SearchableGrid = withTranslation(SearchableGridComponent)
