import { Api, AuthorizedApi } from '.'
import { fetchAchievementsError, fetchAchievementsSuccess, fetchUserAchievementsError, fetchUserAchievementsSuccess } from '../state/achievements'
import store from '../state/store.js'

export function fetchAchievements (config) {
  const api = new Api(
    config,
    (response) => {
      const { data } = response
      store.dispatch(fetchAchievementsSuccess(data))
    },
    (error) => {
      const { message, code } = error
      const detail = error.detail ?? message
      store.dispatch(fetchAchievementsError({ detail, code }))
    }
  )

  api.fetch('achievements')
}

export function fetchUserAchievements (config, auth) {
  const api = new AuthorizedApi(
    config,
    (response) => {
      const { data } = response
      store.dispatch(fetchUserAchievementsSuccess(data))
    },
    (error) => {
      const { detail } = error
      store.dispatch(fetchUserAchievementsError({ detail }))
    }
  )

  api.fetchWithToken('achievements/user', auth?.accessToken)
}
