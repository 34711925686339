import { createSlice } from '@reduxjs/toolkit'

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const initialState = {
  data: [],
  error: '',
  checksum: '',
  updated: ''
}

const messages = createSlice({
  name: 'consite_messages',
  initialState,
  reducers: {
    fetchConsiteMessagesSuccess (state, action) {
      const { data, checksum, generated } = action.payload
      return {
        ...state,
        data,
        checksum,
        updated: generated,
        error: ''
      }
    },
    fetchConsiteMessagesError (state, action) {
      const { detail } = action.payload

      return {
        ...state,
        error: detail
      }
    }
  }
})

const persistConfig = {
  key: 'consite_messages',
  storage
}

export const { fetchConsiteMessagesSuccess, fetchConsiteMessagesError } =
  messages.actions
export const ConinfoMessagesReducer = persistReducer(
  persistConfig,
  messages.reducer
)
