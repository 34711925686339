import LocationOnIcon from '@mui/icons-material/LocationOn'
import PetsIcon from '@mui/icons-material/Pets'
import { Box, Grid, Modal, Paper, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { translateText } from '../../../language'
import { setFavoriteForEvent } from '../../../state/events'
import { pallete, sizing } from '../../../theme'
import { isDefined, notDefined } from '../../../utils'
import { Loading, NoData, TranslatableButton, TranslatableTypography } from '../widgets'

const style = {
  '&.companion-event-browser-wrapper': {
    padding: '5px'
  },
  '& .companion-event-browser-content': {
    borderRadius: '5px',
    background: pallete.paperColor
  },
  '& .companion-event-browser-day': {
    position: 'relative'
  },
  '& .companion-event-browser-day-scroll-pivot': {
    position: 'absolute',
    top: `-${sizing.header.height}px`
  },
  '& .companion-event-browser-day-header': {
    background: pallete.headerColor,
    borderRadius: '5px 5px 0px 0px',
    padding: '10px',
    fontWeight: 600,
    width: '100%'
  },
  '& .companion-event-browser-event-time': {
    '& .MuiBox-root': {
      textAlign: 'center',
      '& .MuiTypography-root': {
        display: 'inline',
        margin: '5px',
        fontWeight: '600',
        color: pallete.header
      }
    }
  },
  '& .companion-event-browser-event': {
    padding: '5px',
    position: 'relative',
    '& .companion-event-browser-modal-opener': {
      display: 'block',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%'
    }
  },
  '& .companion-event-browser-event:nth-of-type(even)': {
    background: pallete.paperColorDim
  },
  '& .companion-event-browser-event:nth-of-type(odd)': {
    background: pallete.paperColorDim2
  },
  '& .companion-event-browser-event-header': {
    '& .MuiTypography-root': {
      display: 'inline',
      margin: '5px',
      fontWeight: '600',
      color: pallete.header
    }
  },
  eventModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .companion-event-browser-modal-content': {
      minWidth: '60%',
      maxWidth: '80%',
      '& .modal-content-title': {
        background: pallete.headerColor,
        padding: '10px',
        fontWeight: 600,
        color: 'white'
      },
      '& .modal-content-sub-title': {
        paddingLeft: '10px',
        paddingRight: '10px',
        background: pallete.paperColorDim2,
        fontWeight: 600,
        color: pallete.headerColor,
        '& .MuiTypography-root': {
          display: 'inline',
          margin: '5px',
          fontWeight: '600',
          color: pallete.header
        }
      },
      '& .modal-content-content': {
        padding: '10px',
        textAlign: 'justify',
        maxHeight: '300px',
        overflowY: 'scroll',
        '& .modal-content-label': {
          marginTop: '10px',
          color: pallete.headerColor
        }
      },
      '& .modal-content-controls': {
        marginTop: '10px',
        padding: '10px',
        textAlign: 'right'
      }
    }
  }
}

const ConopsDetail = (props) => {
  const { note, equipment } = props

  const noteLabelElement = isDefined(note) ? <TranslatableTypography className='modal-content-label'>conops-note</TranslatableTypography> : undefined
  const noteElement = isDefined(note) ? <Typography>{note}</Typography> : undefined
  const equipmentLabelElement = isDefined(equipment) ? <TranslatableTypography className='modal-content-label'>equipment</TranslatableTypography> : undefined
  const equipmentElement = isDefined(equipment) ? <Typography>{equipment}</Typography> : undefined

  return (
    <Grid item xs={12} className='modal-content-conops'>
      {noteLabelElement}
      {noteElement}
      {equipmentLabelElement}
      {equipmentElement}
    </Grid>
  )
}

const SecurityDetail = (props) => {
  const { note } = props
  const noteLabelElement = isDefined(note) ? <TranslatableTypography className='modal-content-label'>security-note</TranslatableTypography> : undefined
  const noteElement = isDefined(note) ? <Typography>{note}</Typography> : undefined
  return (
    <Grid item xs={12} className='modal-content-security'>
      {noteLabelElement}
      {noteElement}
    </Grid>
  )
}

const EventDetail = (props) => {
  const { con_id, day, start, end, name, description, favorite, conops, security, location } = props
  const dispatch = useDispatch()
  const setFavoriteDispatcher = (favorite) => dispatch(setFavoriteForEvent({ con_id, favorite }))

  const descriptionGrid = isDefined(description) ? (<Grid item xs={12} className='modal-content-describtion'>{description}</Grid>) : undefined
  const conopsGrid = isDefined(conops) ? (<ConopsDetail {...conops} />) : undefined
  const securityGrid = isDefined(security) ? (<SecurityDetail {...security} />) : undefined

  let favoriteButton
  if (favorite) {
    favoriteButton = (
      <TranslatableButton variant="contained" startIcon={<PetsIcon />} onClick={() => setFavoriteDispatcher(false)} >
        remove favorite
      </TranslatableButton>
    )
  } else {
    favoriteButton = (
      <TranslatableButton variant="contained" startIcon={<PetsIcon />} onClick={() => setFavoriteDispatcher(true)} >
        add favorite
      </TranslatableButton>
    )
  }

  return (
    <Paper>
      <Grid container>
        <Grid item xs={12} className='modal-content-title'>{name}</Grid>
        <Grid item xs={12} className='modal-content-sub-title' style={{ paddingTop: '10px' }}>
          <Typography>{day}</Typography>
        </Grid>
        <Grid item xs={12} className='modal-content-sub-title'>
          <Typography fontSize='small'>{start}</Typography>
          <Typography fontSize='small'>-</Typography>
          <Typography fontSize='small'>{end}</Typography>
        </Grid>
        <Grid item xs={12} className='modal-content-sub-title' style={{ paddingBottom: '10px' }}>
          <LocationOnIcon /><Typography>{location}</Typography>
        </Grid>
        <Grid item xs={12} className='modal-content-content'>
          <Grid container >
            {descriptionGrid}
            {conopsGrid}
            {securityGrid}
          </Grid>
        </Grid>
        <Grid item xs={12} className='modal-content-controls'>
          {favoriteButton}
        </Grid>
      </Grid>
    </Paper>
  )
}

const Event = (props) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const { event, locale, day } = props
  const { con_id, start, end, name, descriptions, favorite, conops, security, location } = event
  const timeFormatOptions = { hour: '2-digit', minute: '2-digit' }
  const translation = useTranslation()

  const separator = <Typography fontSize='small'>-</Typography>

  const startDate = new Date(start)
  const startTime = startDate.toLocaleTimeString(locale, timeFormatOptions)
  const startTimeTypography = (
    <Typography fontSize='small'>
      {startTime}
    </Typography>
  )

  const endDate = new Date(end)
  const endTime = endDate.toLocaleTimeString(locale, timeFormatOptions)
  const endTimeTypography = (
    <Typography fontSize='small'>
      {endTime}
    </Typography>
  )
  const tName = translateText(name, translation.i18n)
  const tDescription = translateText(descriptions, translation.i18n)
  const tLocation = translateText(location?.name, translation.i18n)
  return (
    <Grid container className='companion-event-browser-event'>
      <Modal open={open} onClose={handleClose} sx={style.eventModal}>
        <Box className='companion-event-browser-modal-content'>
          <EventDetail
            con_id={con_id}
            start={startTime}
            end={endTime}
            day={day}
            name={tName}
            description={tDescription}
            favorite={favorite}
            conops={conops}
            security={security}
            location={tLocation} />
        </Box>
      </Modal>
      <Box className='companion-event-browser-modal-opener' onClick={handleOpen}/>
      <Grid item xs={4} key='event-time' className='companion-event-browser-event-time'>
        <Box>
          {startTimeTypography}
          {separator}
          {endTimeTypography}
        </Box>
        </Grid>
        <Grid item xs={8} key='event-label' className='companion-event-browser-event-header'>
          {tName}
        </Grid>
    </Grid>
  )
}

const EventsDay = (props) => {
  const { day, events } = props

  const translation = useTranslation()
  const locale = translation.t('date_locale')
  const convertedDate = new Date(day)
  const localizedDate = convertedDate.toLocaleDateString(locale)
  const dayLabel = translation.t('day_' + convertedDate.getDay()) + ' - ' + localizedDate

  const header = (
    <Typography color={pallete.paperColor}>
      {dayLabel}
    </Typography>
  )

  const rows = events.map((event) => (<Event key={event.con_id} event={event} locale={locale} day={dayLabel} />))

  return (
    <Grid item xs={12} className='companion-event-browser-day'>
      <Box className={'companion-event-browser-day-scroll-pivot ' + day} />
      <Box className='companion-event-browser-day-header'>{header}</Box>
      <Grid container className='companion-event-browser-day-events'>{rows}</Grid>
    </Grid>
  )
}

const Browser = (props) => {
  const navigation = useSelector((state) => state.navigation)
  const { loading } = navigation
  const { data, noData } = props

  if (loading && notDefined(data)) {
    return <Loading />
  }

  const days = []
  data.forEach(entry => {
    const day = entry[0]
    const events = entry[1]
    const eventProps = {
      key: day,
      day,
      events
    }
    if (events?.length > 0) {
      days.push(<EventsDay {...eventProps} />)
    }
  })

  if (notDefined(data) || days.length === 0) {
    return <NoData {...noData}/>
  }

  return (
    <Box className='companion-event-browser-wrapper' sx={style}>
      <Grid container className='companion-event-browser-content' >
        {days}
      </Grid>
    </Box>
  )
}

export { Browser, EventDetail }
