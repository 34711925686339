import { Api, AuthorizedApi } from '.'
import { fetchEventsError, fetchEventsSuccess } from '../state/events'
import store from '../state/store.js'

export function fetchEvents (config) {
  const api = new Api(
    config,
    (response) => {
      const { data } = response
      store.dispatch(fetchEventsSuccess(data))
    },
    (error) => {
      const { message, code } = error
      const detail = error.detail ?? message
      store.dispatch(fetchEventsError({ detail, code }))
    }
  )

  api.fetch('events')
}

export function fetchUserEvents (config, auth, language) {
  const api = new AuthorizedApi(
    config,
    (response) => {
      const { data } = response
      store.dispatch(fetchEventsSuccess(data))
    },
    (error) => {
      const { message, code } = error
      const detail = error.detail ?? message
      store.dispatch(fetchEventsError({ detail, code }))
    }
  )

  api.fetchWithToken('events/user', auth?.accessToken, { language })
}
