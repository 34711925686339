import { Box, Tab, Tabs } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { changeFooterLink } from '../../../state/navigation'
import { useCategory } from '../../../state/notifications'
import { pallete } from '../../../theme'
import { notDefined } from '../../../utils'

const sx = {
  tabs: {
    height: '50px',
    background: pallete.headerTransparentColor,
    '& .Mui-selected': {
      color: `${pallete.paperColor} !important`
    },
    '@media (max-width: 350px)': {
      width: '100% !important',
      minWidth: '100% !important',
      maxWidth: '100% !important'
    }
  }
}

export const Menu = (props) => {
  const { categories, category } = props
  const dispatch = useDispatch()
  const selectMenuItemDispatcher = (e, value) => {
    dispatch(useCategory({ category: categories[value] }))
    const link = '/notifications/' + categories[value]
    dispatch(changeFooterLink({ notifications: link }))
  }
  const { t } = useTranslation()

  if (notDefined(categories)) {
    return <Box />
  }

  const index = categories.indexOf(category)

  const tabs = categories.map((item, key) => {
    return (
      <Tab component={Link} to={'/notifications/' + item} key={key} label={t(item + '-notifications')} />
    )
  })

  return (
    <Box sx={sx.tabs}>
      <Tabs value={index} onChange={selectMenuItemDispatcher}>
        {tabs}
      </Tabs>
    </Box>
  )
}
