import { Grid, Modal, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { pallete } from '../../../theme'
import { Button } from './Button'

const modalStyle = {
  '& .companion-error-modal-wrapper': {
    width: '80%',
    textAlign: 'center',
    padding: '10px',
    position: 'relative',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    left: '50%',
    color: pallete.errorColorRGB
  },
  '& .companion-error-modal-icon-wrapper': {
    color: pallete.errorColorRGB,
    '& .MuiSvgIcon-root': {
      width: '64px',
      height: '64px'
    }
  },
  '& .companion-error-modal-label-wrapper': {
  },
  '& .companion-error-modal-button-wrapper': {
    textAlign: 'right',
    padding: '10px'
  }
}

export const ErrorModal = (props) => {
  const { label, icon, onClose } = props
  const translation = useTranslation()
  const { t } = translation.i18n
  const translatedLabel = t(label)
  const open = props.open ?? false

  return (
    <Modal open={open} onClose={ () => onClose() } sx={modalStyle} >
      <Paper className='companion-error-modal-wrapper'>
        <Grid container>
          <Grid item xs={12} className='companion-error-modal-icon-wrapper'>
              {icon}
          </Grid>
          <Grid item xs={12} className='companion-error-modal-label-wrapper'>
              <Typography>{translatedLabel}</Typography>
          </Grid>
          <Grid item xs={12} className='companion-error-modal-button-wrapper'>
            <Button label="ok" onClick={ () => onClose() }/>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  )
}
