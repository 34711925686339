import { createSlice } from '@reduxjs/toolkit'

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const initialState = {
  data: [],
  error: '',
  checksum: '',
  updated: '',
  draftedMessage: '',
  creating: false,
  created: 0,
  createDisabled: false
}

const yaps = createSlice({
  name: 'yaps',
  initialState,
  reducers: {
    fetchYapsSuccess (state, action) {
      const { data, checksum, generated } = action.payload

      return {
        ...state,
        data,
        checksum,
        updated: generated,
        error: '',
        creating: false
      }
    },
    fetchYapsError (state, action) {
      const { detail } = action.payload

      return {
        ...state,
        creating: false,
        error: detail
      }
    },
    createYapSuccess (state, action) {
      const { data, checksum, generated } = action.payload
      return {
        ...state,
        data,
        checksum,
        updated: generated,
        error: '',
        draftedMessage: '',
        creating: false,
        created: new Date().getTime(),
        createDisabled: true
      }
    },
    createYapError (state, action) {
      const { detail } = action.payload

      return {
        ...state,
        error: detail,
        creating: false
      }
    },
    setCreatingYap (state, action) {
      const { creating } = action.payload
      return {
        ...state,
        creating
      }
    },
    setCreateDisabled (state, action) {
      const { createDisabled } = action.payload
      return {
        ...state,
        createDisabled
      }
    },
    setValidationError (state, action) {
      const { validationError } = action.payload
      return {
        ...state,
        validationError
      }
    },
    setDraftedMessage (state, action) {
      const { draftedMessage } = action.payload
      return {
        ...state,
        draftedMessage
      }
    }
  }
})

const persistConfig = {
  key: 'yaps',
  storage
}

export const { fetchYapsSuccess, fetchYapsError, createYapSuccess, createYapError, setCreatingYap, setCreateDisabled, setValidationError, setDraftedMessage } = yaps.actions
export const YapsReducer = persistReducer(
  persistConfig,
  yaps.reducer
)
