import { Box } from '@mui/material'
import Radium from 'radium'
import { Component } from 'react'
import { connect } from 'react-redux'
import { sizing } from '../../theme.js'

const bodyStyle = {
  width: '100vw',
  maxWidth: '600px',
  height: '100vh',
  margin: '0 auto',
  overflowX: 'hidden',
  overflowY: 'auto',
  '@media (min-width: 350px)': {
    paddingTop: `${sizing.header.height}px`
  },
  '@media (max-width: 349px)': {
    paddingTop: `${sizing.header.height * 0.8}px`
  },
  paddingTop: `${sizing.header.height}px`
}

class Body extends Component {
  render () {
    const { children, error } = this.props
    const space = error ? sizing.footer.height + 32 : sizing.footer.height
    /*
    There was issue with mobile phones, this forces artificial space between content and bottom of screen
    */
    const line = {
      display: 'block',
      height: `${space + 10}px`,
      marginBottom: `${space + 10}px`,
      webkitUserSelect: 'none',
      msUserSelect: 'none',
      userSelect: 'none'
    }
    return (
      <Box
        className="companion-content"
        data-component="companion-content"
        sx={bodyStyle}
      >
        <Box>{children}</Box>
        <Box sx={line}></Box>
      </Box>
    )
  }
}
const mapStateToProps = function (state) {
  const { connectionError } = state.error
  return {
    error: connectionError
  }
}

export default connect(mapStateToProps)(Radium(Body))
