import { Button as MUIButton } from '@mui/material'
import { Component } from 'react'
import { useTranslation } from 'react-i18next'
import { withTranslation } from '../../../language'
import { pallete } from '../../../theme'

const buttonVariants = {
  filled: {
    borderRadius: '25px',
    background: pallete.headerColor,
    color: pallete.paperColor,
    '&:hover': { background: pallete.headerColor }
  },
  'invert-contained': {
    borderRadius: '25px',
    background: pallete.paperColor,
    color: pallete.headerColor,
    fontWeight: 600,
    boxShadow: 'none',
    '&:hover': { background: pallete.paperColor }
  }
}

class ButtonTypographyComponent extends Component {
  render () {
    const { t } = this.props.i18nEngine

    let sx = this.props.sx

    try {
      const { variant } = this.props
      const variantStyle = buttonVariants[variant]
      sx = { ...this.props.sx, ...variantStyle }
    } catch (error) {}

    const customProps = {
      ...this.props,
      children: t(this.props.children),
      sx
    }

    delete customProps.i18nEngine
    delete customProps.language

    return <MUIButton {...customProps}></MUIButton>
  }
}

export const TranslatableButton = withTranslation(ButtonTypographyComponent)

class ButtonMoreComponent extends Component {
  render () {
    return <TranslatableButton>more</TranslatableButton>
  }
}

export const MoreButton = withTranslation(ButtonMoreComponent)

export const Button = (props) => {
  const { icon, label } = props
  const translation = useTranslation()
  const { t } = translation.i18n
  const translatedLabel = t(label)
  const variant = props.variant ?? 'filled'
  const variantStyle = buttonVariants[variant]

  const sx = { ...props.sx, ...variantStyle }

  const customProps = {
    ...props,
    sx
  }

  return (
    <MUIButton {...customProps}>
      {icon}
      {translatedLabel}
    </MUIButton>
  )
}
