import { createSlice } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const initialState = {
  active: undefined
}

const coninfoMenu = createSlice({
  name: 'coninfo_menu',
  initialState,
  reducers: {
    selectConinfoItem (state, action) {
      return {
        ...state,
        active: action.payload
      }
    }
  }
})

const persistConfig = {
  key: 'coninfo_menu',
  storage
}

export const { selectConinfoItem } = coninfoMenu.actions
export const ConinfoMenuReducer = persistReducer(
  persistConfig,
  coninfoMenu.reducer
)
