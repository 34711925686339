const DefaultAchievementIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      version="1.1"
      viewBox="0 0 39.687 39.688"
    >
      <path
        fill="#fff"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="#d96343"
        strokeDasharray="none"
        strokeLinecap="butt"
        strokeLinejoin="round"
        strokeMiterlimit="4"
        strokeOpacity="1"
        strokeWidth="0.529"
        d="M20.298 1.531c-6.456 0-11.684 5.228-11.684 11.684.021 1.969.508 3.916 1.482 5.652L5.926 30.55l7.577-1.566 4.593 5.906 2.435-6.816 3.09 10.139 3.958-5.419 6.18 2.604-4.465-14.732a11.662 11.662 0 002.688-7.45c0-6.457-5.228-11.685-11.684-11.685z"
      ></path>
      <path
        fill="#d96343"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="#d96343"
        strokeDasharray="none"
        strokeLinecap="butt"
        strokeLinejoin="bevel"
        strokeMiterlimit="4"
        strokeOpacity="1"
        strokeWidth="0.254"
        d="M30.077 13.215c0 5.398-4.382 9.779-9.78 9.779-5.397 0-9.757-4.381-9.757-9.779 0-5.397 4.36-9.779 9.758-9.779 5.397 0 9.779 4.382 9.779 9.78z"
      ></path>
      <path
        fill="#fff"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="#d96343"
        strokeOpacity="1"
        strokeWidth="0.265"
        d="M20.622 8.518c.106 1.545 1.016 2.794 2.032 2.73 1.016-.084 1.334-.994 1.228-2.54C23.776 7.184 22.866 6 21.85 6.063c-.995.063-1.334.91-1.228 2.455z"
      ></path>
      <path
        fill="#fff"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="#d96343"
        strokeOpacity="1"
        strokeWidth="0.265"
        d="M20.008 8.052c.106 1.545-.613 2.921-1.63 2.985-.994.084-1.439-.783-1.566-2.329-.106-1.524.635-2.836 1.63-2.9 1.016-.084 1.46.72 1.566 2.244z"
      ></path>
      <path
        fill="#fff"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="#d96343"
        strokeOpacity="1"
        strokeWidth="0.265"
        d="M23.564 12.645c-.19 1.546.445 2.964 1.46 3.09.996.128 1.504-.698 1.694-2.243.212-1.524-.444-2.857-1.46-3.006-.995-.127-1.482.635-1.694 2.16z"
      ></path>
      <path
        fill="#fff"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="#d96343"
        strokeOpacity="1"
        strokeWidth="0.265"
        d="M17.087 11.545c.424 1.481-.02 2.984-.994 3.26-.974.253-1.588-.487-1.99-1.97-.423-1.502.021-2.92.995-3.195.973-.254 1.587.423 1.99 1.905z"
      ></path>
      <path
        fill="#fff"
        fillOpacity="1"
        fillRule="nonzero"
        stroke="#d96343"
        strokeOpacity="1"
        strokeWidth="0.265"
        d="M20.05 12.328c1.906.17 2.752 1.65 2.837 3.069.974.106 1.46 1.058 1.524 2.032.085 1.1-.804 2.053-1.968 2.159-1.207.085-1.27-.762-1.397-1.207-.55.487-1.228.381-1.673 0-.02.805-.444 1.165-1.545 1.25-1.185.084-2.116-.678-2.265-1.863-.19-1.567.741-2.308 1.99-2.371.17-1.841 1.228-2.921 2.498-3.07z"
      ></path>
    </svg>
  )
}

export default DefaultAchievementIcon
