/**
 * React, react redux and react localization imports
 */
import TelegramIcon from '@mui/icons-material/Telegram'
import { Button, Grid } from '@mui/material'
import { Component } from 'react'
import { withTranslation } from '../../../language'
import { pallete } from '../../../theme'
import { ResetApplication } from './ResetApplication'
import { TranslatableTypography } from './Typography'

const contactsStyle = {
  wrapper: {
    width: '100%',
    position: 'absolute',
    bottom: 0
  },
  item: {
    padding: '5px',
    textAlign: 'center'
  },
  button: {
    background: pallete.paperColor,
    color: pallete.headerColor,
    fontWeight: 600,
    boxShadow: 'none',
    '&:hover': { background: pallete.paperColor },
    borderRadius: '25px'
  },
  signature: {
    fontSize: '0.7rem',
    color: 'white',
    fontStyle: 'italic'
  }
}

class ContactsComponent extends Component {
  render () {
    const reportBug = this.props?.i18nEngine?.i18n?.t('report-bug')
    return (
      <Grid container sx={contactsStyle.wrapper}>
        <Grid item xs={6} sx={contactsStyle.item}>
          <Button
            href="https://t.me/CesfurConops"
            sx={contactsStyle.button}
            target="_blank"
          >
            <TelegramIcon /> Conops
          </Button>
        </Grid>
        <Grid item xs={6} sx={contactsStyle.item}>
          <Button href="https://t.me/Cesfur" sx={contactsStyle.button} target="_blank" >
            <TelegramIcon /> Česfur
          </Button>
        </Grid>
        <Grid item xs={12} sx={contactsStyle.item}>
          <Button href="https://t.me/+e_wbJGHYn_pkMmE8" sx={contactsStyle.button} target="_blank" >
            <TelegramIcon /> {reportBug}
          </Button>
        </Grid>
        <Grid item xs={12} sx={contactsStyle.item}>
          <ResetApplication />
        </Grid>
        <Grid item xs={12} sx={contactsStyle.item}>
          <TranslatableTypography sx={contactsStyle.signature}>
            created-by
          </TranslatableTypography>
        </Grid>
      </Grid>
    )
  }
}

export const Contacts = withTranslation(ContactsComponent)
