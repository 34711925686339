import { Api } from '.'
import store from '../state/store.js'

import {
  fetchNotificationsError,
  fetchNotificationsSuccess
} from '../state/notifications'

export function fetchNotifications (config) {
  const api = new Api(
    config,
    (response) => {
      const { data } = response
      store.dispatch(fetchNotificationsSuccess(data))
    },
    (error) => {
      const { detail } = error
      store.dispatch(fetchNotificationsError({ detail }))
    }
  )

  api.fetch('notifications')
}
