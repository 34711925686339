import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  toggled: []
}

const menu = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    toggleMenu (state, action) {
      const { reference, value } = action.payload
      const newState = { ...state }
      newState[reference] = value
      return newState
    }
  }
})

export const { toggleMenu } = menu.actions
export const MenuReducer = menu.reducer
