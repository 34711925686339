/**
 * React, react redux and react localization imports
 */
import { Box } from '@mui/material'
import { Component } from 'react'
import { withTranslation } from '../../../language'
import { pallete } from '../../../theme'
import { TranslatableTypography } from './Typography'

const qrStyle = {
  wrapper: { padding: '10px', textAlign: 'center' },
  label: { color: pallete.paperColor },
  img: { width: '120px', height: '120px' }
}

class QRCodeComponent extends Component {
  render () {
    return (
      <Box sx={qrStyle.wrapper}>
        <TranslatableTypography sx={qrStyle.label}>share-me</TranslatableTypography>
        <img src="/qr.png" alt="QR code for companion" style={qrStyle.img}/>
      </Box>
    )
  }
}

export const QRCode = withTranslation(QRCodeComponent)
