import { Box, CircularProgress, Paper } from '@mui/material'
import { pallete } from '../../../theme'
import { TranslatableTypography } from './Typography'

const loadingStyle = {
  width: '100%',
  padding: '5px',
  '& .companion-loading-container': {
    width: '100%',
    height: '100%',
    position: 'relative',
    border: `1px dashed ${pallete.headerColor}`,
    filter: `drop-shadow(0px 0px 3px ${pallete.headerColor})`
  },
  '& .companion-loading-wrapper': {
    padding: '35px',
    textAlign: 'center'
  },
  '& .companion-loading-title': {
    fontWeight: 600,
    color: pallete.headerColor
  }
}
const Loading = (props) => {
  return (
    <Box sx={loadingStyle}>
      <Paper className="companion-loading-container">
        <Box className="companion-loading-wrapper">
            <CircularProgress className="companion-loading-loader"/>
            <TranslatableTypography className="companion-loading-title">
                loading
            </TranslatableTypography>
        </Box>
      </Paper>
    </Box>
  )
}

export { Loading }
