import { Api } from '.'
import store from '../state/store.js'

import {
  fetchConsiteMessagesError,
  fetchConsiteMessagesSuccess
} from '../state/coninfo/messages'

import {
  fetchConsitePhotosError,
  fetchConsitePhotosSuccess
} from '../state/coninfo/photos'

import {
  fetchConsiteScreensError,
  fetchConsiteScreensSuccess
} from '../state/coninfo/screens'

import {
  fetchConsiteBarError,
  fetchConsiteBarSuccess
} from '../state/coninfo/bar'

export function fetchConinfoBar (config, language) {
  const api = new Api(
    config,
    (response) => {
      const { data } = response
      store.dispatch(fetchConsiteBarSuccess(data))
    },
    (error) => {
      const { detail } = error
      store.dispatch(fetchConsiteBarError({ detail }))
    }
  )

  api.fetch('consite/bar', language)
}

export function fetchConinfoMessages (config, language) {
  const api = new Api(
    config,
    (response) => {
      const { data } = response
      store.dispatch(fetchConsiteMessagesSuccess(data))
    },
    (error) => {
      const { detail } = error
      store.dispatch(fetchConsiteMessagesError({ detail }))
    }
  )

  api.fetch('consite/messages', language)
}

export function fetchConinfoPhotos (config) {
  const api = new Api(
    config,
    (response) => {
      const { data } = response
      store.dispatch(fetchConsitePhotosSuccess(data))
    },
    (error) => {
      const { detail } = error
      store.dispatch(fetchConsitePhotosError({ detail }))
    }
  )

  api.fetch('consite/photos')
}

export function fetchConinfoScreens (config) {
  const api = new Api(
    config,
    (response) => {
      const { data } = response
      store.dispatch(fetchConsiteScreensSuccess(data))
    },
    (error) => {
      const { detail } = error
      store.dispatch(fetchConsiteScreensError({ detail }))
    }
  )

  api.fetch('consite/screens')
}
