import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Grid } from '@mui/material'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Paper from '@mui/material/Paper'
import { Box } from '@mui/system'

import { Component } from 'react'
import { pallete, sizing } from '../../theme.js'

const sxOverride = {
  subHeader: {
    height: '0px',
    visibility: 'hidden',
    marginBottom: '0px',
    padding: '0px',
    zIndex: 1101,
    width: '100%',
    '& .MuiPaper-root': {
      borderRadius: '0px',
      height: '100%',
      width: '100%',
      background: pallete.headerTransparentColor
    },

    '&.visible': {
      height: `${sizing.subHeader.height}px`,
      visibility: 'visible',
      marginBottom: '10px'
    }
  },
  breadCrumbs: {
    paddingTop: '5px',
    paddingLeft: '20px'
  },
  languageContainer: {
    paddingRight: '20px',
    textAlign: 'right',
    border: '0px',
    '& .MuiButtonBase-root': {
      borderRadius: '0px',
      border: '0px'
    }
  }
}

export default class SubHeadeWithBreadcrumb extends Component {
  render () {
    const className =
      this.props.children !== undefined && this.props.children.length > 0
        ? 'visible'
        : ''
    return (
      <Box className={className} sx={{ ...sxOverride.subHeader }}>
        <Paper>
          <Grid container>
            <Grid item xs={12}>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                sx={sxOverride.breadCrumbs}
              >
                {this.props.children}
              </Breadcrumbs>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    )
  }
}
