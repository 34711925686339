import { AuthorizedApi } from '.'
import store from '../state/store.js'
import { createYapError, createYapSuccess, fetchYapsError, fetchYapsSuccess } from '../state/yaps'

export function fetchYaps (config, auth, skip, limit) {
  const api = new AuthorizedApi(
    config,
    (response) => {
      const { data } = response
      store.dispatch(fetchYapsSuccess(data))
    },
    (error) => {
      const { detail } = error
      store.dispatch(fetchYapsError({ detail }))
    }
  )

  api.fetchWithToken('shouts', auth?.accessToken, { skip, limit })
}

export function createYap (config, auth, yap, language) {
  const api = new AuthorizedApi(
    config,
    (response) => {
      const { data } = response
      store.dispatch(createYapSuccess(data))
    },
    (error) => {
      const { message, detail } = error
      store.dispatch(createYapError({ detail: message ?? detail }))
    }
  )

  api.postWithToken('shouts/create', auth?.accessToken, { shout: yap, lang: language }, { skip: 0, limit: 30 })
}
