import { createSlice } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const initialState = {
  refresh: true,
  active: 'en'
}

const language = createSlice({
  name: 'language',
  initialState,
  reducers: {
    selectLanguage (state, action) {
      return {
        ...state,
        active: action.payload,
        refresh: true
      }
    },
    setLanguageDataRefresh (state, action) {
      const { refresh } = action.payload
      return {
        ...state,
        refresh
      }
    }
  },
  extraReducers: {
    'persist/REHYDRATE': (state, action) => {}
  }
})

const persistConfig = {
  key: 'language',
  storage
}

export const { selectLanguage, setLanguageDataRefresh } = language.actions
export const LanguageReducer = persistReducer(persistConfig, language.reducer)
