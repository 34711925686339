import { AppBar, Grid, Toolbar } from '@mui/material'
import { Component } from 'react'
import { connect } from 'react-redux'

import {
  Contacts,
  HamburgerDrawerButton,
  HamburgerDrawerMenu,
  LanguageSelectText,
  LoginMenuItem,
  QRCode,
  UnauthorizedButton,
  UserButton,
  UserDrawerMenuItems
} from './widgets'

import { pallete, sizing } from '../../theme.js'
import { isDefined, notDefined } from '../../utils'
import { DisconnectedMenuItem } from './widgets/Disconnected'

const headerStyle = {
  '@media (min-width: 350px)': {
    height: `${sizing.header.height}px`
  },
  '@media (max-width: 349px)': {
    height: `${sizing.header.height * 0.8}px`
  },
  background: pallete.headerColor,
  zIndex: '10',
  '& .MuiToolbar-root': {}
}

const toolbarStyle = {
  height: '100%',
  padding: '0px !important',
  wrapper: {
    height: '100%',
    width: '100%',
    position: 'relative'
  },
  controlContainer: {
    margin: '0',
    position: 'absolute',
    top: '50%',
    left: '50%',
    '-ms-transform': 'translate(-50%, -50%)',
    transform: 'translate(-50%, -50%)'
  },
  userBadge: {
    paddingRight: '15px'
  }
}

class Header extends Component {
  render () {
    const { profile, connectionError } = this.props

    const userBadge = isDefined(profile)
      ? (
      <UserButton {...profile} />
        )
      : (
      <UnauthorizedButton disabled={connectionError} />
        )

    let loginMenu = (
      <HamburgerDrawerMenu reference="drawer-widgets">
        <LoginMenuItem />
        <QRCode />
        <Contacts />
      </HamburgerDrawerMenu>
    )

    if (connectionError) {
      loginMenu = (
        <HamburgerDrawerMenu reference="drawer-widgets">
          <DisconnectedMenuItem />
          <QRCode />
          <Contacts />
        </HamburgerDrawerMenu>
      )
    } else if (!notDefined(profile)) {
      loginMenu = (
        <HamburgerDrawerMenu reference="drawer-widgets">
          <UserDrawerMenuItems {...profile} reference="drawer-widgets" />
          <QRCode />
          <Contacts />
        </HamburgerDrawerMenu>
      )
    }

    return (
      <AppBar data-component="companion-header" elevation={3} sx={headerStyle}>
        <Toolbar sx={toolbarStyle}>
          <Grid container sx={toolbarStyle.wrapper}>
            <Grid item xs={6} sx={toolbarStyle.wrapper}>
              <HamburgerDrawerButton reference="drawer-widgets" />
              {loginMenu}
            </Grid>

            <Grid item xs={6} sx={toolbarStyle.wrapper}>
              <Grid
                container
                direction="row"
                justifyContent="end"
                sx={toolbarStyle.controlContainer}
              >
                <Grid item>
                  <LanguageSelectText />
                </Grid>
                <Grid item sx={toolbarStyle.userBadge}>
                  {userBadge}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    )
  }
}

const mapStateToProps = function (state) {
  const { profile } = state.auth
  const { connectionError } = state.error

  return {
    profile,
    connectionError
  }
}
export default connect(mapStateToProps)(Header)
