import { createSlice } from '@reduxjs/toolkit'

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const initialState = {
  data: [],
  error: '',
  checksum: '',
  updated: ''
}

const photos = createSlice({
  name: 'consite_photos',
  initialState,
  reducers: {
    fetchConsitePhotosSuccess (state, action) {
      const { data, checksum, generated } = action.payload
      return {
        ...state,
        data,
        checksum,
        updated: generated,
        error: ''
      }
    },
    fetchConsitePhotosError (state, action) {
      const { detail } = action.payload

      return {
        ...state,
        error: detail
      }
    }
  }
})

const persistConfig = {
  key: 'consite_photos',
  storage
}

export const { fetchConsitePhotosSuccess, fetchConsitePhotosError } =
  photos.actions
export const ConinfoPhotosReducer = persistReducer(
  persistConfig,
  photos.reducer
)
