import { createSlice, current } from '@reduxjs/toolkit'
import { definedOrDefault, handler, isDefined } from '../../utils'

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const initialState = {
  form: {}, // This is voting form related data e.g. selected fursuit, voted profiles
  profile: {}, // This is voting profile, it contains user voting profile returned from server
  checksum: undefined,
  updated: undefined,
  error: undefined
}

class VoteEngine {
  constructor (votingProfile, previousForm) {
    this.votingDeadline = votingProfile?.deadline_utc

    this.userTickets = definedOrDefault(
      handler(votingProfile.tickets),
      handler({ totalMax: 0, assigned: 0 })
    )

    this.unSubmittedVotes = definedOrDefault(
      handler(previousForm.unSubmittedVotes),
      handler([])
    )

    this.assignedTickets = definedOrDefault(
      handler(votingProfile.tickets.assigned),
      handler(0)
    )

    this.maxTickets = definedOrDefault(
      handler(votingProfile.tickets.totalMax),
      handler(0)
    )

    this.maxTicketsPerCampaign = definedOrDefault(
      handler(votingProfile.tickets.campaignMax),
      handler(0)
    )

    this.allProfiles = definedOrDefault(
      handler(votingProfile.profiles),
      handler([])
    )

    this.allCampaigns = definedOrDefault(
      handler(votingProfile.campaigns),
      handler([])
    )

    this.selectedIndex = definedOrDefault(
      handler(previousForm.selectedIndex),
      handler(0)
    )

    this.selectedProfile = definedOrDefault(
      handler(previousForm.selectedProfile),
      handler(this.allProfiles[this.selectedIndex])
    )

    this.unSubmittedVotes = definedOrDefault(
      handler(previousForm.unSubmittedVotes),
      handler([])
    )
  }

  processCampaignVote (campaignName, actualVote) {
    let alreadyAssignedVotesAll = 0
    const mergedProfiles = [...this.allProfiles, ...this.unSubmittedVotes]
    mergedProfiles.filter((f) => f.number !== this.selectedProfile.number)
      .forEach((profile) => {
        profile.voting.forEach((campaign) => {
          alreadyAssignedVotesAll += campaign.tickets
        })
      })

    const campaignsNotInScope = this.selectedProfile.voting.filter(
      (f) => f.campaign !== campaignName
    )

    campaignsNotInScope.forEach((campaign) => {
      alreadyAssignedVotesAll += campaign.tickets
    })

    const campaignInScope = definedOrDefault(() => {
      return this.selectedProfile.voting.find(
        (f) => f.campaign === campaignName
      )
    }, handler({ campaign: campaignName, tickets: 0 }))

    const newValue =
      campaignInScope.tickets + actualVote >= 0
        ? campaignInScope.tickets + actualVote
        : 0
    const possibleVotes = [
      alreadyAssignedVotesAll + newValue <= this.maxTickets
        ? newValue
        : this.maxTickets - alreadyAssignedVotesAll,
      newValue <= this.maxTicketsPerCampaign
        ? newValue
        : this.maxTicketsPerCampaign - campaignInScope.tickets
    ]

    const newCampaign = {
      campaign: campaignName,
      tickets: Math.min(...possibleVotes)
    }

    const newProfile = {
      ...this.selectedProfile,
      voting: [...campaignsNotInScope, newCampaign]
    }
    return newProfile
  }

  vote (campaignName, actualVote) {
    if (isDefined(this.selectedProfile)) {
      this.selectedProfile = this.processCampaignVote(campaignName, actualVote)
      const unSubmittedVotesFiltered = this.unSubmittedVotes.filter(
        (f) => f.number !== this.selectedProfile.number
      )
      this.unSubmittedVotes = [
        ...unSubmittedVotesFiltered,
        this.selectedProfile
      ]
    }
  }

  mergeWithUnsubmitted () {
    let unsubmittedTickets = 0
    const filter = []
    this.unSubmittedVotes.forEach((profile) => {
      filter.push(profile.number)
      profile.voting.forEach((campaign) => {
        unsubmittedTickets += campaign.tickets
      })
    })

    const base = []
    let alreadyAssignedVotesAll = unsubmittedTickets
    this.allProfiles.forEach((profile) => {
      if (!filter.includes(profile.number)) {
        base.push(profile)
        profile.voting.forEach((campaign) => {
          alreadyAssignedVotesAll += campaign.tickets
        })
      }
    })

    this.allProfiles = [...base, ...this.unSubmittedVotes].sort(
      (a, b) => a.order - b.order
    )
    this.assignedTickets = alreadyAssignedVotesAll
    this.selectedProfile = this.allProfiles[this.selectedIndex]
  }

  getNewform (previousForm) {
    this.mergeWithUnsubmitted()

    const newForm = {
      ...previousForm,
      selectedIndex: this.selectedIndex,
      selectedProfile: this.selectedProfile,
      unSubmittedVotes: this.unSubmittedVotes,
      assignedTickets: this.assignedTickets,
      maxTickets: this.maxTickets,
      maxTicketsPerCampaign: this.maxTicketsPerCampaign,
      remainingTickets: this.maxTickets - this.assignedTickets,
      allProfiles: this.allProfiles,
      allCampaigns: this.allCampaigns,
      votingDeadline: this.votingDeadline
    }

    return newForm
  }
}

const campaigns = createSlice({
  name: 'voting',
  initialState,
  reducers: {
    selectProfile (state, action) {
      const { form } = current(state)

      /**
       * Payload contains selected profile obj and index
       */
      const { index, profile } = action.payload

      /**
       * Creating new form state
       */
      const newForm = {
        ...form,
        selectedProfile: profile,
        selectedIndex: index
      }
      return { ...state, form: newForm, error: undefined }
    },

    vote (state, action) {
      /**
       * Payload contains campaigna name and actual vote assignable to form.selected profile
       */
      const { campaignName, actualVote } = action.payload

      /**
       * Getting current voting form and user profile
       */
      const { form, profile } = current(state)
      const engine = new VoteEngine(profile, form)

      engine.vote(campaignName, actualVote)
      return { ...state, form: engine.getNewform(), error: undefined }
    },
    fetchVotingProfileSucces (state, action) {
      const { form } = current(state)
      const { data, checksum, generated } = action.payload
      const profile = data[0]
      const engine = new VoteEngine(profile, form)

      return {
        ...state,
        profile,
        updated: generated,
        checksum,
        form: engine.getNewform(),
        error: undefined
      }
    },
    fetchVotingProfileError (state, action) {
      const { detail } = action.payload

      return {
        ...state,
        error: detail
      }
    },
    cancel (state) {
      const { profile, form } = state
      const engine = new VoteEngine(profile, {
        selectedIndex: form.selectedIndex
      })

      return {
        ...state,
        form: engine.getNewform(),
        error: undefined
      }
    },
    saveSuccess (state, action) {
      const { data, checksum, generated } = action.payload
      const { form } = state
      const profile = data[0]
      const engine = new VoteEngine(profile, {
        selectedIndex: form.selectedIndex
      })

      return {
        ...state,
        profile,
        form: engine.getNewform(),
        updated: generated,
        checksum,
        error: undefined
      }
    },
    saveError (state, action) {
      const { detail } = action.payload

      return {
        ...state,
        error: detail
      }
    }
  },
  extraReducers: {
    'persist/REHYDRATE': (state, action) => {
      const { key, payload } = action

      if (
        key === 'voting' &&
        isDefined(payload?.form) &&
        isDefined(payload?.profile)
      ) {
        const engine = new VoteEngine(payload.profile, payload.form)
        return {
          ...state,
          form: engine.getNewform()
        }
      } else {
        return { ...state }
      }
    }
  }
})

export const {
  selectProfile,
  vote,
  cancel,
  saveSuccess,
  saveError,
  fetchVotingProfileSucces,
  fetchVotingProfileError
} = campaigns.actions

const persistConfig = {
  key: 'voting',
  storage
}

export const VotingReducer = persistReducer(persistConfig, campaigns.reducer)
