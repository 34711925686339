import { createSlice, current } from '@reduxjs/toolkit'

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const initialState = {
  footerIndex: 0,
  footerLinks: {
    notifications: '/notifications',
    events: '/events',
    map: '/map'
  },
  activeLocation: undefined,
  lastLocation: undefined,
  routes: [],
  loading: false
}

const navigation = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    selectNavigation (state, action) {
      const { footerIndex, path } = action.payload
      return {
        ...state,
        footerIndex,
        activeLocation: path,
        lastLocation: path
      }
    },
    loadingOn (state, action) {
      return {
        ...state,
        loading: true
      }
    },
    loadingOff (state, action) {
      return {
        ...state,
        loading: false
      }
    },
    changeFooterLink (state, action) {
      const { footerLinks } = current(state)
      return {
        ...state,
        footerLinks: { ...footerLinks, ...action.payload }
      }
    }
  }
})

const persistConfig = {
  key: 'navigation',
  storage,
  blacklist: ['lastLocation', 'loading']
}

export const { selectNavigation, loadingOn, loadingOff, changeFooterLink } = navigation.actions
export const NavigationReducer = persistReducer(
  persistConfig,
  navigation.reducer
)
