import { Component } from 'react'

import { connect } from 'react-redux'
import { fetchConfig } from '../../api/config'
import { notDefined } from '../../utils'
import { ConfigLoading } from './widgets'

class ConfigProviderComponent extends Component {
  render () {
    const { config, children } = this.props

    if (notDefined(config.config) || config?.refresh) {
      fetchConfig()
      return <ConfigLoading />
    } else {
      return children
    }
  }
}

const mapConfigProps = function (state) {
  return { config: state?.config }
}

const ConfigProvider = connect(mapConfigProps)(ConfigProviderComponent)

export { ConfigProvider }
