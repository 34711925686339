import { configureStore } from '@reduxjs/toolkit'

import {
  FLUSH,
  PAUSE,
  PERSIST, persistStore, PURGE,
  REGISTER,
  REHYDRATE
} from 'redux-persist'

import { AchievementsReducer } from './achievements'
import { AuthReducer } from './auth'
import { ConfigReducer } from './config'
import { ConinfoBarReducer } from './coninfo/bar'
import { ConinfoMenuReducer } from './coninfo/menu'
import { ConinfoMessagesReducer } from './coninfo/messages'
import { ConinfoPhotosReducer } from './coninfo/photos'
import { ConinfoScreensReducer } from './coninfo/screens'
import { ErrorReducer } from './error'
import { EventsReducer } from './events'
import { EventsMenuReducer } from './events/menu'
import { LanguageReducer } from './lang'
import { MapReducer } from './map'
import { MenuReducer } from './menu'
import { NavigationReducer } from './navigation'
import { NotificationsReducer } from './notifications'
import { VotingReducer } from './voting'
import { YapsReducer } from './yaps'

// TODO: Use https://redux-toolkit.js.org/rtk-query instead of axios and custom data hooks

const modelVersion = '1.0'

export const store = configureStore({
  reducer: {
    achievements: AchievementsReducer,
    auth: AuthReducer,
    navigation: NavigationReducer,
    menu: MenuReducer,
    notifications: NotificationsReducer,
    voting: VotingReducer,
    schedule: EventsReducer,
    language: LanguageReducer,
    error: ErrorReducer,
    map: MapReducer,
    config: ConfigReducer,
    coninfoMenu: ConinfoMenuReducer,
    coninfoMessages: ConinfoMessagesReducer,
    coninfoPhotos: ConinfoPhotosReducer,
    coninfoBar: ConinfoBarReducer,
    coninfoScreens: ConinfoScreensReducer,
    eventsMenu: EventsMenuReducer,
    yaps: YapsReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
})

const cacheVersion = localStorage.getItem('cache-version')
if (cacheVersion !== modelVersion) {
  localStorage.clear()
  localStorage.setItem('cache-version', modelVersion)
  window.location.reload()
}

export const State = store // Todo: find usages and remove
export const persistor = persistStore(store)
export default store
