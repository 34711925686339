import { createSlice, current } from '@reduxjs/toolkit'

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { didConfigChange } from '../../utils'

const initialState = {
  config: {}
}

const config = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setConfig (state, action) {
      const { config } = current(state)
      return {
        ...state,
        config: { ...action.payload },
        refresh: didConfigChange(config, { ...action.payload })
      }
    },
    setRefresh (state, action) {
      return {
        ...state,
        ...action.payload
      }
    }
  }
})

const persistConfig = {
  key: 'config',
  storage
}

export const { setConfig, setRefresh } = config.actions
export const ConfigReducer = persistReducer(persistConfig, config.reducer)
