import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  connectionError: false,
  dataError: false,
  errorDetail: ''
}

const errorState = createSlice({
  name: 'error-state',
  initialState,
  reducers: {
    resetErrors () {
      return {
        ...initialState
      }
    },
    setConnectionError (state, action) {
      const { error, detail } = action.payload

      return {
        ...state,
        connectionError: error,
        errorDetail: detail
      }
    },
    setDataError (state, action) {
      const { error, detail } = action.payload

      return {
        ...state,
        connectionError: error,
        errorDetail: detail
      }
    }
  }
})

export const {
  resetErrors,
  setConnectionError,
  setDataError
} = errorState.actions
export const ErrorReducer = errorState.reducer
