import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { AuthorizationGate } from './components/Authorization'
import { Browser, Menu, Send } from './components/yaps'

const YapsHome = () => {
  const auth = useSelector((state) => state.auth)
  const config = useSelector((state) => state.config)
  const yaps = useSelector((state) => state.yaps)
  const navigation = useSelector((state) => state.navigation)
  const language = useSelector((state) => state.language)
  const error = useSelector((state) => state.error)

  const sendProps = {
    auth,
    creating: yaps.creating,
    created: yaps.created,
    createDisabled: yaps.createDisabled,
    validationError: yaps.validationError,
    draftedMessage: yaps.draftedMessage,
    error,
    language: language.active,
    navigation,
    ...config
  }

  return (
    <AuthorizationGate>
      <Box>
        <Menu />
        <Send {...sendProps} />
        <Browser {...yaps} {...navigation} />
      </Box>
    </AuthorizationGate>
  )
}

export { YapsHome }
