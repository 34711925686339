import { Component } from 'react'

import { Link } from 'react-router-dom'

import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/system'

import { connect } from 'react-redux'
import { withTranslation } from '../../../language'
import { pallete } from '../../../theme.js'
import { avatarUrl, mapAppConfig } from '../../../utils'

const badgeStyle = {
  container: {
    width: '120px',
    paddingTop: '5px',
    textAlign: 'center'
  },
  avatar: {
    background: pallete.paperColor,
    color: pallete.headerColor,
    padding: '1px'
  },
  label: {
    color: pallete.textColor,
    fontWeight: 600
  }
}

const buttonStyle = {
  container: {
    borderRadius: 25,
    padding: 0,
    textAlign: 'left',
    display: 'block'
  },
  avatar_wrapper: {
    background: pallete.paperColor,
    color: pallete.headerColor,
    padding: '5px',
    borderRadius: '25px',
    display: 'inline-block'
  },
  avatar: {}
}

class UserButtonComponent extends Component {
  render () {
    const { avatar_url, nick, config } = this.props
    const avatar = avatarUrl(config, avatar_url)

    return (
      <IconButton data-component="user-button" sx={buttonStyle.container}>
        <Box sx={buttonStyle.avatar_wrapper}>
          <Avatar sx={buttonStyle.avatar} alt={nick} src={avatar} />
        </Box>
      </IconButton>
    )
  }
}

const mapUserButtonProps = function (state, props) {
  return mapAppConfig(state, props)
}

export const UserButton = connect(mapUserButtonProps)(UserButtonComponent)

class UnauthorizedBadgeComponent extends Component {
  render () {
    const { t } = this.props.i18nEngine
    return (
      <Box data-component="unauthorized-badge" sx={badgeStyle.container}>
        <IconButton sx={badgeStyle.avatar}>
          <Avatar sx={badgeStyle.avatar} />
        </IconButton>
        <Typography sx={badgeStyle.label}>{t('limitedAccess')}</Typography>
      </Box>
    )
  }
}

const unauthorizedButtonStyle = {
  button: {
    background: pallete.paperColor,
    color: pallete.headerColor,
    padding: '0px',
    borderRadius: 25,
    '&:hover': {
      background: pallete.paperColor
    }
  },
  avatar: {
    background: pallete.paperColor,
    color: pallete.headerColor
  },
  label: {
    color: pallete.textColor,
    fontWeight: 600,
    paddingRight: '10px',
    fontSize: '0.75rem',
    '@media (min-width:400px)': {
      fontSize: '1rem'
    }
  }
}

class UnauthorizedButtonComponent extends Component {
  render () {
    return (
      <IconButton
        component={Link}
        to="/login"
        data-component="unauthorized-button"
        sx={unauthorizedButtonStyle.button}
        disabled={this.props.disabled}
      >
        <Avatar sx={unauthorizedButtonStyle.avatar} />
      </IconButton>
    )
  }
}

export const UnauthorizedBadge = withTranslation(UnauthorizedBadgeComponent)
export const UnauthorizedButton = withTranslation(UnauthorizedButtonComponent)
