import store from '../state/store.js'
import {
  fetchVotingProfileError,
  fetchVotingProfileSucces,
  saveError,
  saveSuccess
} from '../state/voting'

import { AuthorizedApi } from './index.js'

export function fetchVotingProfiles (config, auth) {
  const api = new AuthorizedApi(
    config,
    (success) => {
      const { data } = success
      store.dispatch(fetchVotingProfileSucces(data))
    },
    (error) => {
      store.dispatch(fetchVotingProfileError(error))
    }
  )

  api.fetchWithToken('voting/', auth?.accessToken)
}

export function save (config, profiles, auth) {
  const api = new AuthorizedApi(
    config,
    (success) => {
      const { data } = success
      store.dispatch(saveSuccess(data))
    },
    (error) => {
      store.dispatch(saveError({ error }))
    }
  )

  api.putWithToken('voting/vote/', auth?.accessToken, { profiles })
}
