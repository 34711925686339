import { Box } from '@mui/material'
import { Component } from 'react'

const sx = {
  backgroundImage: {
    maxWidth: '100%',
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: '0',
    zIndex: '-100',
    backgroundColor: 'black',
    backgroundPosition: '30% top',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage:
      'url(https://www.cesfur.org/css/gfx-cesfur/2024/background-lg.jpg)',
    '@media screen and (max-width: 700px)': {
      backgroundImage:
        'url(https://www.cesfur.org/css/gfx-cesfur/2024/background-md.jpg)',
      backgroundPosition: 'right top'
    }
  }
}

class BackgroundImageWithAlfa extends Component {
  render () {
    return <Box sx={sx.backgroundImage}></Box>
  }
}

export default BackgroundImageWithAlfa
