import axios from 'axios'
import { setConfig } from '../state/config'
import store from '../state/store'

export function fetchConfig () {
  const randomSuffix = Math.floor(Math.random() * 9999)

  axios.get(`/config.json?v=${randomSuffix}`).then(
    (response) => {
      const { data } = response
      store.dispatch(setConfig(data))
    },
    (error) => {
      console.error(error)
      // const { message } = error
      // store.dispatch(setConfig({}))
      // store.dispatch(setConnectionError({ error: true, detail: message }))
    }
  )
}
