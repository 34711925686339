import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { notDefined } from '../../../utils'
import { CardWithAvatar, Loading, NoData } from '../widgets'

const browserStyle = {
  '&': {
    padding: '5px'
  },
  '& .companion-card-root': {
    width: '100%',
    marginBottom: '5px',
    '& .companion-card-content p': {
      fontStyle: 'italic'
    }
  }
}

const Browser = (props) => {
  const translation = useTranslation()
  const { data, noData, loading } = props

  if (loading && notDefined(data)) {
    return <Loading />
  }

  if (notDefined(data)) {
    return <NoData {...noData}/>
  }

  const items = data.map((item, key) => {
    const { attendee, badge, message, time } = item
    const locale = translation.t('date_locale')
    const title = attendee + ' - ' + new Date(time * 1000).toLocaleString(locale)

    return (
      <CardWithAvatar
        key={key}
        title={title}
        text={message}
        src={badge}
      />
    )
  })

  return (
    <Grid container sx={browserStyle} className="companion-yaps-browser">
      {items}
    </Grid>
  )
}

export { Browser }
