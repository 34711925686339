import { Box, Grid, Tab, Tabs } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { pallete } from '../../../theme'

const sx = {
  tabs: {
    width: '100%',
    height: '50px',
    background: pallete.headerTransparentColor,
    '& .Mui-selected': {
      color: `${pallete.paperColor} !important`
    },
    '@media (max-width: 350px)': {
      width: '100% !important',
      minWidth: '100% !important',
      maxWidth: '100% !important'
    }
  },
  controls: {
    height: '50px',
    lineHeight: '50px',
    textAlign: 'right',
    paddingRight: '10px',
    background: pallete.headerTransparentColor,
    '@media (max-width: 350px)': {
      display: 'none !important'
    }
  }
}

const Menu = (props) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { paths } = useSelector((state) => state?.achievements?.menu)
  const selected = (paths ?? []).indexOf(pathname)
  const loggedIn = useSelector((state) => state?.auth?.profile ?? false)
  const userAchievements = loggedIn ? (<Tab component={Link} to="/achievements/user" label={t('user-achievements')}/>) : undefined

  return (
    <Box {...props} >
      <Grid container>
        <Grid item sx={sx.tabs}>
          <Tabs aria-label="achievements menu tabs" value={selected > 0 ? selected : 0}>
            <Tab component={Link} to="/achievements" label={t('all-achievements')} />
            {userAchievements}
          </Tabs>
        </Grid>
      </Grid>
    </Box>
  )
}

export { Menu }
