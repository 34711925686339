import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { translateText } from '../../../language'
import { isDefined, notDefined } from '../../../utils'
import { Card, Loading, NoData } from '../widgets'

const browserStyle = {
  '&': {
    padding: '5px'
  },
  '& .companion-card-root': {
    width: '100%',
    marginBottom: '5px',
    '& .companion-card-avatar-outer .MuiAvatar-root': {
      background: 'none !important'
    },
    '& .companion-card-content .MuiCardContent-root': {
      padding: '5px'
    }
  }
}

const itemStyle = {
  marginBottom: '5px'
}

const Browser = (props) => {
  const translation = useTranslation()
  const { data, noData, loading, category } = props

  if (loading && notDefined(data)) {
    return <Loading />
  }

  if (notDefined(data) || data.length === 0) {
    return <NoData {...noData}/>
  }

  const notifications = data.map((notification, key) => {
    const titles = notification.titles ? notification.titles : []
    const title = translateText(titles, translation.i18n)
    const descriptions = notification.descriptions ? notification.descriptions : []
    const description = translateText(descriptions, translation.i18n)

    if (isDefined(category) && notification.category !== category) {
      return undefined
    }

    return (
      <Card
        key={key}
        title={title}
        text={description}
        sx={itemStyle}
        icon={notification.icon}
        link={notification.link}
      />
    )
  })

  return (
    <Box sx={browserStyle} className='companion-notifications-browser'>
      {notifications}
    </Box>
  )
}

export { Browser }
