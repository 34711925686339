import { createSlice, current } from '@reduxjs/toolkit'

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { isDefined } from '../../utils'

const initialState = {
  menu: {
    paths: ['/achievements', '/achievements/user']
  },
  allRequest: {
    data: [],
    error: '',
    checksum: '',
    updated: ''
  },
  userRequest: {
    data: [],
    error: '',
    checksum: '',
    updated: ''
  },
  all: {
    map: [],
    values: []
  },
  user: {
    map: [],
    values: []
  }
}

const achievements = createSlice({
  name: 'achievements',
  initialState,
  reducers: {
    fetchAchievementsSuccess (state, action) {
      const { data, checksum, generated } = action.payload
      const { all, user } = current(state)
      const previous_all_achievements = new Map(all.map)
      const previous_user_achievements = new Map(user.map)
      const new_all_achievements = new Map()

      data.forEach(achievement => {
        const { achievement_id } = achievement
        const previous = previous_all_achievements.get(achievement_id)
        const previous_user = previous_user_achievements.get(achievement_id)
        const awarded = previous?.awarded || previous_user?.awarded || {}
        new_all_achievements.set(achievement_id, { ...achievement, awarded })
      })
      return {
        ...state,
        allRequest: {
          data,
          checksum,
          updated: generated,
          error: ''
        },
        all: {
          map: Array.from(new_all_achievements),
          values: Array.from(new_all_achievements.values())
        }
      }
    },
    fetchAchievementsError (state, action) {
      const { detail } = action.payload

      return {
        ...state,
        allRequest: {
          error: detail
        }
      }
    },
    fetchUserAchievementsSuccess (state, action) {
      const { data, checksum, generated } = action.payload
      const { all } = current(state)
      const previous_all_achievements = new Map(all.map)
      const new_user_achievements = new Map()
      data.forEach(achievement => {
        const { timestamp } = achievement
        const { achievement_id } = achievement.achievement

        const new_achievement = achievement?.achievement || previous_all_achievements.get(achievement_id)
        if (isDefined(new_achievement)) {
          new_user_achievements.set(achievement_id, { ...new_achievement, awarded: { timestamp } })
          previous_all_achievements.set(achievement_id, { ...new_achievement, awarded: { timestamp } })
        }
      })
      return {
        ...state,
        userRequest: {
          data,
          checksum,
          updated: generated,
          error: ''
        },
        all: {
          map: Array.from(previous_all_achievements),
          values: Array.from(previous_all_achievements.values())
        },
        user: {
          map: Array.from(new_user_achievements),
          values: Array.from(new_user_achievements.values())
        }
      }
    },
    fetchUserAchievementsError (state, action) {
      const { detail } = action.payload

      return {
        ...state,
        userRequest: {
          error: detail
        }
      }
    }
  }
})

const persistConfig = {
  key: 'achievements',
  storage,
  debug: true
}

export const { fetchAchievementsSuccess, fetchAchievementsError, fetchUserAchievementsSuccess, fetchUserAchievementsError } = achievements.actions
export const AchievementsReducer = persistReducer(
  persistConfig,
  achievements.reducer
)
