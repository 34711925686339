/**
 * React, react redux and react localization imports
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { logout } from '../../../api/auth'

/**
 * Material imports
 */
import {
  Logout as LogoutIcon,
  Menu as MenuIcon,
  ThumbUpOutlined
} from '@mui/icons-material'
import {
  Avatar,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem, Typography
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
/**
 * Relative exports to root directory
 */
import SendIcon from '@mui/icons-material/Send'
import { Box } from '@mui/system'
import { Link } from 'react-router-dom'
import { withTranslation } from '../../../language'
import { toggleMenu } from '../../../state/menu'
import store from '../../../state/store'
import { pallete } from '../../../theme'
import { avatarUrl, mapAppConfig } from '../../../utils'
import { TranslatableButton } from './Button'
import DefaultAchievementIcon from './DefaultAchievementIcon'
import { TranslatableTypography } from './Typography'

const buttonStyle = {
  button: {
    padding: '5px',
    borderRadius: '15px',
    height: '100%'
  },
  icon: {
    marginLeft: '10px',
    marginRight: '10px',
    color: pallete.textColor,
    height: '100%'
  },
  image: {
    height: '100%',
    filter: `drop-shadow(0px 0px 3px ${pallete.headerBrightColor})`
  }
}

/**
 * Component for opening hamburger menu
 * It's connected to redux state management.
 */
class HamburgerMenuButton extends Component {
  render () {
    const { reference, toggled, i18nEngine } = this.props
    const { t } = i18nEngine

    const control = {
      onClick: (event) =>
        this.props.toggleMenu(reference, !toggled, event.currentTarget)
    }

    return (
      <IconButton
        size="large"
        aria-label="menu"
        style={buttonStyle.button}
        {...control}
      >
        <MenuIcon style={buttonStyle.icon} />
        <img
          className="highlight-shadow"
          style={buttonStyle.image}
          alt="Convention logo"
          src={t('header')}
        />
      </IconButton>
    )
  }
}

const hamburgerMenuStyle = {
  overflow: 'visible',
  mt: 1.5,
  width: '180px',
  bgColor: pallete.headerColor,
  '& .MuiAvatarRoot': {
    width: 32,
    height: 32,
    ml: -0.5,
    mr: 1
  },
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 7,
    width: 10,
    height: 10,
    bgcolor: pallete.paperColor,
    transform: 'translateY(-50%) rotate(45deg)',
    zIndex: 0
  }
}

/**
 * Component used for holding all the items
 */
class HamburgerMenuContent extends Component {
  render () {
    const { reference } = this.props

    const behavior = {
      transformOrigin: { horizontal: 'right', vertical: 'top' },
      anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      PaperProps: { elevation: 3, style: hamburgerMenuStyle }
    }

    const control = {
      open: this.props.toggled,
      anchorEl: this.props.anchorEl,
      onClose: () => this.props.toggleMenu(reference, false, null),
      onClick: () => this.props.toggleMenu(reference, false, null)
    }

    return (
      <Menu {...behavior} {...control}>
        {this.props.children}
      </Menu>
    )
  }
}

/**
 * This is main popup menu component
 */
class HamburgerPopupMenuComponent extends Component {
  constructor (props) {
    super(props)
    this.state = { anchor: null }
  }

  render () {
    const propsProxy = {
      reference: this.props.reference,
      toggled: this.props.toggled,
      i18nEngine: this.props.i18nEngine,
      anchorEl: this.state.anchor
    }

    const handlerProxy = {
      toggleMenu: (reference, toggled, anchor) => {
        this.setState(() => {
          return { anchor }
        })
        this.props.toggleMenu(reference, toggled)
      }
    }

    return (
      <React.Fragment>
        <HamburgerMenuButton {...propsProxy} {...handlerProxy} />
        <HamburgerMenuContent {...propsProxy} {...handlerProxy}>
          {this.props.children}
        </HamburgerMenuContent>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, props) => {
  const toggled = state.menu[props.reference]
  return { toggled: toggled === true }
}

const mapDispatchToProps = () => {
  return {
    toggleMenu: (reference, toggleValue) => {
      const payload = {
        reference,
        value: toggleValue
      }

      store.dispatch(toggleMenu(payload))
    }
  }
}

export const HamburgerPopupMenu = withTranslation(
  connect(mapStateToProps, mapDispatchToProps)(HamburgerPopupMenuComponent)
)

/**
 * This is main popup menu component
 */
class HamburgerDrawerMenuComponent extends Component {
  render () {
    const { toggled, reference } = this.props

    const control = {
      onClose: (event) => this.props.toggleMenu(reference, !toggled, null)
    }

    return (
      <Drawer anchor="left" open={toggled} {...control}>
        <Box
          sx={{
            width: '250px',
            height: '100%',
            background: pallete.headerColor
          }}
        >
          {this.props.children}
        </Box>
      </Drawer>
    )
  }
}
export const HamburgerDrawerButton = withTranslation(
  connect(mapStateToProps, mapDispatchToProps)(HamburgerMenuButton)
)

export const HamburgerDrawerMenu = withTranslation(
  connect(mapStateToProps, mapDispatchToProps)(HamburgerDrawerMenuComponent)
)

/**
 * Component used for holding all the items
 */
class TranslatableMenuItemComponent extends Component {
  render () {
    const { t } = this.props.i18nEngine

    return (
      <MenuItem>
        {t(this.props.text)}
        {this.props.children}
      </MenuItem>
    )
  }
}

export const TranslatableMenuItem = withTranslation(
  TranslatableMenuItemComponent
)

const userItemStyle = {
  wrapper: {
    padding: '0px !important',
    color: pallete.paperColor,
    fontWeight: 600
  },
  icon: {
    minWidth: 'auto',
    color: pallete.paperColor,
    fontWeight: 600,
    marginRight: '5px'
  }
}

export const UserDrawerMenuItem = (props) => {
  const { link, icon, label, onClick, className } = props
  return (
    <ListItem className={className} sx={userItemStyle.wrapper}>
      <ListItemButton
        onClick={() => onClick(link)}
        component={Link}
        to={link}
      >
        <ListItemIcon sx={userItemStyle.icon}>
          {icon}
        </ListItemIcon>
        <ListItemText>
          <TranslatableTypography>{label}</TranslatableTypography>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  )
}

const menuItemsStyle = {
  loginWrapper: {
    width: '100%',
    padding: '10px'
  },
  avatarWrapper: {
    display: 'inline-block'
  },
  nickWrapper: {
    marginLeft: '10px',
    display: 'inline-block',
    fontWeight: 600,
    color: pallete.paperColor
  },
  logoutWrapper: {
    width: '100%',
    textAlign: 'center',
    marginTop: '15px'
  },
  menuList: {
    padding: '0px !important',
    '& .companion-menu-item .MuiListItemIcon-root': {
      width: '35px',
      '& svg': {
        display: 'block',
        margin: '0 auto'
      }
    }
  },
  fullWidth: {
    width: '100%'
  }
}

class UserDrawerMenuItemsComponent extends Component {
  onLogout (config, auth) {
    logout(config, auth)
    this.closeDrawer()
  }

  closeDrawer () {
    const { reference } = this.props
    const closeEvent = { reference, value: false }
    store.dispatch(toggleMenu(closeEvent))
  }

  render () {
    const { nick, attendee, avatar_url, config, auth } = this.props
    const avatar = avatarUrl(config, avatar_url)

    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        sx={menuItemsStyle.fullWidth}
      >
        <Grid
          item
          sx={{
            ...menuItemsStyle.loginWrapper,
            ...menuItemsStyle.fullWidth
          }}
        >
          <Box sx={menuItemsStyle.avatarWrapper}>
            <Avatar alt={nick} src={avatar} />
          </Box>
          <Box sx={menuItemsStyle.nickWrapper}>
            <Typography>{nick}</Typography>
            <Typography>#{attendee}</Typography>
          </Box>
        </Grid>

        <Grid item sx={menuItemsStyle.fullWidth}>
          <List sx={menuItemsStyle.menuList}>
            <Divider />
            <UserDrawerMenuItem className="companion-menu-item" icon={ <ThumbUpOutlined /> } onClick={() => this.closeDrawer()} label="voting" link="/voting" />
            <Divider />
            <UserDrawerMenuItem icon={ <DefaultAchievementIcon /> } onClick={() => this.closeDrawer()} label="my-achievements" link="/achievements" />
            <Divider />
            <UserDrawerMenuItem className="companion-menu-item" icon={ <SendIcon /> } onClick={() => this.closeDrawer()} label="yaps" link="/yaps" />
            <Divider />
          </List>
        </Grid>

        <Grid
          item
          sx={{
            ...menuItemsStyle.logoutWrapper,
            ...menuItemsStyle.fullWidth
          }}
        >
          <TranslatableButton
            component={Link}
            to="/logout"
            variant="invert-contained"
            startIcon={<LogoutIcon />}
            onClick={() => this.onLogout(config, auth)}
          >
            logout
          </TranslatableButton>
        </Grid>
      </Grid>
    )
  }
}

const mapAvatarStateToProps = (state, props) => {
  return { auth: state.auth, ...mapAppConfig(state, props) }
}

export const UserDrawerMenuItems = withTranslation(
  connect(mapAvatarStateToProps)(UserDrawerMenuItemsComponent)
)
