import { Api } from '.'
import store from '../state/store.js'

import { fetchMapError, fetchMapSuccess } from '../state/map'

export function fetchMap (config) {
  const api = new Api(
    config,
    (response) => {
      const { data } = response
      store.dispatch(fetchMapSuccess(data))
    },
    (error) => {
      const { detail } = error
      store.dispatch(fetchMapError({ detail }))
    }
  )

  api.fetch('map')
}
