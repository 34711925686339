import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import PetsOutlinedIcon from '@mui/icons-material/PetsOutlined'
import { Box, Grid, Tab, Tabs } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { selectEventsMenuItem } from '../../../state/events/menu'
import { changeFooterLink } from '../../../state/navigation'
import { pallete } from '../../../theme'
import { TranslatableButton } from '../widgets/Button'

const sx = {
  tabs: {
    height: '50px',
    background: pallete.headerTransparentColor,
    '& .Mui-selected': {
      color: `${pallete.paperColor} !important`
    },
    '@media (max-width: 350px)': {
      width: '100% !important',
      minWidth: '100% !important',
      maxWidth: '100% !important'
    }
  },
  controls: {
    height: '50px',
    lineHeight: '50px',
    textAlign: 'right',
    paddingRight: '10px',
    background: pallete.headerTransparentColor,
    '@media (max-width: 350px)': {
      display: 'none !important'
    }
  }
}

const Menu = (props) => {
  const paths = ['/events', '/events/favorite', '/events/assigned']

  const { assignedCount } = props

  const selected = useSelector((state) => state?.eventsMenu?.active?.selected ?? 0)
  const loggedIn = useSelector((state) => state?.auth?.profile ?? false)
  const dispatch = useDispatch()
  const selectMenuItemDispatcher = (e, value) => {
    dispatch(selectEventsMenuItem({ selected: value, path: paths[value] }))
    dispatch(changeFooterLink({ events: paths[value] }))
  }
  const assigned = (loggedIn && assignedCount > 0) ? (<Tab component={Link} to="/events/assigned" icon={<AssignmentOutlinedIcon />}/>) : undefined

  const handleClick = () => {
    const today = new Date().toISOString().substring(0, 10)
    const ref = document.getElementsByClassName('companion-event-browser-day-scroll-pivot ' + today)
    if (ref.length > 0) {
      ref[0].scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  return (
    <Box>
      <Grid container>
        <Grid item xs={9} sx={sx.tabs}>
          <Tabs aria-label="events menu tabs" value={selected} onChange={selectMenuItemDispatcher}>
            <Tab component={Link} to="/events" icon={<CalendarMonthOutlinedIcon />}/>
            <Tab component={Link} to="/events/favorite" icon={<PetsOutlinedIcon />}/>
            {assigned}
          </Tabs>
        </Grid>
        <Grid item xs={3} sx={sx.controls}>
          <TranslatableButton variant="contained" onClick={handleClick}>today</TranslatableButton>
        </Grid>
      </Grid>
    </Box>
  )
}

export { Menu }
