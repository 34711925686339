import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import { Box, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { translateText } from '../../../language'
import { pallete } from '../../../theme'
import { isDefined, notDefined } from '../../../utils'
import { NoData, TranslatableTypography } from '../widgets'

const browserStyle = {
  padding: '5px',
  '& .companion-achievement-browser': {
    backgroundColor: pallete.paperColor,
    borderRadius: '5px'
  },
  '& .companion-achievement-browser-label': {
    color: pallete.headerColor,
    padding: '15px',
    fontWeoght: '400',
    textDecoration: 'underline',
    fontStyle: 'italic'
  },
  '& .companion-achievement-icon': {
    padding: '5px',
    width: '50px',
    height: '50px',
    '& img': {
      margin: 'auto',
      display: 'block',
      width: '100%',
      height: '100%'
    }
  },
  '& .companion-achievement-content': {
    width: 'calc(100% - 60px)'
  },
  '& .companion-achievement-item:nth-of-type(even)': {
    background: pallete.paperColorDim,
    width: '100%'
  },
  '& .companion-achievement-item:nth-of-type(odd)': {
    background: pallete.paperColorDim2,
    width: '100%'
  },
  '& .companion-achievement-title': {
    fontSize: '1rem',
    color: pallete.headerColor
  },
  '& .companion-achievement-description': {
    fontSize: '0.8rem',
    textDecoration: 'italic'
  },
  '& .companion-achievement-awarded': {
    textAlign: 'right',
    '& svg': {
      color: pallete.headerColor
    },
    '& p': {
      color: pallete.headerColor
    }
  }
}

const Achievement = (props) => {
  const { title, description, image_url, awarded } = props
  const imageSrc = image_url ?? '/default-achievement.svg'

  let medal
  if (isDefined(awarded)) {
    medal = <Grid item xs={12} className='companion-achievement-awarded'>
      <Grid container>
        <Grid item xs={11}>
          <TranslatableTypography>achievement-awarded</TranslatableTypography>
        </Grid>
        <Grid item xs={1}>
          <EmojiEventsIcon />
        </Grid>
      </Grid>
    </Grid>
  }

  return (
    <Grid container className='companion-achievement-available'>
      <Grid item className='companion-achievement-icon'>
        <img src={imageSrc} alt='achievement-icon'/>
      </Grid>
      <Grid item className='companion-achievement-content'>
        <Grid container>
          <Grid item xs={12}>
            <Typography className='companion-achievement-title'>{title}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className='companion-achievement-description'>{description}</Typography>
          </Grid>
          {medal}
        </Grid>
      </Grid>
    </Grid>
  )
}

const Browser = (props) => {
  const { data, noData, loading, label } = props
  const { i18n } = useTranslation()

  if (loading && notDefined(data)) {
    return undefined
  }

  if (notDefined(data)) {
    return <NoData />
  } else if (data.length === 0) {
    return <NoData {...noData}/>
  }

  const achievements = data.map((itemProps, key) => {
    const { title, description, awarded } = itemProps
    const translatedTitle = translateText(title, i18n)
    const translatedDescription = translateText(description, i18n)
    const order = translatedTitle.split(' ').join('').toUpperCase()
    const date_locale = i18n.t('date_locale')
    const newProps = {
      ...itemProps,
      title: translatedTitle,
      description: translatedDescription,
      awarded,
      date_locale
    }
    return (
      <Grid item key={key} order={order} xs={12} className='companion-achievement-item'>
        <Achievement {...newProps} />
      </Grid>
    )
  })

  const ordered = achievements.sort((c1, c2) => {
    if (c1.props?.order < c2.props?.order) {
      return -1
    }
    return 1
  })

  return (
    <Box sx={browserStyle}>
      <Box className='companion-achievement-browser'>
        <TranslatableTypography className='companion-achievement-browser-label'>{label}</TranslatableTypography>
        <Grid container className='companion-achievement-browser-content'>
          {ordered}
        </Grid>
      </Box>
    </Box>
  )
}

export { Achievement, Browser }
