/**
 * React, react redux and react localization imports
 */

import { Component } from 'react'
import { connect } from 'react-redux'

/**
 * Material imports
 */
import CloudOffIcon from '@mui/icons-material/CloudOff'
import HelpIcon from '@mui/icons-material/Help'
import WifiOffIcon from '@mui/icons-material/WifiOff'

/**
 * Relative exports to root directory
 */
import { Box, Modal, Paper } from '@mui/material'
import { withTranslation } from '../../../language'
import { pallete, sizing } from '../../../theme'
import { TranslatableTypography } from './Typography'

class StatelessDisconnectedComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false
    }
  }

  handleOpen () {
    this.setState({ ...this.state, open: true })
  }

  handleClose () {
    this.setState({ ...this.state, open: false })
  }

  render () {
    const { label, visible } = this.props
    const hidden = visible
      ? { visibility: 'visible' }
      : { visibility: 'hidden' }

    const iconSx = {
      lineHeight: '32px',
      height: '32px',
      display: 'block',
      float: 'left'
    }
    const icon =
      label === 'server-error'
        ? (
        <CloudOffIcon sx={iconSx} />
          )
        : (
        <WifiOffIcon sx={iconSx} />
          )

    const modalIconSx = {
      width: '128px',
      height: '128px',
      display: 'inline-block',
      color: pallete.headerColor
    }
    const modalIcon =
      label === 'server-error'
        ? (
        <CloudOffIcon sx={modalIconSx} />
          )
        : (
        <WifiOffIcon sx={modalIconSx} />
          )

    return (
      <Box
        sx={{
          ...hidden,
          ...this.props.sx,
          width: '100%',
          background: pallete.headerBrightColor,
          textAlign: 'center',
          height: '32px'
        }}
      >
        <Modal
          open={this.state.open}
          onClose={() => this.handleClose()}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          sx={{ padding: '20px' }}
        >
          <Paper
            sx={{
              textAlign: 'center',
              padding: '10px',
              marginTop: '80px',
              filter: `drop-shadow(0px 0px 3px  ${pallete.headerBrightColor})`,
              color: pallete.textColor
            }}
          >
            {modalIcon}
            <TranslatableTypography>{`${label}-help`}</TranslatableTypography>
          </Paper>
        </Modal>

        <Box
          sx={{
            height: '32px',
            display: 'inline-block',
            color: 'rgb(207, 45, 45)',
            position: 'relative'
          }}
          onClick={() => this.handleOpen()}
        >
          {icon}
          <TranslatableTypography
            sx={{
              fontWeight: 600,
              fontSize: '0.7rem',
              display: 'block',
              marginLeft: '10px',
              marginRight: '10px',
              lineHeight: '32px',
              height: '32px',
              float: 'left'
            }}
          >
            {label}
          </TranslatableTypography>

          <HelpIcon
            sx={{
              lineHeight: '32px',
              height: '32px',
              display: 'block',
              float: 'left',
              color: pallete.headerColor
            }}
          />
        </Box>
      </Box>
    )
  }
}

const StatelessDisconnected = withTranslation(StatelessDisconnectedComponent)

class DisconnectedComponent extends Component {
  render () {
    const { visible, label } = this.props

    return (
      <StatelessDisconnected
        visible={visible}
        label={label}
        sx={{
          position: 'absolute',
          bottom: `${sizing.footer.height}px`,
          zIndex: 1000
        }}
      />
    )
  }
}

const mapStateToProps = (state) => {
  const { connectionError } = state.error

  const label = connectionError ? 'server-error' : 'consite-unavailable'

  return {
    visible: connectionError,
    label
  }
}

const Disconnected = connect(mapStateToProps)(DisconnectedComponent)

const disconnectItemStyle = {
  wrapper: {
    fontSize: 14,
    color: pallete.paperColor,
    textAlign: 'center',
    padding: '15px'
  },
  icon: {
    width: '64px',
    height: '64px',
    display: 'inline-block',
    color: pallete.paperColor
  },
  typography: { marginTop: '15px' },
  button: { marginTop: '15px' }
}

class DisconnectedMenuItemComponent extends Component {
  render () {
    const { label } = this.props
    const icon =
      label === 'server-error'
        ? (
        <CloudOffIcon sx={disconnectItemStyle.icon} />
          )
        : (
        <WifiOffIcon sx={disconnectItemStyle.icon} />
          )
    return (
      <Box sx={disconnectItemStyle.wrapper}>
        {icon}
        <TranslatableTypography sx={disconnectItemStyle.typography}>
          {`${label}-help`}
        </TranslatableTypography>
      </Box>
    )
  }
}
const DisconnectedMenuItem = withTranslation(
  connect(mapStateToProps)(DisconnectedMenuItemComponent)
)
export { Disconnected, DisconnectedMenuItem, StatelessDisconnected }
