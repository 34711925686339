import { Link, Typography } from '@mui/material'
import { Component } from 'react'
import { withTranslation } from '../../../language.js'

const style = {
  text: {
    fontWeight: 600
  },
  link: {
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center'
  }
}

class BreadcrumbLinkItemBase extends Component {
  render () {
    return (
      <Link
        underline="hover"
        sx={style.link}
        color="inherit"
        href={this.props.href}
      >
        {this.props.i18nEngine.t(this.props.i18n)}
      </Link>
    )
  }
}

class BreadcrumbLastItemBase extends Component {
  render () {
    return (
      <Typography sx={style.text}>
        {this.props.i18nEngine.t(this.props.i18n)}
      </Typography>
    )
  }
}

const BreadcrumbLink = withTranslation(BreadcrumbLinkItemBase)
const BreadcrumbLast = withTranslation(BreadcrumbLastItemBase)

export { BreadcrumbLast, BreadcrumbLink }
