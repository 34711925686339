import { Component, useEffect } from 'react'

import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import { selectNavigation } from '../state/navigation'
import store from '../state/store'

import {
  AllAchievements,
  UserAchievements
} from './Achievements'

import {
  NotificationsConinfoMessages,
  NotificationsHome
} from './Notifications'

import {
  EventsAssigned,
  EventsFavorite,
  EventsHome
} from './Events'

import {
  YapsHome
} from './Yaps'

import { MapHome } from './Map'

import { ConinfoBar, ConinfoMessages, ConinfoPhotos } from './Coninfo'

import { VotingHome } from './Voting'

import { AuthError, Login, Logout, NotRegisteredError } from './Login'

import {
  AllWidgetsPage,
  ContainersWidgetsPage,
  DataCardsWidgetsPage,
  MainWidgetsPage,
  MapWidgetsPage,
  VotingSelectionWidgetsPage
} from './Widgets.js'

import { fetchAchievements, fetchUserAchievements } from '../api/achievements'
import {
  fetchConinfoBar,
  fetchConinfoMessages,
  fetchConinfoPhotos
} from '../api/coninfo'
import { fetchEvents, fetchUserEvents } from '../api/events'
import { fetchMap } from '../api/map'
import { fetchNotifications } from '../api/notifications'
import { fetchVotingProfiles } from '../api/voting'
import { fetchYaps } from '../api/yaps'

import { useSelector } from 'react-redux'
import { requestWithUserAuth } from '../api/auth'
import { fetchConfig } from '../api/config'
import { setLanguageDataRefresh } from '../state/lang'
import { isDefined, notDefined } from '../utils'

class Routing extends Component {
  render () {
    return (
      <Routes>
        <Route exact path="/" element={<Navigate to="/notifications" />} />

        <Route path="achievements">
          <Route index element={<AllAchievements />} />
          <Route path="user" element={<UserAchievements />} />
        </Route>

        <Route path="notifications">
          <Route index element={<NotificationsHome />} />
          <Route path="coninfo" element={<NotificationsConinfoMessages />} />
          <Route path="*" element={<NotificationsHome />} />
        </Route>

        <Route path="yaps">
          <Route index element={<YapsHome />} />
        </Route>

        <Route path="events">
          <Route index element={<EventsHome />} />
          <Route path="favorite" element={<EventsFavorite />} />
          <Route path="assigned" element={<EventsAssigned />} />
        </Route>

        <Route path="map">
          <Route index element={<MapHome />} />
        </Route>

        <Route path="voting">
          <Route index element={<VotingHome />} />
        </Route>

        <Route path="widgets">
          <Route index element={<AllWidgetsPage />} />
          <Route path="main" element={<MainWidgetsPage />} />
          <Route path="cards" element={<DataCardsWidgetsPage />} />
          <Route path="voting" element={<VotingSelectionWidgetsPage />} />
          <Route path="containers" element={<ContainersWidgetsPage />} />
          <Route path="map" element={<MapWidgetsPage />} />
        </Route>

        <Route path="login">
          <Route index element={<Login />} />
        </Route>

        <Route path="authError">
          <Route index element={<AuthError />} />
        </Route>

        <Route path="notRegistered">
          <Route index element={<NotRegisteredError />} />
        </Route>

        <Route path="coninfo">
          <Route index element={<ConinfoMessages />} />
          <Route path="messages" element={<ConinfoMessages />} />
          <Route path="photos" element={<ConinfoPhotos />} />
          <Route path="bar" element={<ConinfoBar />} />
        </Route>

        <Route path="logout">
          <Route index element={<Logout />} />
        </Route>
      </Routes>
    )
  }
}

const fetchRoute = {
  '/notifications': {
    navigation: { footerIndex: 0 },
    fetcher: (config) => fetchNotifications(config)
  },
  '/notifications/coninfo': {
    navigation: { footerIndex: 0 },
    fetcher: (config, auth, language) => fetchConinfoMessages(config, language)
  },
  '/events': {
    navigation: { footerIndex: 1 },
    fetcher: (config, auth, language) =>
      requestWithUserAuth(
        config,
        auth,
        (auth) => fetchUserEvents(config, auth, language),
        () => fetchEvents(config)
      )
  },
  '/events/favorite': {
    navigation: { footerIndex: 1 },
    fetcher: (config, auth, language) =>
      requestWithUserAuth(
        config,
        auth,
        (auth) => fetchUserEvents(config, auth, language),
        () => fetchEvents(config)
      )
  },
  '/events/assigned': {
    navigation: { footerIndex: 1 },
    fetcher: (config, auth, language) =>
      requestWithUserAuth(
        config,
        auth,
        (auth) => fetchUserEvents(config, auth, language),
        () => fetchEvents(config)
      )
  },
  '/map': {
    navigation: { footerIndex: 2 },
    fetcher: (config) => fetchMap(config)
  },
  '/coninfo': {
    navigation: { footerIndex: 3 },
    fetcher: (config, auth, language) => fetchConinfoMessages(config, language)
  },
  '/coninfo/messages': {
    navigation: { footerIndex: 3 },
    fetcher: (config, auth, language) => fetchConinfoMessages(config, language)
  },
  '/coninfo/photos': {
    navigation: { footerIndex: 3 },
    fetcher: (config) => fetchConinfoPhotos(config)
  },
  '/coninfo/bar': {
    navigation: { footerIndex: 3 },
    fetcher: (config, auth, language) => fetchConinfoBar(config, language)
  },
  '/achievements': {
    navigation: {},
    fetcher: (config, auth) => {
      fetchAchievements(config)
      requestWithUserAuth(config, auth, (auth) =>
        fetchUserAchievements(config, auth)
      )
    }
  },
  '/achievements/user': {
    navigation: {},
    fetcher: (config, auth) => {
      requestWithUserAuth(config, auth, (auth) =>
        fetchUserAchievements(config, auth)
      )
    }
  },
  '/yaps': {
    navigation: { footerIndex: 0 },
    fetcher: (config, auth, language) =>
      requestWithUserAuth(config, auth, (auth) =>
        fetchYaps(config, auth, 0, 30)
      )
  },
  '/voting': {
    navigation: {},
    fetcher: (config, auth) =>
      requestWithUserAuth(config, auth, (auth) =>
        fetchVotingProfiles(config, auth)
      )
  }
}

/* eslint-disable react/display-name */
/* TODO: Anonymous function naming */
const withAPICommunication = (Routing) => (props) => {
  const location = useLocation()
  const config = useSelector((state) => state.config)
  const auth = useSelector((state) => state.auth)
  const navigation = useSelector((state) => state.navigation)
  const language = useSelector((state) => state.language)

  useEffect(() => {
    try {
      const path = location.pathname

      const { lastLocation } = navigation
      const shouldTransitionAndFetch =
        notDefined(lastLocation) || path !== lastLocation || language.refresh

      if (!shouldTransitionAndFetch) {
        return
      }

      fetchConfig()
      let fetch = fetchRoute[path]
      const parts = path.split('/')
      for (let i = parts.length; i > 0; i--) {
        const key = parts.slice(0, i).join('/')
        fetch = fetchRoute[key]
        if (isDefined(fetch)) {
          break
        }
      }
      if (isDefined(fetch)) {
        if (isDefined(fetch.fetcher)) {
          try {
            fetch.fetcher(config.config, auth, language.active)
          } catch (error) {
            console.error(error)
            store.dispatch(
              selectNavigation({
                footerIndex: -1,
                path: '/error'
              })
            )
          }
        }

        if (isDefined(fetch.navigation)) {
          store.dispatch(selectNavigation({ ...fetch.navigation, path }))
          store.dispatch(setLanguageDataRefresh({ refresh: false }))
        } else {
          store.dispatch(selectNavigation({ footerIndex: -1, path }))
          store.dispatch(setLanguageDataRefresh({ refresh: false }))
        }
      } else {
        store.dispatch(selectNavigation({ footerIndex: -1, path }))
        store.dispatch(setLanguageDataRefresh({ refresh: false }))
      }
    } catch (error) {
      console.error(error)
      store.dispatch(selectNavigation({ footerIndex: -1, path: '/error' }))
      store.dispatch(setLanguageDataRefresh({ refresh: false }))
    }
  }, [location, config, auth, navigation, language])

  return <Routing />
}

export default withAPICommunication(Routing)
