import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { Messages } from './components/coninfo'
import { Browser, Menu } from './components/notifications'

export const NotificationsHome = () => {
  const notifications = useSelector((state) => state.notifications)
  const { data, categories, category } = notifications
  return (
    <Box>
      <Menu categories={categories} category={category}/>
      <Browser data={data} category={category}/>
    </Box>
  )
}

export const NotificationsConinfoMessages = () => {
  const notifications = useSelector((state) => state.notifications)
  const { categories, category } = notifications
  const coninfoMessages = useSelector((state) => state.coninfoMessages)
  const { data } = coninfoMessages
  return (
    <Box>
      <Menu categories={categories} category={category}/>
      <Messages data={data} />
    </Box>
  )
}
