import { createSlice } from '@reduxjs/toolkit'

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const initialState = {
  data: [],
  error: '',
  checksum: '',
  updated: ''
}

const bar = createSlice({
  name: 'consite_bar',
  initialState,
  reducers: {
    fetchConsiteBarSuccess (state, action) {
      const { data, checksum, generated } = action.payload
      return {
        ...state,
        data,
        checksum,
        updated: generated,
        error: ''
      }
    },
    fetchConsiteBarError (state, action) {
      const { detail } = action.payload

      return {
        ...state,
        error: detail
      }
    }
  }
})

const persistConfig = {
  key: 'consite_bar',
  storage
}

export const { fetchConsiteBarSuccess, fetchConsiteBarError } = bar.actions
export const ConinfoBarReducer = persistReducer(persistConfig, bar.reducer)
