import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'
import { isDefined } from '../../../utils'

const style = {
  position: 'relative',
  width: '40px',
  height: '40px',
  '& .companion-countdown-progress': {
  },
  '& .companion-countdown-label': {
    position: 'absolute',
    top: '10px',
    left: '0px',
    textAlign: 'center',
    width: '40px',
    height: '20px',
    fontSize: '12px',
    display: 'inline-block'
  }
}

export const CountDown = (props) => {
  const { remaining, onTrigger } = props
  const [seconds, setSeconds] = useState(remaining)

  useEffect(() => {
    const id = setInterval(() => {
      setSeconds((previous) => previous - 1)
      if (seconds <= 0 && isDefined(onTrigger)) {
        onTrigger()
      }
    }, 1000)
    return () => {
      clearInterval(id)
    }
  }, [seconds])

  const secondsLabel = `${seconds} s`
  const percent = Math.floor((seconds / remaining) * 100)

  return (
    <Box className="companion-countdown" sx={style}>
      <CircularProgress className="companion-countdown-progress" variant="determinate" value={percent} />
      <Typography className="companion-countdown-label" variant="caption" color="text.secondary">{secondsLabel}</Typography>
    </Box>
  )
}
