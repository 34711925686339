import { Component } from 'react'
import { connect } from 'react-redux'

import { Box } from '@mui/material'
import { Map, NoData } from './components/widgets'

import { changeSelection } from '../state/map'
import { mapDataPropsProvider, notDefined, shouldUpdate } from '../utils'

class MapHomeComponent extends Component {
  shouldComponentUpdate (nextProps) {
    return (
      shouldUpdate(this.props, nextProps) ||
      nextProps.selected !== this.props.selected
    )
  }

  render () {
    const { data, loading, checksum, selected, onSelect } = this.props

    if (loading && notDefined(data)) {
      return undefined
    }

    if (notDefined(data)) {
      return <NoData />
    }

    return (
      <Box sx={{ padding: '5px' }}>
        <Map
          data={data}
          checksum={checksum}
          selected={selected}
          onSelect={onSelect}
        />
      </Box>
    )
  }
}

const mapMapProps = function (state) {
  const { data, checksum, selected } = state.map
  return {
    ...mapDataPropsProvider(data, checksum, state),
    selected
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSelect: (selected) => dispatch(changeSelection({ selected }))
  }
}

const MapHome = connect(mapMapProps, mapDispatchToProps)(MapHomeComponent)

export { MapHome }
