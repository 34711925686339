import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import { Provider as StoreProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/es/integration/react'
import { persistor, store } from './state/store'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { AuthorizationProvider } from './view/components/Authorization'

import App from './App'
import './index.css'
import './language'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <StoreProvider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AuthorizationProvider>
        <StrictMode>
          <App />
        </StrictMode>
      </AuthorizationProvider>
    </PersistGate>
  </StoreProvider>
)

serviceWorkerRegistration.unregister()
reportWebVitals()
