import TrackChangesIcon from '@mui/icons-material/TrackChanges'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { AuthorizationGate } from './components/Authorization'
import { Browser, Menu } from './components/achievements'

const AllAchievements = () => {
  const data = useSelector((state) => state?.achievements?.all?.values)
  const navigation = useSelector((state) => state.navigation)

  return (
    <Box>
      <Menu />
      <Browser
        label='achievements-label'
        noData={{
          icon: <TrackChangesIcon className='get-achievements-icon'/>
        }}
        data={data}
        {...navigation}
      />
    </Box>
  )
}

const UserAchievements = () => {
  const data = useSelector((state) => state?.achievements?.user?.values)
  const navigation = useSelector((state) => state.navigation)

  return (
    <AuthorizationGate>
      <Box>
        <Menu />
        <Browser
          label='user-achievements-label'
          noData={{
            label: 'nothing here - achievements',
            icon: <TrackChangesIcon className='get-achievements-icon'/>
          }}
          data={data}
          {...navigation}
        />
      </Box>
    </AuthorizationGate>
  )
}

export { AllAchievements, UserAchievements }
