/**
 * React, react redux and react localization imports
 */
import DeleteForever from '@mui/icons-material/DeleteForever'
import Replay from '@mui/icons-material/Replay'
import { Box, Button, Grid, Modal, Paper } from '@mui/material'
import { Component } from 'react'
import { withTranslation } from '../../../language'
import { pallete } from '../../../theme'
import { TranslatableTypography } from './Typography'

const style = {
  modal: {
    wrapper: {
      padding: '15px',
      height: '100px',
      marginTop: 'calc(50% - 100px)',
      width: '80%',
      marginLeft: '10%'
    },
    buttonWrapper: {
      textAlign: 'center'
    },
    yes: {
      background: pallete.paperColor,
      color: pallete.headerColor,
      fontWeight: 600,
      boxShadow: 'none',
      '&:hover': { background: pallete.paperColor },
      '& p': { fontWeight: 600 },
      borderRadius: '25px'
    },
    no: {
      background: pallete.paperColor,
      color: pallete.headerColor,
      fontWeight: 600,
      boxShadow: 'none',
      '&:hover': { background: pallete.headerColor },
      '& p': { fontWeight: 600 },
      borderRadius: '25px'
    }
  },
  button: {
    background: pallete.paperColor,
    color: pallete.headerColor,
    fontWeight: 600,
    boxShadow: 'none',
    '&:hover': { background: pallete.paperColor },
    '& p': { fontWeight: 600, fontSize: '12px' },
    borderRadius: '25px'
  }
}

class ResetApplicationComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false
    }
  }

  onReset () {
    localStorage.clear()
    window.location.reload()
  }

  render () {
    return (
      <Box>
        <Modal
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
        >
          <Paper sx={style.modal.wrapper}>
            <Grid container>
              <Grid xs={12} item>
                <TranslatableTypography>
                  Are you sure you want to reset application and reload data?
                </TranslatableTypography>
              </Grid>
              <Grid xs={6} item sx={style.modal.buttonWrapper}>
                <Button sx={style.modal.yes} onClick={() => this.onReset()}>
                  <DeleteForever />
                  <TranslatableTypography>Yes</TranslatableTypography>
                </Button>
              </Grid>
              <Grid xs={6} item sx={style.modal.buttonWrapper}>
                <Button
                  sx={style.modal.no}
                  onClick={() => this.setState({ open: false })}
                >
                  <TranslatableTypography>No</TranslatableTypography>
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Modal>
        <Button sx={style.button} onClick={() => this.setState({ open: true })}>
          <Replay />
          <TranslatableTypography>Reset application cache</TranslatableTypography>
        </Button>
      </Box>
    )
  }
}

export const ResetApplication = withTranslation(ResetApplicationComponent)
