/**
 * Material imports
 */

import ErrorIcon from '@mui/icons-material/Error'

/**
 * Relative exports to root directory"editor.codeActionsOnSave": {
  "source.organizeImports": true
}
 */
import { Box, Paper } from '@mui/material'
import { pallete } from '../../../theme'
import { TranslatableTypography } from './Typography'

const noDataStyle = {
  box: {
    width: '100%',
    padding: '5px'
  },
  container: {
    width: '100%',
    height: '100%',
    position: 'relative',
    border: `1px dashed ${pallete.headerColor}`,
    filter: `drop-shadow(0px 0px 3px ${pallete.headerBrightColor})`
  },
  wrapper: {
    padding: '35px',
    textAlign: 'center',
    '& .error-icon': { fontSize: 120, color: pallete.headerColor }
  },
  errorIcon: { fontSize: 120, color: pallete.headerColor },
  typography: { fontWeight: 600, color: pallete.headerColor }
}

const NoData = (props) => {
  const label = props?.label ?? 'No data available'
  const icon = props?.icon ?? (<ErrorIcon className='error-icon'/>)

  return (
    <Box sx={noDataStyle.box}>
      <Paper sx={noDataStyle.container}>
        <Box sx={noDataStyle.wrapper}>
          {icon}
          <TranslatableTypography sx={noDataStyle.typography}>
            {label}
          </TranslatableTypography>
        </Box>
      </Paper>
    </Box>
  )
}

export { NoData }
