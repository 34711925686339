import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { Bar, Messages } from './components/coninfo'

export const ConinfoMessages = () => {
  const coninfoMessages = useSelector((state) => state.coninfoMessages)
  const { data } = coninfoMessages
  return (
    <Box>
      <Messages data={data} />
    </Box>
  )
}

export const ConinfoPhotos = () => {
  return (
    <Box>
    </Box>
  )
}

export const ConinfoBar = () => {
  const coninfoBar = useSelector((state) => state.coninfoBar)
  const { data } = coninfoBar

  return (
    <Box>
      <Bar data={data} />
    </Box>
  )
}
