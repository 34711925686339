import { createTheme } from '@mui/material/styles'

const pallete = {
  headerColor: 'rgb(223,120,24)',
  headerBrightColor: 'rgb(248,170,97)',
  headerDimColor: 'rgb(150,85,24)',
  headerHighlightColor: 'rgb(223,120,24)',
  headerTransparentColor: 'rgb(223,120,24, 0.6)',
  headerTransparentThinColor: 'rgb(223,120,24, 0.2)',
  paperColor: 'rgb(255, 255, 255)',
  paperColorTransparent: 'rgba(255, 255, 255, 0.6)',
  paperColorDim: 'rgb(252, 243, 241)',
  paperColorDim2: 'rgb(249, 231, 227)',
  transparentAlfa: 'rgba(250, 250, 250, 0.3)',
  textColor: 'rgb(30, 30, 30)',
  successColorRGB: 'rgb(0, 161, 48)',
  errorColorRGB: 'rgb(207, 45, 45)'
}

const sizing = {
  header: {
    height: 75
  },

  subHeader: {
    height: 35
  },

  footer: {
    height: 55
  },

  pading: {
    container: 5
  },

  scrollbar: {
    width: 8,
    height: 8
  }
}

const theme = createTheme({
  typography: {
    fontFamily: ['Open Sans', 'sans-serif'].join(',')
  },
  palette: {
    primary: {
      main: pallete.headerColor,
      success: pallete.successColorRGB
    },
    secondary: {
      main: pallete.paperColor
    },
    text: {
      secondary: pallete.textColor
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: `${pallete.headerColor} ${pallete.paperColor}`,
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            backgroundColor: pallete.paperColor,
            width: `${sizing.scrollbar.width}px`,
            height: `${sizing.scrollbar.height}px`
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: '2px',
            backgroundColor: pallete.headerColor,
            overflow: 'hidden'
          },
          '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus':
            {
              backgroundColor: pallete.headerColor
            },
          '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active':
            {
              backgroundColor: pallete.headerColor
            },
          '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover':
            {
              backgroundColor: pallete.headerColor
            },
          '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
            backgroundColor: pallete.paperColor
          }
        }
      }
    },
    MuiIconButton: {
      variants: [
        {
          props: { variant: 'avatar' },
          style: {
            textTransform: 'none',
            marginLeft: 'auto',
            padding: '5px',
            paddingRight: '10px'
          }
        }
      ]
    }
  }
})

export { pallete, sizing, theme }
