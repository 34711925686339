import { Component } from 'react'

import Cookies from 'js-cookie'
import jwt_decode from 'jwt-decode'
import { connect } from 'react-redux'
import { auth } from '../../state/auth'
import store from '../../state/store'
import { isDefined, isTokenValid } from '../../utils'
import { AutomaticallyLogoutedDialog, NoAccessDialog } from './widgets/Login'

class AuthorizationProviderComponent extends Component {
  componentDidMount () {
    const authorizationTokenCookie = Cookies.get('X_CAT_TOKEN')
    let profile = {}

    if (isDefined(authorizationTokenCookie)) {
      Cookies.remove('X_CAT_TOKEN')
      if (isTokenValid(authorizationTokenCookie)) {
        const { nick, user, attendee, avatar_url } = jwt_decode(
          authorizationTokenCookie
        )
        profile = {
          nick,
          user,
          attendee,
          avatar_url
        }

        if (isDefined(this.props.onAuth)) {
          this.props.onAuth(authorizationTokenCookie, profile)
        }
      }
    }
  }

  render () {
    const { children } = this.props
    return children
  }
}

const mapDispatchToProps = () => {
  return {
    onAuth: (authToken, profile) => {
      store.dispatch(auth({ authToken, profile }))
    }
  }
}

export const AuthorizationProvider = connect(() => {
  return {}
}, mapDispatchToProps)(AuthorizationProviderComponent)

class AuthorizationGateComponent extends Component {
  render () {
    if (this.props.expired) {
      return <AutomaticallyLogoutedDialog />
    } else if (!this.props.authorized) {
      return <NoAccessDialog />
    } else {
      const { children } = this.props
      return children
    }
  }
}

const mapStateToProps = (state) => {
  const { auth } = state
  const authorized = isTokenValid(auth?.authToken) && isDefined(auth.profile)
  return { expired: auth.expired, authorized }
}

export const AuthorizationGate = connect(mapStateToProps)(
  AuthorizationGateComponent
)
