import { SvgIcon } from '@mui/material'
import { Box } from '@mui/system'
import { ReactComponent as Path } from './cz.svg'

export default function CZIcon () {
  return (
    <Box>
      <SvgIcon style={{ opacity: '1', width: '20px' }} fontSize="small">
        <Path />
      </SvgIcon>
    </Box>
  )
}
