import { Component, PureComponent } from 'react'

import { connect } from 'react-redux'

import AddIcon from '@mui/icons-material/Add'
import CancelIcon from '@mui/icons-material/Cancel'
import GridViewIcon from '@mui/icons-material/GridView'
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded'
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded'
import PetsIcon from '@mui/icons-material/Pets'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import RemoveIcon from '@mui/icons-material/Remove'
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  LinearProgress,
  Modal,
  Paper,
  Switch,
  Typography
} from '@mui/material'

import { requestWithUserAuth } from '../../../api/auth.js'
import { save } from '../../../api/voting.js'
import { withTranslation } from '../../../language.js'
import { cancel, selectProfile, vote } from '../../../state/voting'
import { pallete } from '../../../theme.js'
import {
  isDefined,
  mapDataPropsProvider,
  notDefined,
  shouldUpdate
} from '../../../utils'
import { TranslatableButton } from './Button.js'
import { TranslatableTypography } from './Typography'

class VotingHelpComponent extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      open: false
    }
  }

  handleOpen () {
    this.setState({ ...this.state, open: true })
  }

  handleClose () {
    this.setState({ ...this.state, open: false })
  }

  render () {
    return (
      <Box sx={{ display: 'inline' }}>
        <Box
          sx={{
            display: 'inline',
            '& button:hover': {
              backgroundColor: pallete.headerColor
            }
          }}
        >
          <IconButton
            sx={{ background: pallete.headerColor, padding: '4px' }}
            onClick={() => this.handleOpen()}
          >
            <QuestionMarkIcon
              sx={{
                color: pallete.paperColor,
                width: '20px',
                height: '20px'
              }}
            />
          </IconButton>
        </Box>

        <Modal
          open={this.state.open}
          onClose={() => this.handleClose()}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          sx={{ padding: '20px' }}
        >
          <Paper
            sx={{
              padding: '10px',
              marginTop: '80px',
              height: '300px',
              filter: `drop-shadow(0px 0px 3px  ${pallete.headerBrightColor})`
            }}
          >
            <TranslatableTypography>voting-help</TranslatableTypography>
          </Paper>
        </Modal>
      </Box>
    )
  }
}

export const VotingHelp = VotingHelpComponent

const votingCardStyle = {
  card: {
    width: '100%',
    height: '100%',
    position: 'relative',
    '& .voting-avatar': {
      visibility: 'hidden'
    },
    '&.loaded .voting-avatar': {
      visibility: 'visible'
    }
  },
  media: {
    width: '100%',
    height: '100%',
    background: pallete.headerBrightColor,
    color: pallete.paperColor
  },
  defaultAvatar: {
    width: '100%',
    height: '100%',
    '& .companion-voting-default-avatar': {
      width: '100%',
      height: '100%',
      background: pallete.headerBrightColor,
      color: pallete.paperColor,
      opacity: '0.3'
    },
    '& .companion-voting-default-avatar-background': {
      width: '100%',
      height: '100%',
      background: 'white'
    }
  },
  content: {
    width: '100%',
    position: 'absolute',
    bottom: '0px',
    padding: '5px !important',
    background: pallete.headerColor,
    color: pallete.paperColor,
    textAlign: 'center',
    maxHeight: '64px',
    '& p': {
      fontSize: '14px',
      lineHeight: '14px',
      wordWrap: 'break-word'
    }
  },
  score: {
    position: 'relative',
    height: '1px',
    background: `${pallete.headerColor}`
  },
  scoreItemsWrapper: {
    position: 'absolute',
    textAlign: 'right',
    paddingTop: '5px',
    width: '100%'
  },
  scoreItem: {
    background: `${pallete.headerColor}`,
    color: `${pallete.paperColor}`,
    display: 'inline-block',
    padding: '5px',
    borderRadius: '50%',
    height: '25px',
    width: '28px',
    textAlign: 'center',
    lineHeight: '18px',
    fontWeight: 600,
    marginRight: '5px'
  }
}

const sortVoting = (v1, v2) => {
  if (v1.campaign > v2.campaign) {
    return 1
  }
  if (v1.campaign < v2.campaign) {
    return -1
  }
  return 0
}

class VotingCardScore extends Component {
  render () {
    if (this.props.showScore !== 'true') {
      return <Box sx={votingCardStyle.score} />
    }

    const tickets = this.props.data.reduce((accumulator, voting) => {
      return accumulator + voting.tickets
    }, 0)

    if (tickets <= 0) {
      return <Box sx={votingCardStyle.score} />
    }

    const items = [...this.props.data].sort(sortVoting).map((voting) => {
      return (
        <Box key={`${voting.campaign}`} sx={votingCardStyle.scoreItem}>
          {voting?.tickets}
        </Box>
      )
    })
    return (
      <Box sx={votingCardStyle.score}>
        <Box sx={votingCardStyle.scoreItemsWrapper}>{items}</Box>
      </Box>
    )
  }
}

class VotingCardComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loaded: false,
      previousAvatar: undefined,
      error: false
    }
  }

  render () {
    const customSx = isDefined(this.props.sx)
      ? this.props.sx
      : votingCardStyle.card

    const { avatar_url, name } = this.props.data
    const { loaded, error } = this.state
    const changed = this.state.previousAvatar !== avatar_url
    const loader = changed
      ? (<LinearProgress
        className="voting-avatar-loader"
        />)
      : undefined
    const loadedClassName = loaded && !error ? 'loaded' : ''
    const noAvatar = (changed || (loaded && error))
      ? (<Box sx={votingCardStyle.defaultAvatar}>
          <CardMedia
            component="img"
            className="companion-voting-default-avatar"
            image="/no_avatar.jpg"
          />
          <Box className="companion-voting-default-avatar-background" />
        </Box>)
      : undefined

    const loading = (this?.props?.loading ?? 'lazy')

    return (
      <Card
        sx={customSx}
        onClick={this.props.onClick}
        className={`${loadedClassName}`}
      >
        <VotingCardScore
          data={this.props.data.voting}
          showScore={this.props.showScore}
        />

        {loader}

        {noAvatar}

        <CardMedia
          component="img"
          className="voting-avatar"
          sx={{ ...votingCardStyle.media }}
          image={this.props.data.avatar_url}
          alt={name}
          loading={loading}
          onError={({ currentTarget }) => {
            this.setState({
              loaded: true,
              error: true,
              previousAvatar: avatar_url
            })
          }}
          onLoad={({ currentTarget }) => {
            this.setState({
              loaded: true,
              error: false,
              previousAvatar: avatar_url
            })
          }}
        />

        <CardContent sx={votingCardStyle.content}>
          <TranslatableTypography>
            {this.props.data.name}
          </TranslatableTypography>
        </CardContent>
      </Card>
    )
  }
}

export const VotingCard = VotingCardComponent

const votingProfilesStyle = {
  openHeader: {
    wrapper: {
      display: 'inline',
      marginRight: '5px',
      '& button:hover': { backgroundColor: pallete.headerColor }
    },
    button: { background: pallete.headerColor, padding: '4px' },
    icon: { color: pallete.paperColor, width: '20px', height: '20px' },
    label: {
      fontWeight: '600',
      verticalAlign: 'middle',
      display: 'inline',
      marginLeft: '5px'
    },
    switchLabel: {
      display: 'inline-block',
      color: pallete.paperColor
    },
    switch: {
      '& .MuiSwitch-switchBase': {
        '&.Mui-checked +.MuiSwitch-track': {
          background: pallete.headerBrightColor
        },
        '&.Mui-checked .MuiSwitch-thumb': {
          background: pallete.headerBrightColor
        }
      }
    }
  },
  closeHeader: {
    button: {
      display: 'block',
      width: '100%',
      borderRadius: '0px',
      textAlign: 'right',
      padding: '5px',
      height: '35px',
      lineHeight: '25px',
      marginTop: '5px',
      background: pallete.headerColor
    },
    icon: { color: pallete.paperColor, width: '25px', height: '25px' }
  },
  container: {
    width: '100%',
    height: 'calc(100% - 35px)',
    overflowY: 'scroll',
    padding: '10px',
    textAlign: 'center'
  },
  profile: {
    card: {
      '@media (max-width: 539px)': {
        width: '30%',
        margin: '5px'
      },
      '@media (min-width: 540px)': {
        width: '160px',
        margin: '5px'
      },
      cursor: 'pointer',
      position: 'relative',
      height: '160px',
      display: 'inline-block',
      '& .MuiCardMedia-img': { height: '160px' },
      '& .voting-avatar': {
        visibility: 'hidden'
      },
      '&.loaded .voting-avatar': {
        visibility: 'visible'
      }
    }
  }
}

class VotingProfilesComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false,
      showOnlySelected: false
    }
  }

  handleOpen () {
    this.setState({ ...this.state, open: true })
  }

  handleClose () {
    this.setState({ ...this.state, open: false })
  }

  handleShowOnlySelected (e, selected) {
    this.setState({ ...this.state, showOnlySelected: selected })
    const docs = document.getElementsByClassName(
      'voting-profiles-modal-scroll'
    )
    if (docs.length) {
      docs[0].scrollTo({ top: 0 })
    }
  }

  shouldComponentUpdate (nextProps, nexState) {
    return this.state !== nexState || shouldUpdate(this.props, nextProps)
  }

  handleSelect (index, profile) {
    this.handleClose()
    if (!notDefined(this.props.onProfileSelect)) {
      this.props.onProfileSelect(index, profile)
    }
  }

  render () {
    if (notDefined(this.props.data)) {
      return undefined
    }

    const { allProfiles } = this.props.data
    const showOnlySelected = this?.state?.showOnlySelected
    const handler = this

    const items = allProfiles.reduce(function (filtered, profile, i) {
      const selectedCondition =
        (profile.voting.length > 0 && showOnlySelected) || !showOnlySelected
      if (selectedCondition) {
        filtered.push(
          <VotingCard
            key={i}
            highlighted={profile.voting.length > 0}
            data={profile}
            showScore="true"
            sx={votingProfilesStyle.profile.card}
            onClick={() => handler.handleSelect(i, profile)}
          />
        )
      }
      return filtered
    }, [])

    return (
      <Box sx={{ display: 'inline' }}>
        <Box sx={votingProfilesStyle.openHeader.wrapper}>
          <IconButton
            sx={votingProfilesStyle.openHeader.button}
            onClick={() => this.handleOpen()}
          >
            <GridViewIcon sx={votingProfilesStyle.openHeader.icon} />
          </IconButton>
        </Box>
        <Modal
          open={this.state.open}
          onClose={() => this.handleClose()}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={{ width: '100%', height: '100%' }}>
            <Grid container sx={{ background: pallete.headerColor }}>
              <Grid xs={9} sx={{ padding: '5px' }} item>
                <TranslatableTypography
                  sx={votingProfilesStyle.openHeader.switchLabel}
                >
                  Show votes only:
                </TranslatableTypography>
                <Switch
                  checked={showOnlySelected}
                  onChange={(e, value) => this.handleShowOnlySelected(e, value)}
                  sx={votingProfilesStyle.openHeader.switch}
                />
              </Grid>
              <Grid xs={3} item>
                <IconButton
                  sx={votingProfilesStyle.closeHeader.button}
                  onClick={() => this.handleClose()}
                >
                  <CancelIcon sx={votingProfilesStyle.closeHeader.icon} />
                </IconButton>
              </Grid>
            </Grid>

            <Box
              sx={votingProfilesStyle.container}
              className="voting-profiles-modal-scroll"
            >
              {items}
            </Box>
          </Box>
        </Modal>
      </Box>
    )
  }
}

const mapProfilesDispatch = (dispatch) => {
  return {
    onProfileSelect: (index, profile) => {
      dispatch(selectProfile({ index, profile }))
    }
  }
}

const mapProfilesProps = function (state) {
  const { form, checksum } = state.voting
  return mapDataPropsProvider(form, checksum, state)
}

export const VotingProfiles = connect(
  mapProfilesProps,
  mapProfilesDispatch
)(VotingProfilesComponent)

const carouselControlsStyle = {
  wrapper: {
    '& button:hover': {
      backgroundColor: pallete.headerColor,
      opacity: '1!important'
    }
  },
  leftButton: {
    position: 'absolute',
    left: '5px',
    top: '5px',
    background: pallete.headerColor,
    color: pallete.paperColor
  },
  rightButton: {
    position: 'absolute',
    right: '5px',
    top: '5px',
    background: pallete.headerColor,
    color: pallete.paperColor
  }
}

class VotingCarouselControlsComponent extends PureComponent {
  render () {
    return (
      <Box sx={carouselControlsStyle.wrapper}>
        <IconButton
          sx={carouselControlsStyle.leftButton}
          onClick={this.props.onLeft}
        >
          <NavigateBeforeRoundedIcon />
        </IconButton>
        <IconButton
          sx={carouselControlsStyle.rightButton}
          onClick={this.props.onRight}
        >
          <NavigateNextRoundedIcon />
        </IconButton>
      </Box>
    )
  }
}

export const VotingCarouselControls = VotingCarouselControlsComponent

const carouselStyle = {
  container: { width: '100%', height: '200px', position: 'relative' },
  left: {
    position: 'absolute',
    top: '0px',
    left: '0',
    width: '40%',
    height: '100%',
    padding: '10px',
    paddingLeft: '0px'
  },
  middleLeft: {
    position: 'absolute',
    top: '0px',
    left: '10%',
    width: '40%',
    height: '100%',
    padding: '5px'
  },
  middle: {
    position: 'absolute',
    top: '0px',
    left: '30%',
    width: '40%',
    height: '100%'
  },
  middleRight: {
    position: 'absolute',
    top: '0px',
    right: '10%',
    width: '40%',
    height: '100%',
    padding: '5px'
  },
  right: {
    position: 'absolute',
    top: '0px',
    right: 0,
    width: '40%',
    height: '100%',
    padding: '10px',
    paddingRight: '0px'
  },
  controlLayer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0px'
  },
  controlWrapper: {
    position: 'absolute',
    top: '0px',
    left: '0',
    width: '100%',
    height: '50px'
  }
}

class VotingCarouselComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      touched: null,
      lastPosition: null
    }
  }

  shouldComponentUpdate (nextProps, nexState) {
    return (
      shouldUpdate(this.props, nextProps) ||
      this.props.data.selectedIndex !== nextProps.data.selectedIndex
    )
  }

  convertSelectedToIndex (selected) {
    const { allProfiles } = this.props.data
    const shift = selected - 2
    return shift < 0 ? allProfiles.length + shift : shift
  }

  convertIndexToSelected (index) {
    const { allProfiles } = this.props.data
    const shift = index + 2
    return shift > this.props.data.length
      ? 0 + (shift - allProfiles.length)
      : shift
  }

  getRelativeOffseIndex (index, length, offset) {
    if (index + offset < 0) {
      return index + offset + length
    } else if (index + offset >= length) {
      return index + offset - length
    } else {
      return index + offset
    }
  }

  getSlice (index) {
    const { allProfiles } = this.props.data
    const indexes = [
      this.getRelativeOffseIndex(index, allProfiles.length, -2),
      this.getRelativeOffseIndex(index, allProfiles.length, -1),
      this.getRelativeOffseIndex(index, allProfiles.length, 0),
      this.getRelativeOffseIndex(index, allProfiles.length, 1),
      this.getRelativeOffseIndex(index, allProfiles.length, 2)
    ]
    const slice = {
      left: allProfiles[indexes[0]],
      middleLeft: allProfiles[indexes[1]],
      middle: allProfiles[indexes[2]],
      middleRight: allProfiles[indexes[3]],
      right: allProfiles[indexes[4]]
    }
    return slice
  }

  onClick (e) {}

  onTouchStart (e) {
    this.setState({
      touched: e.targetTouches[0].clientX,
      lastPosition: null
    })
  }

  onTouchMove (e) {
    this.setState({
      ...this.state,
      lastPosition: e.targetTouches[0].clientX
    })
  }

  onTouchEnd (e) {
    const startX = this?.state?.touched
    const endX = this?.state?.lastPosition

    if (notDefined(startX) || notDefined(endX)) {
      return
    }

    const delta = endX - startX

    this.setState({
      touched: null,
      lastPosition: null
    })

    if (delta < 0) {
      this.onRight()
    } else {
      this.onLeft()
    }
  }

  onLeft () {
    if (notDefined(this.props.onProfileSelect)) return
    const { selectedIndex, allProfiles } = this.props.data
    const newSelectedIndex =
      selectedIndex - 1 < 0 ? allProfiles.length - 1 : selectedIndex - 1
    const profile = allProfiles[newSelectedIndex]
    this.props.onProfileSelect(newSelectedIndex, profile)
  }

  onRight () {
    if (notDefined(this.props.onProfileSelect)) return
    const { selectedIndex, allProfiles } = this.props.data
    const newSelectedIndex =
      selectedIndex + 1 >= allProfiles.length ? 0 : selectedIndex + 1
    const profile = allProfiles[newSelectedIndex]
    this.props.onProfileSelect(newSelectedIndex, profile)
  }

  render () {
    const { selectedIndex } = this.props.data
    const slice = this.getSlice(selectedIndex)
    return (
      <Box sx={carouselStyle.container}>
        <Box sx={carouselStyle.left}>
          <VotingCard data={slice.left} loading="eager"/>
        </Box>
        <Box sx={carouselStyle.middleLeft}>
          <VotingCard data={slice.middleLeft} loading="eager"/>
        </Box>
        <Box sx={carouselStyle.right}>
          <VotingCard data={slice.right} loading="eager"/>
        </Box>
        <Box sx={carouselStyle.middleRight}>
          <VotingCard data={slice.middleRight} loading="eager"/>
        </Box>
        <Box sx={carouselStyle.middle}>
          <VotingCard data={slice.middle} showScore="true" loading="eager"/>
        </Box>
        <Box
          sx={carouselStyle.controlLayer}
          onClick={(e) => this.onClick(e)}
          onTouchStart={(e) => this.onTouchStart(e)}
          onTouchMove={(e) => this.onTouchMove(e)}
          onTouchEnd={(e) => this.onTouchEnd(e)}
        />
        <Box sx={carouselStyle.controlWrapper}>
          <VotingCarouselControls
            onLeft={() => this.onLeft()}
            onRight={() => this.onRight()}
          />
        </Box>
      </Box>
    )
  }
}

const mapVotingCarouselDispatch = (dispatch) => {
  return {
    onProfileSelect: (index, profile) =>
      dispatch(selectProfile({ index, profile }))
  }
}

const mapVotingCarouselProps = function (state) {
  const { checksum, form } = state.voting
  return mapDataPropsProvider(form, checksum, state)
}

export const VotingCarousel = connect(
  mapVotingCarouselProps,
  mapVotingCarouselDispatch
)(VotingCarouselComponent)

class MinusVote extends Component {
  render () {
    return (
      <IconButton
        onClick={this.props.onClick}
        sx={{
          ...this.props.sx,
          background: pallete.headerColor,
          borderRadius: '10px',
          '&:hover': { background: pallete.headerColor },
          padding: '0px'
        }}
      >
        <RemoveIcon
          sx={{
            color: pallete.paperColor,
            width: '100%',
            height: '100%'
          }}
        />
      </IconButton>
    )
  }
}

class PlusVote extends Component {
  render () {
    return (
      <IconButton
        onClick={this.props.onClick}
        sx={{
          ...this.props.sx,
          background: pallete.headerColor,
          borderRadius: '10px',
          '&:hover': { background: pallete.headerColor },
          padding: '0px'
        }}
      >
        <AddIcon
          sx={{
            color: pallete.paperColor,
            width: '100%',
            height: '100%'
          }}
        />
      </IconButton>
    )
  }
}

class SelectedVote extends Component {
  render () {
    return (
      <IconButton
        onClick={this.props.onClick}
        sx={{
          ...this.props.sx,
          background: pallete.headerColor,
          borderRadius: '0px',
          '&:hover': { background: pallete.headerColor }
        }}
      >
        <PetsIcon
          sx={{
            color: pallete.paperColor,
            width: '100%',
            height: '100%'
          }}
        />
      </IconButton>
    )
  }
}

class ActiveVote extends Component {
  render () {
    return (
      <IconButton
        onClick={this.props.onClick}
        sx={{
          ...this.props.sx,
          background: pallete.headerTransparentColor,
          borderRadius: '0px',
          '&:hover': { background: pallete.headerTransparentColor }
        }}
      >
        <PetsIcon
          sx={{
            color: pallete.paperColor,
            width: '100%',
            height: '100%'
          }}
        />
      </IconButton>
    )
  }
}

class DisabledVote extends Component {
  render () {
    return (
      <IconButton
        sx={{
          ...this.props.sx,
          background: pallete.headerTransparentColor,
          borderRadius: '0px',
          '&:hover': { background: pallete.headerTransparentColor }
        }}
      >
        <PetsIcon
          sx={{
            color: pallete.paperColor,
            width: '100%',
            height: '100%'
          }}
        />
      </IconButton>
    )
  }
}

class VotingScoreComponent extends Component {
  onVote (campaignId, campaignName, actualVotes) {
    if (isDefined(this.props.onVote)) {
      this.props.onVote(campaignId, campaignName, actualVotes)
    }
  }

  render () {
    const {
      maxVotes,
      actualVotes,
      remainingVotes,
      campaignId,
      campaignTitle,
      campaignName
    } = this.props
    const active =
      maxVotes - actualVotes > remainingVotes
        ? remainingVotes
        : maxVotes - actualVotes
    const disabled =
      maxVotes - actualVotes >= remainingVotes
        ? maxVotes - actualVotes - remainingVotes
        : 0

    const width = 100 / maxVotes

    const voteHandler = (i) => {
      if (this.props.disabled) {
        return
      }

      this.onVote(campaignId, campaignName, i)
    }

    const addHandler = () => {
      if (this.props.disabled) {
        return
      }

      if (actualVotes + 1 <= maxVotes) {
        this.onVote(campaignId, campaignName, 1)
      }
    }

    const removeHandler = () => {
      if (this.props.disabled) {
        return
      }

      if (actualVotes - 1 >= 0) {
        this.onVote(campaignId, campaignName, -1)
      }
    }

    const buttonStyle = { width: `${width}%`, maxWidth: '50px' }

    const votes = []
    for (let i = actualVotes * -1; i < 0; i++) {
      votes.push(
        <SelectedVote
          key={`selected-${i}`}
          sx={buttonStyle}
          onClick={() => voteHandler(i)}
        />
      )
    }

    for (let i = 1; i <= active; i++) {
      votes.push(
        <ActiveVote
          key={`active-${i}`}
          sx={buttonStyle}
          onClick={() => voteHandler(i)}
        />
      )
    }

    for (let i = 1; i <= disabled; i++) {
      votes.push(<DisabledVote key={`disabled-${i}`} sx={buttonStyle} />)
    }

    return (
      <Box sx={{ ...this.props.sx, width: '100%', padding: '5px' }}>
        <Box>
          <TranslatableTypography
            data={campaignTitle}
            sx={{ color: pallete.headerColor, fontWeight: 600 }}
          />
        </Box>
        <Grid container>
          <Grid item xs={1.5} sx={{ padding: '5px' }}>
            <MinusVote onClick={removeHandler} />
          </Grid>
          <Grid item xs={9} sx={{ textAlign: 'center' }}>
            <Box sx={{ display: 'inline-block' }}>{votes}</Box>
          </Grid>
          <Grid item xs={1.5} sx={{ padding: '5px' }}>
            <PlusVote onClick={addHandler} />
          </Grid>
        </Grid>
      </Box>
    )
  }
}

export const VotingScore = VotingScoreComponent

const sortCampaign = (c1, c2) => {
  if (c1.campaign > c2.campaign) {
    return 1
  }
  if (c1.campaign < c2.campaign) {
    return -1
  }
  return 0
}

class VotingScoreCardComponent extends Component {
  render () {
    const { data } = this.props

    const {
      maxTicketsPerCampaign,
      remainingTickets,
      selectedProfile,
      allCampaigns
    } = data

    const assignedVotes = []

    if (isDefined(selectedProfile)) {
      selectedProfile.voting.forEach((element) => {
        assignedVotes[element.campaign] = element.tickets
      })
    }

    const sortedCampaigns = [...allCampaigns].sort(sortCampaign)
    const score = sortedCampaigns.map((campaign) => {
      const actualVotes = isDefined(assignedVotes[campaign.name])
        ? assignedVotes[campaign.name]
        : 0
      return (
        <VotingScore
          key={`key-${campaign.name}`}
          maxVotes={maxTicketsPerCampaign}
          actualVotes={actualVotes}
          remainingVotes={remainingTickets}
          campaignTitle={campaign.titles}
          campaignId={campaign.id}
          campaignName={campaign.name}
          onVote={this.props.onVote}
          disabled={this.props.disabled}
        />
      )
    })

    return <Box>{score}</Box>
  }
}

const mapVotingScoreCardDispatch = (dispatch) => {
  return {
    onVote: (campaignId, campaignName, actualVote) => {
      const payload = {
        campaignId,
        campaignName,
        actualVote
      }

      dispatch(vote(payload))
    }
  }
}

const mapVotingScoreCardProps = function (state) {
  const { checksum, form } = state.voting
  return mapDataPropsProvider(form, checksum, state)
}

export const VotingScoreCard = connect(
  mapVotingScoreCardProps,
  mapVotingScoreCardDispatch
)(VotingScoreCardComponent)

class Clock extends Component {
  render () {
    /* TODO: Add props type check and enable it in eslint */
    const { deadline, disabled } = this.props

    if (disabled || notDefined(deadline)) {
      return <Box />
    } else {
      return (
        <Box>
          <TranslatableTypography>
            You can still vote until:
          </TranslatableTypography>
          <Typography>{deadline}</Typography>
        </Box>
      )
    }
  }
}

class Cancel extends Component {
  onCancel () {
    if (isDefined(this.props.onCancel)) {
      this.props.onCancel()
    }
  }

  render () {
    return (
      <Box sx={{ display: 'inline-block', marginRight: '20px' }}>
        <TranslatableButton variant="filled" onClick={() => this.onCancel()}>
          cancel
        </TranslatableButton>
      </Box>
    )
  }
}

class Save extends Component {
  onSave () {
    if (isDefined(this.props.onSave)) {
      this.props.onSave()
    }
  }

  render () {
    return (
      <Box sx={{ display: 'inline-block' }}>
        <TranslatableButton variant="filled" onClick={() => this.onSave()}>
          save
        </TranslatableButton>
      </Box>
    )
  }
}

class Summary extends Component {
  render () {
    const { remainingTickets } = this.props
    let text = 'no-votes-remaining'
    let valueComponent
    if (remainingTickets > 0) {
      text = 'votes-remaining'
      valueComponent = (
        <Typography
          sx={{
            display: 'inline',
            paddingLeft: '3px',
            paddingRight: '3px',
            fontWeight: 800
          }}
        >
          : {remainingTickets}
        </Typography>
      )
    }

    return (
      <Box
        sx={{
          textAlign: 'left',
          color: pallete.headerColor,
          fontWeight: 600
        }}
      >
        <TranslatableTypography sx={{ display: 'inline', fontWeight: 800 }}>
          {text}
        </TranslatableTypography>
        {valueComponent}
      </Box>
    )
  }
}

const votingStyle = {
  wrapper: { width: '100%', padding: '5px' },
  unsubmittedVotesWrapper: {
    width: '100%',
    textAlign: 'center',
    padding: '15px'
  },
  disablledVotesWrapper: {
    width: '100%',
    textAlign: 'center',
    padding: '15px',
    color: pallete.headerColor
  },
  remainingTicketsWrapper: { padding: '10px 0px 0px 10px' },
  votingControlsWrapper: { padding: '10px 10px 0px 0px', textAlign: 'right' },
  clocksWrapper: {
    padding: '0px 10px 10px 10px',
    '& p': {
      display: 'inline-block',
      color: pallete.headerColor,
      fontSize: '0.8rem'
    },
    '& p:first-of-type': { marginRight: '10px' }
  }
}

class VotingComponent extends Component {
  render () {
    const { auth, config } = this.props
    const { unSubmittedVotes, remainingTickets, votingDeadline } =
      this.props.data
    const cancelButton = <Cancel onCancel={() => this.props.onCancel()} />
    const saveButton = (
      <Save onSave={() => this.props.onSave(config, unSubmittedVotes, auth)} />
    )
    let save = <Box />

    const deadline = isDefined(votingDeadline)
      ? new Date(votingDeadline * 1000).toLocaleString()
      : undefined
    const disabled = (votingDeadline ?? Date.now()) * 1000 < Date.now()

    if (disabled) {
      save = <Box sx={votingStyle.disablledVotesWrapper}><TranslatableTypography>Voting has ended</TranslatableTypography></Box>
    } else if (unSubmittedVotes.length > 0) {
      save = (
        <Box sx={votingStyle.unsubmittedVotesWrapper}>
          {cancelButton} {saveButton}
        </Box>
      )
    }

    return (
      <Paper sx={votingStyle.wrapper}>
        <VotingCarousel />
        <Grid container>
          <Grid item xs={8} sx={votingStyle.remainingTicketsWrapper}>
            <Summary remainingTickets={remainingTickets} />
          </Grid>
          <Grid item xs={4} sx={votingStyle.votingControlsWrapper}>
            <VotingProfiles />
            <VotingHelp />
          </Grid>
          <Grid item xs={12} sx={votingStyle.clocksWrapper}>
            <Clock deadline={deadline} disabled={disabled} />
          </Grid>
        </Grid>
        <VotingScoreCard disabled={disabled} />
        {save}
      </Paper>
    )
  }
}

const mapVotingProps = function (state) {
  const { auth, config } = state
  const { form, checksum, error } = state.voting

  return {
    ...mapDataPropsProvider(form, checksum, state),
    config: config.config,
    error,
    auth
  }
}

const mapVotingDispatch = (dispatch) => {
  return {
    onCancel: () => {
      dispatch(cancel({}))
    },
    onSave: (config, profiles, auth) => {
      requestWithUserAuth(config, auth, (auth) => save(config, profiles, auth))
    }
  }
}

export const Voting = connect(
  mapVotingProps,
  mapVotingDispatch
)(withTranslation(VotingComponent))
