import i18next from 'i18next'

import { initReactI18next, useTranslation } from 'react-i18next'
const resources = {
  en: {
    translation: {
      toggle_next_language_label: 'CZ',
      toggle_next_language: 'cs',
      date_locale: 'en-GB',
      notifications: 'Notifications',
      events: 'Events',
      map: 'Map',
      voting: 'Voting',
      achievements: 'Achievements',
      disconnected: 'Connection error',
      header: '/header.svg',
      logout: 'Logout',
      add: 'Add',
      newNotification: 'New notification',
      newEvent: 'New event',
      loginRedirect: 'You are being redirected for authentication. ',
      limitedAccess: 'Login',
      newCampaign: 'New campaign',
      day_0: 'Sunday',
      day_1: 'Monday',
      day_2: 'Tuesday',
      day_3: 'Wednesday',
      day_4: 'Thursday',
      day_5: 'Friday',
      day_6: 'Saturday',
      today: 'Today',
      hamburgerMenuBehaviour: 'Hamburger menu behavior:',
      userRelatedInformation: 'User related information:',
      languageSelectors: 'Language selectors:',
      dataCards: 'Cards:',
      votingSelection: 'Voting selectors:',
      more: 'More',
      vote: 'Vote',
      voteLabel: 'Vote',
      changeVote: 'Change vote',
      containers: 'Containers:',
      selectFursuiter: 'Select Fursuiter:',
      'routing-widgets': 'widgets',
      'routing-all': 'all',
      'routing-main': 'main',
      'routing-containers': 'containers',
      'routing-cards': 'cards',
      'routing-voting': 'voting',
      'routing-map': 'map',
      Continue: 'Continue',
      Open: 'Open',
      'server-error': 'There was error while connecting to servers.',
      'server-error-help':
        'There was error while connecting to servers, using cached data. Please check you internet connection and refresh the page.',
      'consite-unavailable': 'Error connecting to consite server',
      'consite-unavailable-help':
        'There was error while connecting to local server. Using public server which contains outdated data. Please check you are connected to Česfur network - "cesfur" and refresh the page',
      'no-data-available': 'No data available',
      'user-menu': 'In this menu, you will see user related items.',
      'companion-authorization':
        'Please continue to convention page for login, then approve companion client access.',
      'authorization-error':
        'There was error during authorization & authentication.',
      'not-registered': 'You are not registered to convention.',
      'logout-success': 'Logout success.',
      login: 'Login',
      retry: 'Retry',
      save: 'Save',
      cancel: 'Cancel',
      'no-votes-remaining': 'No votes remaining',
      'votes-remaining': 'Votes remaining',
      'voting-help':
        'You have limited vote tickets you can assign to fursuiters in different categories. At any time, you can just redistribute already voted tickets.',
      'authentication-expired':
        'Your authentication expired, please login again.',
      'no-access': "You don't have access to this page, please login.",
      'on-bar': 'What you can explore this year on tap:',
      'created-by':
        'Application created by @Maiyun (2024) for ČESFUR z.s. ČESFUR z.s. is rightful owner of the content. Created using Ract JS and MUI',
      coninfo: 'ConInfo',
      'Application is loading': 'Application is loading',
      loading: 'Loading',
      'Show votes only:': ' Show votes only:',
      'Reset application cache': 'Reset application cache',
      'Are you sure you want to reset application and reload data?':
        'Are you sure you want to reset application and reload data?',
      Yes: 'Yes',
      No: 'No',
      'You can still vote until:': 'You can still vote until:',
      'Voting has ended': 'Voting has ended',
      'report-bug': 'Report bug',
      'share-me': 'Share me:',
      'add favorite': 'Add favorite',
      'remove favorite': 'Remove favorite',
      'nothing here - events': 'Nothing is here, you need to add favorite events first',
      'nothing here - assigned': 'Nothing is here, you are not assigned to any event',
      'my-achievements': 'My Achievements',
      yaps: 'Yaps',
      send: 'Send',
      'new-yap': 'New Yap',
      'empty-yap-validation-error': 'Yap cannot be empty',
      'internal-server-error': 'Hoops, something went wrong',
      'authorization-expired': 'Authorization with conreg server expired, please try to log in again',
      'conops-note': 'Note (Conops):',
      'security-note': 'Note (Security):',
      equipment: 'Note (Technical equipment):',
      'date-notifications': 'Deadlines',
      'check-notifications': 'Interestings',
      'coninfo-notifications': 'Announcements',
      'convention-announcement': 'Announcement',
      'nothing here - achievements': 'You still didn\' get any achievement for this year, it can take several minutes before new achievement shows here',
      'all-achievements': 'Available this year',
      'user-achievements': 'Earned',
      'achievements-label': 'This is the list of achievements available for this year:',
      'user-achievements-label': 'This is the list of achievements you already got for this year:',
      'no-announcements': 'No announcements to show',
      'achievement-awarded': 'Received'
    }
  },
  cs: {
    translation: {
      toggle_next_language_label: 'EN',
      toggle_next_language: 'en',
      date_locale: 'cs-CZ',
      notifications: 'Oznámení',
      events: 'Program',
      map: 'Mapa',
      voting: 'Hlasování',
      achievements: 'Trofeje',
      disconnected: 'Chyba připojení',
      header: '/header.svg',
      logout: 'Odhlásit',
      add: 'Přidat',
      newNotification: 'Nové oznámení',
      newEvent: 'Nová událost',
      loginRedirect: 'Právě probíhá přesměrování pro přihlášení',
      limitedAccess: 'Přihlášení',
      newCampaign: 'Nová Kampaň',
      day_0: 'Neděle',
      day_1: 'Pondělí',
      day_2: 'Úterý',
      day_3: 'Středa',
      day_4: 'Čtvrtek',
      day_5: 'Pátek',
      day_6: 'Sobota',
      today: 'Dnes',
      widgets: 'Komponenty',
      allWidgets: 'Všechny',
      hamburgerMenuBehaviour: 'Chování hlavní nabídky:',
      userRelatedInformation: 'Zobrazení informací o uživateli:',
      languageSelectors: 'Výběr jazyka:',
      dataCards: 'Karty:',
      votingSelection: 'Volby pro hlasování',
      more: 'Více',
      vote: 'Volba',
      voteLabel: 'Hlasuj',
      changeVote: 'Změň hlas',
      containers: 'Kontejnery:',
      selectFursuiter: 'Zvol Fursuitera:',
      Continue: 'Pokračovat',
      Open: 'Otevřít',
      'routing-widgets': 'komponenty',
      'routing-all': 'všechny',
      'routing-main': 'main',
      'routing-containers': 'kontejnery',
      'routing-cards': 'karty',
      'routing-voting': 'hlasování',
      'routing-map': 'mapa',
      'server-error': 'Nastala chyba během připojování k serverům.',
      'server-error-help':
        'Chyba během připojování k serverům, prosím zkontrolujte zda jste připojeni k internetu a zaktualizujte prosím stránky.',
      'consite-unavailable':
        'Nastala chyba během připojování k lokálnímu serveru',
      'consite-unavailable-help':
        'Nastala chyba během připojování k lokálnímu serveru, prosím zkontrolujte zda jste připojeni k Česfur wi-fi - "cesfur" a zaktualizujte prosím stránky.',
      'no-data-available': 'Žádné data k dispozici',
      'user-menu': 'V tomto menu naleznete uřivatelské funkce.',
      'companion-authorization':
        'Prosím pokračujte na hlavní web pro přihlášení a autorizaci aplikace.',
      'authorization-error': 'Nastala chyba během přihlášení.',
      'not-registered': 'Nejste přihlášený na žádný con.',
      'logout-success': 'Byli jste odhlášeni',
      retry: 'Zkusit znovu',
      login: 'Přihlásit',
      save: 'Uložit',
      cancel: 'Zrušit',
      'no-votes-remaining': 'Nezbývají žádné hlasy',
      'votes-remaining': 'Zbývající hlasy',
      'voting-help':
        'Máte omezené množství hlasů které můžete rozdat, pokud už jste nějaké hlasy rozdali, kdykoliv je můžete přerozdělit znovu.',
      'authentication-expired':
        'Autorizace vypršela, prosím přihlaste se znova.',
      'no-access': 'Nemáte přístup do této sekce, prosím přihlašte se.',
      'on-bar': 'Co můžete letos objevovat na pípě:',
      'created-by':
        'Aplikace vytvořena @Maiyun (2024) pro ČESFUR z.s. Obsah ve vlastnictví ČESFUR z.s. Vytvořeno za použití React JS a MUI',
      coninfo: 'ConInfo',
      'Application is loading': 'Aplikace se načítá',
      loading: 'Načítání',
      'Show votes only:': 'Zobrazit jen s hlasy:',
      'Reset application cache': 'Reset lokální cache',
      'Are you sure you want to reset application and reload data?':
        'Jste si jisti že chcete vyresetovat aplikaci a obnovit data?',
      Yes: 'Ano',
      No: 'Ne',
      'You can still vote until:': 'Stále můžete hlasovat do:',
      'Voting has ended': 'Hlasování skončilo',
      'report-bug': 'Nahlásit bug',
      'share-me': 'Sdílej mě:',
      'add favorite': 'Přidej k oblíbeným',
      'remove favorite': 'Odstraň z oblíbených',
      'nothing here - events': 'Nic zde není, nejdříve musíte vybrat Vaše oblíbené programové body',
      'nothing here - assigned': 'Nic zde není, nejste přiřazen k žádnému programovému bodu',
      'my-achievements': 'Mé trofeje',
      yaps: 'Štěky',
      send: 'Odeslat',
      'new-yap': 'Nový štěk',
      'empty-yap-validation-error': 'Nelze odeslat prázdnou zprávu',
      'internal-server-error': 'Jejda, něco se pokazilo',
      'authorization-expired': 'Autorizace s conreg serverem vypršela, přihlašte se prosím znovu',
      'conops-note': 'Poznámka (Conops):',
      'security-note': 'Poznámka (Security):',
      equipment: 'Poznámka (Technické vybavení):',
      'date-notifications': 'Uzávěrky',
      'check-notifications': 'Zajímavosti',
      'coninfo-notifications': 'Oznámení',
      'convention-announcement': 'Oznámení',
      'all-achievements': 'Letošní trofeje',
      'user-achievements': 'Získané trofeje',
      'nothing here - achievements': 'Pro tento rok ste nezískal stále žádnou trofej, může trvat i několik minut než se získaný achievement objeví',
      'achievements-label': 'Toto je seznam trofejí které můžete letos získat:',
      'user-achievements-label': 'Toto je seznam trofejí, které se vám již podařilo získat:',
      'no-announcements': 'Žádné oznámení',
      'achievement-awarded': 'Získáno'
    }
  }
}

i18next.use(initReactI18next).init({
  debug: false,
  resources,
  lng: 'en',
  interpolation: {
    escapeValue: false
  }
})

/* eslint-disable react/display-name */
/* TODO: Anonymous function naming */
export const withTranslation = (Component) => (props) => {
  const engine = useTranslation()
  return (
    <Component {...props} i18nEngine={engine} language={engine.i18n.language} />
  )
}

export const translateText = function (items, i18n) {
  try {
    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      if (item.lang === i18n.language) {
        return item.text
      }
    }
  } catch (Error) {
    console.error('There was error')
    console.error(Error)
    return ''
  }

  return ''
}

export default i18next
