import { createSlice } from '@reduxjs/toolkit'
import '../../utils'

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const initialState = {
  authToken: undefined,
  profile: undefined,
  loginUrl: undefined,
  refreshToken: undefined,
  accessToken: undefined,
  error: undefined
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    fetchLoginUrlSuccess (state, action) {
      const { url } = action.payload
      return {
        ...state,
        loginUrl: url
      }
    },
    fetchLoginUrlError (state, action) {
      const { detail } = action.payload

      return {
        ...state,
        error: detail
      }
    },
    fetchAccessTokenSuccess (state, action) {
      const { accessToken, refreshToken } = action.payload
      return {
        ...state,
        accessToken,
        refreshToken
      }
    },
    fetchTokenError (state, action) {
      const { detail } = action.payload

      return {
        ...state,
        authToken: undefined,
        profile: undefined,
        loginUrl: undefined,
        refreshToken: undefined,
        accessToken: undefined,
        error: detail
      }
    },
    auth (state, action) {
      const { authToken, profile } = action.payload
      return {
        ...state,
        authToken,
        profile
      }
    },
    resetAuth (state) {
      return {
        ...state,
        authToken: undefined,
        profile: undefined,
        loginUrl: undefined,
        refreshToken: undefined,
        accessToken: undefined
      }
    },
    authExpired (state) {
      return {
        ...state,
        expired: true
      }
    },
    clearExpired (state) {
      return {
        ...state,
        expired: false
      }
    }
  }
})

const persistConfig = {
  key: 'auth',
  storage
}

export const {
  fetchLoginUrlSuccess,
  fetchLoginUrlError,
  fetchAccessTokenSuccess,
  fetchTokenError,
  auth,
  resetAuth,
  authExpired,
  clearExpired
} = authSlice.actions
export const AuthReducer = persistReducer(persistConfig, authSlice.reducer)
