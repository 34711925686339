/**
 * React, react redux and react localization imports
 */

import { Component, PureComponent } from 'react'

/**
 * Material imports
 */
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography
} from '@mui/material'

/**
 * Relative exports to root directory
 */
import { pallete } from '../../../theme'
import { notDefined, shallowOpts } from '../../../utils'
import { TranslatableTypography } from './index'

import Carousel from 'react-material-ui-carousel'

const mapStyle = {
  root: {
    width: '100%',
    borderRadius: '5px'
  },
  floor: {
    card: {
      position: 'relative',
      height: '100%',
      paddingBottom: '80px',
      backgroundColor: '#467d47',
      color: pallete.paperColor,
      '& .companion-map-media-container': {
        padding: '30px 15px 0px 15px'
      }
    },
    title: {
      position: 'absolute',
      textAlign: 'center',
      width: '100%',
      padding: '2px',
      backgroundColor: pallete.paperColorTransparent,
      color: pallete.paperColor,
      fontWeight: 600
    }
  },
  image: {
    width: '100%',
    aspectRatio: '3/4'
  },
  legend: {
    position: 'absolute',
    bottom: '0px',
    width: '100%',
    padding: '0px',
    '& .companion-legend-container': {
      background: '#467d47',
      width: '100%',
      padding: '15px'
    }
  },
  font: {
    fontSize: '1rem',
    fontWeight: 800,
    textShadow: 'black 1px 0 15px'
  },
  placeIcon: {
    color: pallete.paperColor,
    padding: '1px'
  },
  placeId: {
    color: pallete.paperColor,
    paddingRight: '5px',
    textAlign: 'right'
  },
  placeLabel: {
    padding: '0px'
  },
  navButtonWrapper: {
    '&:hover button': {
      backgroundColor: pallete.paperColor,
      opacity: '1!important'
    }
  },
  navButton: {
    backgroundColor: pallete.paperColor,
    top: '40px !important',
    '&:hover': {
      backgroundColor: pallete.paperColor,
      opacity: '1!important'
    },
    '& .MuiSvgIcon-root': {
      color: 'black'
    }
  }
}

class MapComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      touchedX: undefined,
      lastPositionX: undefined,
      touchedY: undefined,
      lastPositionY: undefined
    }
  }

  onTouchStart (e) {
    console.log(e)
    this.setState({
      touchedX: e.targetTouches[0].clientX,
      touchedY: e.targetTouches[0].clientY,
      lastPositionX: undefined,
      lastPositionY: undefined
    })
  }

  onTouchMove (e) {
    this.setState({
      ...this.state,
      lastPositionX: e.targetTouches[0].clientX,
      lastPositionY: e.targetTouches[0].clientY
    })
  }

  onTouchEnd (e) {
    const { touchedX, touchedY, lastPositionX, lastPositionY } = this.state
    if (notDefined(touchedX) || notDefined(touchedY) || notDefined(lastPositionX) || notDefined(lastPositionY)) {
      return
    }

    const deltaX = lastPositionX - touchedX
    const deltaY = lastPositionY - touchedY

    this.setState({
      touchedX: undefined,
      lastPositionX: undefined,
      touchedY: undefined,
      lastPositionY: undefined
    })

    if (Math.abs(deltaX) > Math.abs(deltaY)) {
      if (deltaX < 0) {
        this.onRight()
      } else {
        this.onLeft()
      }
    } else {
      return
    }
    console.log(deltaX, deltaY)
  }

  onLeft () {
    const newSelect =
      this.props.selected - 1 < 0
        ? this.props.data.length - 1
        : this.props.selected - 1
    this.props.onSelect(newSelect)
  }

  onRight () {
    const newSelect =
      this.props.selected + 1 >= this.props.data.length
        ? 0
        : this.props.selected + 1
    this.props.onSelect(newSelect)
  }

  render () {
    const floors = this.props.data.map((floor) => {
      return (
        <MapFloorComponent key={floor.id} sx={mapStyle.floor} {...floor}></MapFloorComponent>
      )
    })

    return (
      <Box
        onTouchStart={(e) => this.onTouchStart(e)}
        onTouchMove={(e) => this.onTouchMove(e)}
        onTouchEnd={(e) => this.onTouchEnd(e)}
      >
        <MapRootComponent sx={mapStyle.root} {...shallowOpts(this.props)}>
          {floors}
        </MapRootComponent>
      </Box>
    )
  }
}

export const Map = MapComponent

class MapRootComponent extends PureComponent {
  render () {
    const { onSelect, selected } = this.props

    const carouselOpts = {
      ...this.props,
      autoPlay: false,
      changeOnFirstRender: false,
      swipe: false,
      navButtonsAlwaysVisible: true,
      indicators: false,
      animation: 'fade',
      cycleNavigation: true,
      duration: 0,
      sx: mapStyle.root,
      index: selected,
      navButtonsProps: {
        sx: mapStyle.navButton
      },
      navButtonsWrapperProps: {
        sx: mapStyle.navButtonWrapper
      },
      onChange: (now) => (!notDefined(onSelect) ? onSelect(now) : {})
    }

    return <Carousel {...carouselOpts}>{this.props.children}</Carousel>
  }
}

class MapFloorComponent extends PureComponent {
  render () {
    const { img, titles, legend } = this.props

    const columnA = []
    const columnB = []

    let counter = 0
    legend.forEach((l) => {
      const item = (
        <Grid key={l.id} item xs={12} className="legend">
          <Grid container>
            <Grid item xs={2} sx={mapStyle.placeId}>
              <Typography sx={mapStyle.font}>{l.label}</Typography>
            </Grid>
            <Grid item xs={10} sx={mapStyle.placeLabel}>
              <TranslatableTypography sx={mapStyle.font} data={l.titles} />
            </Grid>
          </Grid>
        </Grid>
      )
      if (counter % 2 === 0) {
        columnA.push(item)
      } else {
        columnB.push(item)
      }
      counter += 1
    })

    return (
      <Card sx={mapStyle.floor.card}>
        <Box sx={mapStyle.floor.title}>
          <TranslatableTypography data={titles} />
        </Box>
        <Box className="companion-map-media-container">
          <CardMedia image={img} sx={mapStyle.image}></CardMedia>
        </Box>
        <CardContent sx={mapStyle.legend}>
          <Grid container className="companion-legend-container">
            <Grid item xs={6} className="companion-legend-left">
              {columnA}
            </Grid>
            <Grid item xs={6} className="companion-legend-right">
              {columnB}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }
}
