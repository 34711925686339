import { createSlice, current } from '@reduxjs/toolkit'

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { DefaultMap } from '../../utils'

const initialState = {
  data: [],
  error: '',
  updated: '',
  checksum: '',
  favorite: [],
  assigned: [],
  favorite_ids: []
}

function sortByStartEnd (event1, event2) {
  const eventDate1 = {
    start: new Date(event1.start),
    end: new Date(event1.end)
  }

  const eventDate2 = {
    start: new Date(event2.start),
    end: new Date(event2.end)
  }

  const duration1 = eventDate1.end - eventDate1.start
  const duration2 = eventDate2.end - eventDate2.start
  const startEquals = eventDate1.start <= eventDate2.start && eventDate1.start >= eventDate2.start

  if (eventDate1.start < eventDate2.start) {
    return -1
  } else if (startEquals && duration1 <= duration2) {
    return -1
  }

  return 1
}

function pushToEventArray (arr, event, filter, favorites) {
  let customizedEvent = { ...event }

  if (favorites.includes(customizedEvent.con_id)) {
    customizedEvent = { ...customizedEvent, favorite: true }
  } else {
    customizedEvent = { ...customizedEvent, favorite: undefined }
  }

  if (!filter(customizedEvent)) {
    return
  }

  arr.push(customizedEvent)
}

function processEvents (events, state, favorites) {
  events.sort(sortByStartEnd)

  const allEvents = new DefaultMap(() => [])
  const favoriteEvents = new DefaultMap(() => [])
  const assignedEvents = new DefaultMap(() => [])
  let assignedCount = 0

  events.forEach((event) => {
    const start = !event?.start.includes('Z') ? event.start + 'Z' : event.start
    const end = !event?.end.includes('Z') ? event.end + 'Z' : event.end

    const updatedEvent = { ...event, start, end }

    const startDate = new Date(start)
    const key = startDate.toISOString().substring(0, 10)

    const allEventsValues = allEvents.get(key)
    pushToEventArray(allEventsValues, updatedEvent, () => true, favorites)

    const favoriteEventsValues = favoriteEvents.get(key)
    pushToEventArray(favoriteEventsValues, updatedEvent, (e) => e?.favorite, favorites)

    const assignedEventValues = assignedEvents.get(key)
    pushToEventArray(assignedEventValues, updatedEvent, (e) => e?.assigned, favorites)
    assignedCount = (event?.assigned) ? assignedCount + 1 : assignedCount
  })

  return {
    ...state,
    data: [...allEvents.entries()],
    favorite: [...favoriteEvents.entries()],
    assigned: [...assignedEvents.entries()],
    assignedCount,
    favorite_ids: [...favorites]
  }
}

const events = createSlice({
  name: 'events',
  initialState,
  reducers: {
    fetchEventsSuccess (state, action) {
      const { data, checksum, generated } = action.payload
      const { favorite_ids } = current(state)

      return processEvents(data, {
        ...state,
        checksum,
        updated: generated,
        error: ''
      }, favorite_ids)
    },
    fetchEventsError (state, action) {
      const { detail } = action.payload

      return {
        ...state,
        error: detail
      }
    },
    setFavoriteForEvent (state, action) {
      const { con_id, favorite } = action.payload
      const { data, favorite_ids } = current(state)
      const events = data.flatMap((entry) => entry[1])
      const ids = new Set(favorite_ids)
      if (favorite) {
        ids.add(con_id)
      } else {
        ids.delete(con_id)
      }

      return processEvents(events, {
        ...state
      }, [...ids])
    }
  }
})

const persistConfig = {
  key: 'events',
  storage
}

export const { fetchEventsSuccess, fetchEventsError, setFavoriteForEvent } = events.actions
export const EventsReducer = persistReducer(persistConfig, events.reducer)
