import { Box, Divider, Grid, Paper } from '@mui/material'
import { Component } from 'react'
import { withTranslation } from '../language.js'
import { pallete, sizing } from '../theme.js'
import {
  Card,
  HamburgerDrawerButton,
  HamburgerDrawerMenu,
  HamburgerPopupMenu,
  LanguageFlags,
  LanguageSelectText,
  Map,
  NoData,
  SearchableGrid,
  StatelessDisconnected,
  TranslatableButton,
  TranslatableMenuItem,
  TranslatableTypography,
  UnauthorizedBadge,
  UnauthorizedButton,
  UserButton,
  VerticalGroupedStack
} from './components/widgets'

import {
  BreadcrumbLast,
  BreadcrumbLink
} from './components/widgets/BreadCrumbsItems.js'

import { Logout, NavigateNext } from '@mui/icons-material'
import SubHeadeWithBreadcrumb from './components/SubHeader.js'

class MainComponentWidgets extends Component {
  render () {
    return (
      <Grid container>
        <TranslatableTypography fontWeight={600}>
          hamburgerMenuBehaviour
        </TranslatableTypography>
        <Divider
          style={{
            color: pallete.headerTransparentColor,
            background: pallete.headerTransparentColor,
            marginTop: 5,
            marginBottom: 5,
            height: 3
          }}
        />

        <Grid
          item
          xs={12}
          style={{
            height: sizing.header.height,
            background: pallete.headerColor,
            marginBottom: 10
          }}
        >
          <Box style={{ width: 180, padding: 5 }}>
            <HamburgerPopupMenu reference="popup-widgets">
              <TranslatableMenuItem text="voting">
                <NavigateNext sx={{ marginLeft: 'auto' }}></NavigateNext>
              </TranslatableMenuItem>
              <TranslatableMenuItem text="achievements">
                <NavigateNext sx={{ marginLeft: 'auto' }}></NavigateNext>
              </TranslatableMenuItem>
              <Divider />
              <TranslatableMenuItem text="logout">
                <Logout fontSize="small" sx={{ marginLeft: 'auto' }}></Logout>
              </TranslatableMenuItem>
            </HamburgerPopupMenu>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            marginBottom: 10,
            border: `2px solid ${pallete.headerColor}`
          }}
        >
          <Box
            style={{
              width: '100%',
              height: sizing.header.height,
              background: pallete.headerColor
            }}
          >
            <Box style={{ width: 200, padding: 5 }}>
              <HamburgerDrawerButton reference="drawer-widgets" />
              <HamburgerDrawerMenu reference="drawer-widgets"></HamburgerDrawerMenu>
            </Box>
          </Box>
        </Grid>

        <TranslatableTypography fontWeight={600}>
          userRelatedInformation
        </TranslatableTypography>
        <Divider
          style={{
            color: pallete.headerTransparentColor,
            background: pallete.headerTransparentColor,
            marginTop: 5,
            marginBottom: 5,
            height: 3
          }}
        />

        <Grid
          item
          xs={12}
          style={{
            height: sizing.header.height,
            background: pallete.headerColor,
            marginBottom: 10
          }}
        >
          <Box sx={{ width: 120, marginLeft: 'auto', marginRight: 0 }}>
            <UnauthorizedBadge />
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            height: sizing.header.height,
            background: pallete.headerColor,
            marginBottom: 10
          }}
        >
          <Box
            style={{
              width: 160,
              marginLeft: 'auto',
              marginRight: 15,
              marginTop: 15
            }}
          >
            <UnauthorizedButton />
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            height: sizing.header.height,
            background: pallete.headerColor,
            marginBottom: 10
          }}
        >
          <Box
            style={{
              width: 120,
              marginLeft: 'auto',
              marginRight: 0
            }}
          >
            <UserButton
              profile={{
                nick: 'Maiyun',
                user: '630',
                attendee: 5,
                avatar_url: '/api/v1/static/images/badges/sm_5.png'
              }}
            />
          </Box>
        </Grid>

        <TranslatableTypography fontWeight={600}>
          languageSelectors
        </TranslatableTypography>
        <Divider
          style={{
            color: pallete.headerTransparentColor,
            background: pallete.headerTransparentColor,
            marginTop: 5,
            marginBottom: 5,
            height: 3
          }}
        />

        <Grid
          item
          xs={12}
          style={{
            height: 50,
            background: pallete.headerColor,
            marginBottom: 10
          }}
        >
          <Box
            style={{
              width: 70,
              marginLeft: 'auto',
              marginTop: 5,
              marginRight: 0
            }}
          >
            <LanguageSelectText />
          </Box>
        </Grid>

        <TranslatableTypography fontWeight={600}>
          disconnected
        </TranslatableTypography>
        <Divider
          style={{
            color: pallete.headerTransparentColor,
            background: pallete.headerTransparentColor,
            marginTop: 5,
            marginBottom: 5,
            height: 3
          }}
        />

        <Grid item xs={12} style={{ marginBottom: 10 }}>
          <StatelessDisconnected visible={true} />
        </Grid>

        <TranslatableTypography fontWeight={600}>noData</TranslatableTypography>
        <Grid item xs={12} style={{ marginBottom: 10, textAlign: 'center' }}>
          <Box
            sx={{
              width: '300px',
              height: '400px',
              display: 'inline-block'
            }}
          >
            <NoData />
          </Box>
        </Grid>

        <Grid item xs={12} style={{ marginBottom: 2 }}>
          <Box sx={{ height: 90 }}>
            <Box sx={{ height: 15, background: pallete.headerColor }}></Box>
            <Box
              sx={{
                height: 35,
                background: pallete.headerTransparentColor
              }}
            >
              <Box
                style={{
                  width: 60,
                  marginLeft: 'auto',
                  marginRight: 20
                }}
              >
                <LanguageFlags />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    )
  }
}

class DataCardWidgets extends Component {
  render () {
    return (
      <Box>
        <TranslatableTypography fontWeight={600}>
          dataCards
        </TranslatableTypography>
        <Divider
          style={{
            color: pallete.headerTransparentColor,
            background: pallete.headerTransparentColor,
            marginTop: 5,
            marginBottom: 5,
            height: 3
          }}
        />

        <Grid container direction="column" alignItems="center" justify="center">
          <Grid item xs={12} style={{ marginBottom: 10, padding: 10 }}>
            <Card
              data-component="more-card"
              sx={{ maxWidth: 400 }}
              max={100}
              text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam pellentesque nibh ac accumsan convallis. Etiam pellentesque nibh ac accumsan convallis. Etiam bibendum sapien at metus ullamcorper pulvinar eget quis erat. Maecenas pellentesque ipsum non risus consectetur aliquet. Sed ut tellus elementum, consequat erat ac, volutpat sapien."
            />
          </Grid>

          <Grid item xs={12} style={{ marginBottom: 10, padding: 10 }}>
            <Card
              data-component="more-card"
              sx={{ maxWidth: 400, height: 200 }}
              max={200}
              text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam pellentesque nibh ac accumsan convallis. Etiam pellentesque nibh ac accumsan convallis. Etiam bibendum sapien at metus ullamcorper pulvinar eget quis erat. Maecenas pellentesque ipsum non risus consectetur aliquet. Sed ut tellus elementum, consequat erat ac, volutpat sapien."
              actions={<TranslatableButton>more</TranslatableButton>}
            />
          </Grid>

          <Grid item xs={12} style={{ marginBottom: 10, padding: 10 }}>
            <Card
              data-component="more-card"
              sx={{ display: 'flex', maxWidth: 400 }}
              max={200}
              fontSize="small"
              text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam pellentesque nibh ac accumsan convallis. Etiam pellentesque nibh ac accumsan convallis. Etiam bibendum sapien at metus ullamcorper pulvinar eget quis erat. Maecenas pellentesque ipsum non risus consectetur aliquet. Sed ut tellus elementum, consequat erat ac, volutpat sapien."
              actions={<TranslatableButton>more</TranslatableButton>}
              media={{
                component: 'img',
                alt: 'Yala Box',
                image: '/yala_rectangle.png'
              }}
            />
          </Grid>

          <Grid item xs={12} style={{ marginBottom: 10, padding: 10 }}>
            <Card
              data-component="more-card"
              sx={{ display: 'flex', maxWidth: 400 }}
              max={200}
              fontSize="small"
              text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam pellentesque nibh ac accumsan convallis. Etiam pellentesque nibh ac accumsan convallis. Etiam bibendum sapien at metus ullamcorper pulvinar eget quis erat. Maecenas pellentesque ipsum non risus consectetur aliquet. Sed ut tellus elementum, consequat erat ac, volutpat sapien."
              actions={<TranslatableButton>more</TranslatableButton>}
              media={{
                component: 'img',
                alt: 'Yala Box',
                image: '/yala_rectangle.png'
              }}
              title="My super title!"
            />
          </Grid>

          <Grid item xs={12} style={{ marginBottom: 10, padding: 10 }}>
            <Card
              data-component="more-card"
              sx={{ display: 'flex', maxWidth: 400 }}
              max={200}
              fontSize="small"
              text="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
              title="My super title!"
            />
          </Grid>

          <Grid item xs={12} style={{ marginBottom: 10, padding: 10 }}>
            <Card
              data-component="more-card"
              sx={{ display: 'flex', maxWidth: 400 }}
              max={200}
              fontSize="small"
              text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam pellentesque nibh ac accumsan convallis. Etiam pellentesque nibh ac accumsan convallis. Etiam bibendum sapien at metus ullamcorper pulvinar eget quis erat. Maecenas pellentesque ipsum non risus consectetur aliquet. Sed ut tellus elementum, consequat erat ac, volutpat sapien."
              actions={<TranslatableButton>more</TranslatableButton>}
              title="My super title!"
            />
          </Grid>

          <Grid item xs={12} style={{ marginBottom: 10, padding: 10 }}>
            <Card
              data-component="more-card"
              sx={{ display: 'flex', maxWidth: 400 }}
              max={200}
              fontSize="small"
              text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam pellentesque nibh ac accumsan convallis. Etiam pellentesque nibh ac accumsan convallis. Etiam bibendum sapien at metus ullamcorper pulvinar eget quis erat. Maecenas pellentesque ipsum non risus consectetur aliquet. Sed ut tellus elementum, consequat erat ac, volutpat sapien."
              actions={<TranslatableButton>more</TranslatableButton>}
              icon="EventBusy"
              title="My super title!"
            />
          </Grid>
        </Grid>
      </Box>
    )
  }
}

// const voting = {

//   tickets: {
//     left: 0,
//     max: 10,
//   },

//   campaigns: [
//     {
//       id: "fursuiter",
//       name: "fursuiter",
//       title: [
//         { lang: "cs", text: "Best Fursuit" },
//         { lang: "en", text: "Best Fursuit" },
//       ],
//     },
//     {
//       id: "performer",
//       name: "performer",
//       title: [
//         { lang: "cs", text: "Best Performer" },
//         { lang: "en", text: "Best Performer" },
//       ],
//     },
//   ],

//   profiles: [
//     {
//       badge: 1,
//       id: "yala_yue21",
//       title: "Yala Yue",
//       avatar: "/yala_rectangle.png",
//       voting: [
//         { campaign: "fursuiter", tickets: 2 },
//         { campaign: "performer", tickets: 3 },
//       ],
//     },
//     {
//       badge: 2,
//       id: "yala_yue22",
//       title: "Yala Yue",
//       avatar: "/yala_landscape.png",
//       voting: [
//         { campaign: "fursuiter", tickets: 4 },
//         { campaign: "performer", tickets: 1 },
//       ],
//     },
//     {
//       badge: 3,
//       id: "yala_yue23",
//       title: "Yala Yue",
//       avatar: "/yala_portrait.png",
//       voting: [],
//     },
//     {
//       badge: 4,
//       id: "yala_yue24",
//       title: "Yala Yue",
//       avatar: "/yala_portrait.png",
//       voting: [],
//     },
//     {
//       badge: 5,
//       id: "yala_yue1",
//       title: "Yala Yue",
//       avatar: "/yala_rectangle.png",
//       voting: [],
//     },
//     {
//       badge: 6,
//       id: "yala_yue2",
//       title: "Yala Yue",
//       avatar: "/yala_landscape.png",
//       voting: [],
//     },
//     {
//       badge: 7,
//       id: "yala_yue3",
//       title: "Yala Yue",
//       avatar: "/yala_portrait.png",
//       voting: [],
//     },
//     {
//       badge: 8,
//       id: "yala_yue4",
//       title: "Yala Yue",
//       avatar: "/yala_rectangle.png",
//       voting: [],
//     },
//     {
//       badge: 9,
//       id: "yala_yue5",
//       title: "Yala Yue",
//       avatar: "/yala_landscape.png",
//       voting: [],
//     },
//     {
//       badge: 10,
//       id: "yala_yue6",
//       title: "Yala Yue",
//       avatar: "/yala_portrait.png",
//       voting: [],
//     },
//     {
//       badge: 11,
//       id: "yala_yue7",
//       title: "Yala Yue",
//       avatar: "/yala_rectangle.png",
//       voting: [],
//     },
//     {
//       badge: 12,
//       id: "yala_yue8",
//       title: "really really long name",
//       avatar: "/yala_landscape.png",
//       voting: [],
//     },
//     {
//       badge: 13,
//       id: "yala_yue9",
//       title: "really long name",
//       avatar: "/yala_portrait.png",
//       voting: [],
//     },
//     {
//       badge: 14,
//       id: "yala_yue10",
//       title:
//         "reallyReallyReallyLongnamereallyReallyReallyLongnamereallyReallyReallyLongnamereallyReallyReallyLongnamereallyReallyReallyLongnamereallyReallyReallyLongname",
//       avatar: "/yala_rectangle.png",
//       voting: [],
//     },
//     {
//       badge: 15,
//       id: "yala_yue11",
//       title:
//         "really really really long namereally really really long namereally really really long namereally really really long namereally really really long name",
//       avatar: "/yala_landscape.png",
//       voting: [],
//     },
//     {
//       badge: 16,
//       id: "yala_yue12",
//       title: "Yala Yue",
//       avatar: "/yala_rectangle.png",
//       voting: [],
//     },
//     {
//       badge: 17,
//       id: "yala_yue13",
//       title: "Yala Yue",
//       avatar: "/yala_landscape.png",
//       voting: [],
//     },
//     {
//       badge: 18,
//       id: "yala_yue14",
//       title: "Yala Yue",
//       avatar: "/yala_portrait.png",
//       voting: [],
//     },
//     {
//       badge: 19,
//       id: "yala_yue15",
//       title: "Yala Yue",
//       avatar: "/yala_rectangle.png",
//       voting: [],
//     },
//     {
//       badge: 20,
//       id: "yala_yue16",
//       title: "Yala Yue",
//       avatar: "/yala_landscape.png",
//       voting: [],
//     },
//     {
//       badge: 21,
//       id: "yala_yue17",
//       title: "Yala Yue",
//       avatar: "/yala_portrait.png",
//       voting: [],
//     },
//     {
//       badge: 22,
//       id: "yala_yue18",
//       title: "Yala Yue",
//       avatar: "/yala_rectangle.png",
//       voting: [],
//     },
//     {
//       badge: 23,
//       id: "yala_yue19",
//       title: "Yala Yue",
//       avatar: "/yala_landscape.png",
//       voting: [],
//     },
//     {
//       badge: 24,
//       id: "yala_yue20",
//       title: "Yala Yue",
//       avatar: "/yala_portrait.png",
//       voting: [],
//     },
//   ],
// };

class VotingselectionWidgets extends Component {
  render () {
    return (
      <Box>
        <TranslatableTypography fontWeight={600}>
          votingSelection
        </TranslatableTypography>
        <Divider
          style={{
            color: pallete.headerTransparentColor,
            background: pallete.headerTransparentColor,
            marginTop: 5,
            marginBottom: 5,
            height: 3
          }}
        />

        <Grid container direction="column" alignItems="center" justify="center">
          <Grid item xs={12} style={{ marginBottom: 10 }}></Grid>
        </Grid>
      </Box>
    )
  }
}

const eventListData = [
  {
    id: '11.01.2023',
    title: '11.01.2023',
    items: [
      {
        id: '12',
        time: '10:00 - 11:00',
        title: 'Lorem ipsum dolor sit amet',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam pellentesque nibh ac accumsan convallis. Etiam pellentesque nibh ac accumsan convallis. Etiam bibendum sapien at metus ullamcorper pulvinar eget quis erat. Maecenas pellentesque ipsum non risus consectetur aliquet. Sed ut tellus elementum, consequat erat ac, volutpat sapien.'
      },
      {
        id: '22',
        time: '10:00 - 11:00',
        title: 'Lorem ipsum dolor sit amet',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam pellentesque nibh ac accumsan convallis. Etiam pellentesque nibh ac accumsan convallis. Etiam bibendum sapien at metus ullamcorper pulvinar eget quis erat. Maecenas pellentesque ipsum non risus consectetur aliquet. Sed ut tellus elementum, consequat erat ac, volutpat sapien.'
      },
      {
        id: '32',
        time: '10:00 - 11:00',
        title: 'Lorem ipsum dolor sit amet',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam pellentesque nibh ac accumsan convallis. Etiam pellentesque nibh ac accumsan convallis. Etiam bibendum sapien at metus ullamcorper pulvinar eget quis erat. Maecenas pellentesque ipsum non risus consectetur aliquet. Sed ut tellus elementum, consequat erat ac, volutpat sapien.'
      }
    ]
  },
  {
    id: '12.01.2023',
    title: '12.01.2023',
    items: [
      {
        id: '21',
        time: '10:00 - 11:00',
        title: 'Lorem ipsum dolor sit amet',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam pellentesque nibh ac accumsan convallis. Etiam pellentesque nibh ac accumsan convallis. Etiam bibendum sapien at metus ullamcorper pulvinar eget quis erat. Maecenas pellentesque ipsum non risus consectetur aliquet. Sed ut tellus elementum, consequat erat ac, volutpat sapien.'
      },
      {
        id: '22',
        time: '10:00 - 11:00',
        title: 'Lorem ipsum dolor sit amet',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam pellentesque nibh ac accumsan convallis. Etiam pellentesque nibh ac accumsan convallis. Etiam bibendum sapien at metus ullamcorper pulvinar eget quis erat. Maecenas pellentesque ipsum non risus consectetur aliquet. Sed ut tellus elementum, consequat erat ac, volutpat sapien.'
      },
      {
        id: '23',
        time: '10:00 - 11:00',
        title: 'Lorem ipsum dolor sit amet',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam pellentesque nibh ac accumsan convallis. Etiam pellentesque nibh ac accumsan convallis. Etiam bibendum sapien at metus ullamcorper pulvinar eget quis erat. Maecenas pellentesque ipsum non risus consectetur aliquet. Sed ut tellus elementum, consequat erat ac, volutpat sapien.'
      }
    ]
  },
  {
    id: '13.01.2023',
    title: '13.01.2023',
    items: [
      {
        id: '31',
        time: '10:00 - 11:00',
        title: 'Lorem ipsum dolor sit amet',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam pellentesque nibh ac accumsan convallis. Etiam pellentesque nibh ac accumsan convallis. Etiam bibendum sapien at metus ullamcorper pulvinar eget quis erat. Maecenas pellentesque ipsum non risus consectetur aliquet. Sed ut tellus elementum, consequat erat ac, volutpat sapien.'
      },
      {
        id: '32',
        time: '10:00 - 11:00',
        title: 'Lorem ipsum dolor sit amet',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam pellentesque nibh ac accumsan convallis. Etiam pellentesque nibh ac accumsan convallis. Etiam bibendum sapien at metus ullamcorper pulvinar eget quis erat. Maecenas pellentesque ipsum non risus consectetur aliquet. Sed ut tellus elementum, consequat erat ac, volutpat sapien.'
      },
      {
        id: '33',
        time: '10:00 - 11:00',
        title: 'Lorem ipsum dolor sit amet',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam pellentesque nibh ac accumsan convallis. Etiam pellentesque nibh ac accumsan convallis. Etiam bibendum sapien at metus ullamcorper pulvinar eget quis erat. Maecenas pellentesque ipsum non risus consectetur aliquet. Sed ut tellus elementum, consequat erat ac, volutpat sapien.'
      }
    ]
  }
]

const profileListData = [
  { id: 'yala_yue1', title: 'Yala Yue', avatar: '/yala_rectangle.png' },
  { id: 'yala_yue2', title: 'Yala Yue', avatar: '/yala_landscape.png' },
  { id: 'yala_yue3', title: 'Yala Yue', avatar: '/yala_portrait.png' },
  { id: 'yala_yue4', title: 'Yala Yue', avatar: '/yala_rectangle.png' },
  { id: 'yala_yue5', title: 'Yala Yue', avatar: '/yala_landscape.png' },
  { id: 'yala_yue6', title: 'Yala Yue', avatar: '/yala_portrait.png' },
  { id: 'yala_yue7', title: 'Yala Yue', avatar: '/yala_rectangle.png' },
  {
    id: 'yala_yue8',
    title: 'really really long name',
    avatar: '/yala_landscape.png'
  },
  {
    id: 'yala_yue9',
    title: 'really long name',
    avatar: '/yala_portrait.png'
  },
  {
    id: 'yala_yue10',
    title: 'reallyReallyReallyLongname',
    avatar: '/yala_rectangle.png'
  },
  {
    id: 'yala_yue11',
    title: 'really really really long name',
    avatar: '/yala_landscape.png'
  }
]

class ContaintersWidgets extends Component {
  render () {
    return (
      <Box>
        <TranslatableTypography id="containers" fontWeight={600}>
          containers
        </TranslatableTypography>
        <Divider
          style={{
            color: pallete.headerTransparentColor,
            background: pallete.headerTransparentColor,
            marginTop: 5,
            marginBottom: 5,
            height: 3
          }}
        />
        <Grid container direction="column" alignItems="center" justify="center">
          <Grid item xs={12} style={{ marginBottom: 10, padding: 10 }}>
            <Box sx={{ marginBottom: '20px' }}>
              <VerticalGroupedStack
                data={eventListData}
                checksum="123"
                renderer={{ group: 'Item', item: 'TimedItem' }}
              />
            </Box>

            <Box>
              <SearchableGrid data={profileListData} checksum="123" />
            </Box>
          </Grid>
        </Grid>
      </Box>
    )
  }
}

const mapData = [
  {
    id: 'floor-1',
    title: [
      { lang: 'cs', text: 'Přízemí' },
      { lang: 'en', text: 'Ground floor' }
    ],
    legend: [
      {
        id: 1,
        title: [
          { lang: 'cs', text: 'Lorem ipsum dolor sit amet' },
          { lang: 'en', text: 'Lorem ipsum dolor sit amet' }
        ]
      },
      {
        id: 2,
        title: [
          { lang: 'cs', text: 'Lorem ipsum dolor sit amet' },
          { lang: 'en', text: 'Lorem ipsum dolor sit amet' }
        ]
      },
      {
        id: 3,
        title: [
          { lang: 'cs', text: 'Lorem ipsum dolor sit amet' },
          { lang: 'en', text: 'Lorem ipsum dolor sit amet' }
        ]
      },
      {
        id: 4,
        title: [
          { lang: 'cs', text: 'Lorem ipsum dolor sit amet' },
          { lang: 'en', text: 'Lorem ipsum dolor sit amet' }
        ]
      },
      {
        id: 5,
        title: [
          { lang: 'cs', text: 'Lorem ipsum dolor sit amet' },
          { lang: 'en', text: 'Lorem ipsum dolor sit amet' }
        ]
      },
      {
        id: 6,
        title: [
          { lang: 'cs', text: 'Lorem ipsum dolor sit amet' },
          { lang: 'en', text: 'Lorem ipsum dolor sit amet' }
        ]
      },
      {
        id: 7,
        title: [
          { lang: 'cs', text: 'Lorem ipsum dolor sit amet' },
          { lang: 'en', text: 'Lorem ipsum dolor sit amet' }
        ]
      }
    ],
    img: '/map_floor_1.jpg'
  },
  {
    id: 'floor-2',
    title: [
      { lang: 'cs', text: '1. patro' },
      { lang: 'en', text: '1st floor' }
    ],
    legend: [
      {
        id: 8,
        title: [
          { lang: 'cs', text: 'Lorem ipsum dolor sit amet' },
          { lang: 'en', text: 'Lorem ipsum dolor sit amet' }
        ]
      },
      {
        id: 9,
        title: [
          { lang: 'cs', text: 'Lorem ipsum dolor sit amet' },
          { lang: 'en', text: 'Lorem ipsum dolor sit amet' }
        ]
      },
      {
        id: 10,
        title: [
          { lang: 'cs', text: 'Lorem ipsum dolor sit amet' },
          { lang: 'en', text: 'Lorem ipsum dolor sit amet' }
        ]
      },
      {
        id: 11,
        title: [
          { lang: 'cs', text: 'Lorem ipsum dolor sit amet' },
          { lang: 'en', text: 'Lorem ipsum dolor sit amet' }
        ]
      },
      {
        id: 12,
        title: [
          { lang: 'cs', text: 'Lorem ipsum dolor sit amet' },
          { lang: 'en', text: 'Lorem ipsum dolor sit amet' }
        ]
      },
      {
        id: 13,
        title: [
          { lang: 'cs', text: 'Lorem ipsum dolor sit amet' },
          { lang: 'en', text: 'Lorem ipsum dolor sit amet' }
        ]
      },
      {
        id: 14,
        title: [
          { lang: 'cs', text: 'Lorem ipsum dolor sit amet' },
          { lang: 'en', text: 'Lorem ipsum dolor sit amet' }
        ]
      },
      {
        id: 15,
        title: [
          { lang: 'cs', text: 'Lorem ipsum dolor sit amet' },
          { lang: 'en', text: 'Lorem ipsum dolor sit amet' }
        ]
      }
    ],
    img: '/map_floor_2.jpg'
  }
]

class MapWidgets extends Component {
  render () {
    return (
      <Box>
        <TranslatableTypography id="map" fontWeight={600}>
          map
        </TranslatableTypography>
        <Divider
          style={{
            color: pallete.headerTransparentColor,
            background: pallete.headerTransparentColor,
            marginTop: 5,
            marginBottom: 5,
            height: 3
          }}
        />
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="center"
          sx={{
            maxWidth: '400px',
            padding: '10px',
            textAlign: 'center'
          }}
        >
          <Map data={mapData} checksum="XY" />
        </Grid>
      </Box>
    )
  }
}

class WidgetsMenu extends Component {
  render () {
    return (
      <Paper style={{ width: '100%', padding: 5, marginBottom: '5px' }}>
        <Box>
          <TranslatableTypography style={{ textTransform: 'capitalize' }}>
            routing-widgets
          </TranslatableTypography>
        </Box>

        <Divider
          style={{
            color: pallete.headerTransparentColor,
            background: pallete.headerTransparentColor,
            marginTop: 5,
            marginBottom: 5,
            height: 3
          }}
        />

        <Grid container spacing={1}>
          <Grid item>
            <TranslatableButton variant="contained" href="/widgets">
              routing-all
            </TranslatableButton>
          </Grid>

          <Grid item>
            <TranslatableButton variant="contained" href="/widgets/main">
              routing-main
            </TranslatableButton>
          </Grid>

          <Grid item>
            <TranslatableButton variant="contained" href="/widgets/containers">
              routing-containers
            </TranslatableButton>
          </Grid>

          <Grid item>
            <TranslatableButton variant="contained" href="/widgets/cards">
              routing-cards
            </TranslatableButton>
          </Grid>

          <Grid item>
            <TranslatableButton variant="contained" href="/widgets/voting">
              routing-voting
            </TranslatableButton>
          </Grid>

          <Grid item>
            <TranslatableButton variant="contained" href="/widgets/map">
              routing-map
            </TranslatableButton>
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

class AllWidgetsPageComponent extends Component {
  render () {
    return (
      <Box sx={{ height: 2000 }}>
        <SubHeadeWithBreadcrumb>
          <BreadcrumbLink i18n="routing-widgets" href="/widgets" />
          <BreadcrumbLast i18n="routing-all" />
        </SubHeadeWithBreadcrumb>

        <WidgetsMenu />

        <Paper>
          <MainComponentWidgets />
          <DataCardWidgets />
          <VotingselectionWidgets />
          <ContaintersWidgets />
          <MapWidgets />
        </Paper>
      </Box>
    )
  }
}

class MainWidgetsPageComponent extends Component {
  render () {
    return (
      <Box sx={{ height: 2000 }}>
        <SubHeadeWithBreadcrumb>
          <BreadcrumbLink i18n="routing-widgets" href="/widgets" />
          <BreadcrumbLast i18n="routing-main" />
        </SubHeadeWithBreadcrumb>

        <WidgetsMenu />

        <Paper>
          <MainComponentWidgets />
        </Paper>
      </Box>
    )
  }
}

class DataCardsWidgetsPageComponent extends Component {
  render () {
    return (
      <Box sx={{ height: 2000 }}>
        <SubHeadeWithBreadcrumb>
          <BreadcrumbLink i18n="routing-widgets" href="/widgets" />
          <BreadcrumbLast i18n="routing-cards" />
        </SubHeadeWithBreadcrumb>

        <WidgetsMenu />

        <Paper>
          <DataCardWidgets />
        </Paper>
      </Box>
    )
  }
}

class VotingSelectionWidgetsPageComponent extends Component {
  render () {
    return (
      <Box sx={{ height: 2000 }}>
        <SubHeadeWithBreadcrumb>
          <BreadcrumbLink i18n="routing-widgets" href="/widgets" />
          <BreadcrumbLast i18n="routing-voting" />
        </SubHeadeWithBreadcrumb>

        <WidgetsMenu />

        <Paper>
          <VotingselectionWidgets />
        </Paper>
      </Box>
    )
  }
}

class ContainersWidgetsPageComponent extends Component {
  render () {
    return (
      <Box sx={{ height: 2000 }}>
        <SubHeadeWithBreadcrumb>
          <BreadcrumbLink i18n="routing-widgets" href="/widgets" />
          <BreadcrumbLast i18n="routing-containers" />
        </SubHeadeWithBreadcrumb>

        <WidgetsMenu />

        <Paper>
          <ContaintersWidgets />
        </Paper>
      </Box>
    )
  }
}

class MapWidgetsPageComponent extends Component {
  render () {
    return (
      <Box sx={{ height: 2000 }}>
        <SubHeadeWithBreadcrumb>
          <BreadcrumbLink i18n="routing-widgets" href="/widgets" />
          <BreadcrumbLast i18n="routing-map" />
        </SubHeadeWithBreadcrumb>

        <WidgetsMenu />

        <Paper>
          <MapWidgets />
        </Paper>
      </Box>
    )
  }
}

const AllWidgetsPage = withTranslation(AllWidgetsPageComponent)
const MainWidgetsPage = withTranslation(MainWidgetsPageComponent)
const DataCardsWidgetsPage = withTranslation(DataCardsWidgetsPageComponent)
const VotingSelectionWidgetsPage = withTranslation(
  VotingSelectionWidgetsPageComponent
)
const ContainersWidgetsPage = withTranslation(ContainersWidgetsPageComponent)
const MapWidgetsPage = withTranslation(MapWidgetsPageComponent)

export {
  AllWidgetsPage, ContainersWidgetsPage, DataCardsWidgetsPage, MainWidgetsPage, MapWidgetsPage, VotingSelectionWidgetsPage
}
