import { ArticleOutlined, CalendarMonthOutlined, MapOutlined } from '@mui/icons-material'
import { BottomNavigation, BottomNavigationAction, Box, LinearProgress, Paper } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { pallete, sizing } from '../../theme.js'

const footerContainerStyle = {
  height: `${sizing.footer.height}px`,
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  background: pallete.headerColor,
  zIndex: '10'
}

const progressStyle = {
  width: '100%',
  position: 'absolute',
  bottom: `${sizing.footer.height + 5}px`
}

const bottomNavStyle = {
  background: pallete.headerColor,
  '& .MuiBottomNavigationAction-root.Mui-selected': {
    color: pallete.paperColor,
    filter: `drop-shadow(0px 0px 3px ${pallete.headerBrightColor})`
  },
  '& .MuiBottomNavigationAction-root.disabled': {
    color: 'rgb(207, 45, 45);',
    filter: 'none'
  },
  '& .MuiBottomNavigationAction-root.Mui-selected.disabled': {
    color: 'rgb(207, 45, 45);',
    filter: 'drop-shadow(0px 0px 3px orange)'
  }
}

const CustomBottomNavigation = () => {
  const translation = useTranslation()
  const { i18n } = translation
  const navigation = useSelector((state) => state.navigation)
  const { footerIndex, loading, footerLinks } = navigation
  const progress = loading ? (<Box sx={progressStyle}><LinearProgress /></Box>) : undefined
  return (
    <Paper data-component="companion-footer" style={footerContainerStyle}>
        {progress}
        <BottomNavigation value={footerIndex} sx={bottomNavStyle} showLabels >
          <BottomNavigationAction label={i18n.t('notifications')}
            icon={<ArticleOutlined />}
            component={Link}
            to={footerLinks.notifications}
          />

          <BottomNavigationAction
            label={i18n.t('events')}
            icon={<CalendarMonthOutlined />}
            component={Link}
            to={footerLinks.events}
          />

          <BottomNavigationAction
            label={i18n.t('map')}
            icon={<MapOutlined />}
            component={Link}
            to={footerLinks.map}
          />
        </BottomNavigation>
      </Paper>
  )
}

export default CustomBottomNavigation
